@use "@styles/variables.scss" as vars;

.container {
    display: flex;
    column-gap: 40px;
    overflow: hidden;

    border-radius: 0px 15px 15px 0px;
    box-sizing: border-box;
}

.leftColumn {
    display: flex;

    margin: 28px 0px 32px 32px;
    flex: 0 0 240px;

    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;

    justify-content: space-between;
}

.rightColumn {
    display: block;
    margin: 28px 32px 28px 32px;
    height: 520px;

    border-left: 1px solid vars.$grey_lines;
}

.columnItem {
    display: block;
}

.title {
    @include vars.font_text19();
    margin-bottom: 16px;

    @media (max-width: vars.$break-point-lg) {
        margin-bottom: 20px;
    }
}

.leftColumn,
.rightColumn {
    box-sizing: border-box;
}

// Camera
.box {
    display: flex;
    flex-direction: column;

    margin-left: 32px;
}

.icon {
    display: flex;
}

.header {
    display: flex;
    align-items: center;
    column-gap: 12px;
    margin-bottom: 20px;
}

.errorTitle {
    display: flex;
    color: vars.$red_attention_hover;
}

.reasons {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.listTitle {
    display: block;
    @include vars.font_text15();
    margin-bottom: 12px;
}

.list {
    display: block;
    margin-left: 12px;
    margin-bottom: 20px;

    li {
        margin-bottom: 8px;
        list-style-type: disc;

        &:last-child {
            margin-bottom: 0px;
        }
    }
}

.action {
    display: block;
}

.isCamera {
    border: none;
    background-color: vars.$grey_hover;
    position: relative;
    overflow: hidden;
    margin: 0px;
    width: 500px;

    .innerCamera {
        height: 520px;
        position: relative;
        left: -150px;
    }
}

.loader {
    position: absolute;
    z-index: vars.$default_index;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.example {
    display: flex;
    margin-bottom: 52px;
    img {
        height: 149px;
        border-radius: 10px;
    }
}

.description {
    display: flex;
    margin-bottom: 16px;
}

.cameraUI {
    display: flex;
    position: absolute;
    bottom: 20px;
    right: 20px;
    left: 20px;
}

.screenShoot {
    display: flex;
    width: 486px;
    height: 520px;
    position: absolute;
    left: -150px;
    top: 0px;
}

.button_wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    column-gap: 16px;
    width: 100%;
    margin-top: 32px;

    &_end {
        justify-content: flex-end;
    }
}

.p {
    max-width: 500px;
}
