@use '@styles/_variables.scss' as vars;

.input_wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    margin-bottom: 32px;
}

.button_wrapper {
    width: 100%;
    display: flex;
    column-gap: 16px;
    justify-content: flex-end;
}

.upload {
    & > div {
        @media (max-width: vars.$break-point-sm) {
            width: 100%;
        }
    }
}
