@use "@styles/_variables.scss" as vars;

.container {
    display: flex;
    column-gap: 12px;
    align-items: center;
    margin-top: 8px;
}

.icon {
    display: flex;
    opacity: 0.5;
}

.input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid vars.$grey_elements;
    border-radius: 8px;
    height: 100%;
    transition: border-color 0.3s;
    font-family: 'Mulish';
    @include vars.font_text14();

    height: 42px;
    padding-top: 11px;
    padding-left: 10px;
    padding-bottom: 11px;
    padding-right: 10px;

    outline: none;

    &:hover {
        border-color: vars.$grey_text;
    }
}