@use "@styles/_variables.scss" as vars;

.container {
    display: flex;
    width: 100%;

    &__header {
        align-items: center;
    }
}

.contactAvatar {
    display: flex;
}

.contactData {
    display: flex;
    flex-direction: column;
    margin-left: 12px;
    flex-grow: 1;
    width: 238px;

    &__header {
        @media (max-width: vars.$break-point-md) {
            margin-left: 10px;
        }
    }
}

.contactSubTitle {
    @include vars.font_text9();
    letter-spacing: 0.05em;
    font-weight: 500;
    text-transform: uppercase;
}

.contactTitle {
    @include vars.font_text14();
    font-weight: 500;

    display: flex;
    margin-top: 4px;
}

.contactTitle__header {
    font-weight: 700;
    margin-top: 0;
}

.content {
    display: flex;
    column-gap: 4px;
    justify-content: space-between;
}

.time {
    @include vars.font_text11();
    color: vars.$grey_text;
}

.avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 44px;
    min-height: 44px;
    min-width: 44px;
    font-weight: bold;
    color: vars.$green_main;
    background-color: vars.$light_green;
    border-radius: 50%;
    cursor: default;
    border: 1px solid rgba(20, 75, 63, 0.05);

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.clickable {
    cursor: pointer;
}

.header {
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;

    @media (max-width: vars.$break-point-md) {
        width: 32px;
        height: 32px;
        min-width: 32px;
        min-height: 32px;
    }
}

.regular {
}

.footer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.badge {
    width: 10px;
    height: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 50%;

    background-color: vars.$supa_green;
    color: vars.$white_main;

    font-size: 1.2rem;
}

.message {
    margin-top: 4px;
    @include vars.font_text13;
    color: vars.$grey_text;

    max-width: 86%;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: break-spaces;
    display: flex;

    & .lastMessage {
        display: inline-block;
        font-size: 1.3rem !important;
        font-weight: normal !important;
        pointer-events: none;
        color: vars.$grey_text !important;
        line-height: 1.8rem !important;
        max-height: 1.8rem;
        overflow: hidden;
        max-width: 100%;
        width: 100%;

        p {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            line-height: 1.8rem !important;
            width: 100%;
        }

        a {
            color: vars.$grey_text !important;
        }

        strong {
            font-weight: normal !important;
        }

        em {
            font-style: normal !important;
        }
    }
}

.icon {
    display: flex;
    align-items: center;
    margin-left: 4px;
    cursor: pointer;

    svg {
        width: 18px;
        height: 18px;
    }
}

.paper {
    padding: 12px 16px;
    border-radius: 10px;
}

.breadcrumbs_container {
    max-width: 100%;

    @include vars.horizontal_scroll;
}

.breadcrumbs_item {
    @include vars.font_text13;
}

.drawer-title {
    margin-bottom: 12px;
}

.popover__border-radius__with-shadow {
    border-radius: 10px !important;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1) !important;
}
