@use "@styles/_variables.scss" as vars;

.btn {
    border-radius: 50%;
    border: none;

    width: 36px;
    height: 36px;
    padding: 0px 5px 0px 7px;

    background-color: vars.$green_success;
    flex-grow: 0;
    flex-shrink: 0;

    cursor: pointer;
}

.show {
    display: none;
}
