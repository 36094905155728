@use "@styles/_variables.scss" as vars;

.container {
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
}

.status-icon {
    position: absolute;
    left: -42px;
    width: 20px;
    height: 20px;
}

.loading {
    fill: vars.$green_main;
}

.error {
    & path {
        stroke: vars.$red_attention;
    }
}

.bubbleContent {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    width: 100%;
}

.bubbleCorner {
    display: block;
}

.messageContent {
    word-break: break-word;
    white-space: break-spaces;
}

.own {
    justify-content: flex-end;

    .bubble {
        .inner {
            background-color: vars.$light_green;

            border-radius: 12px;
            border-bottom-right-radius: 0px;
        }

        &::before {
            display: none;
        }

        &::after {
            content: '';
            display: block;
            width: 10px;
            min-width: 10px;
            height: 14px;
            background-image: url("data:image/svg+xml,%3Csvg width='10' height='14' viewBox='0 0 10 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0C0 4.43333 1.44195 12.04 9.42814 12.6C10.3522 12.8916 10.0937 14 9.10165 14H0V0Z' fill='%23E4F1EF'/%3E%3C/svg%3E%0A");
        }
    }
}
