@use "@styles/_variables.scss" as vars;

.title_wrapper {
    display: flex;
    align-items: center;
    column-gap: 12px;
    margin-bottom: 24px;
}

.create_section {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-top: 104px;

    &__title {
        margin-bottom: 12px;
    }

    &__text {
        width: 280px;
        text-align: center;
        margin-bottom: 24px;
    }
}

.background_img {
    width: 264px;
    height: 231px;
    background: url("/assets/image/video-lectures-svg.svg") no-repeat center;
}

.video_lectures_wrapper {
    width: 100%;
    padding-top: 32px;
}

.video_lectures {
    display: grid;
    grid-template-columns: repeat(auto-fit, 233px);
    column-gap: 20px;
    row-gap: 38px;
}
