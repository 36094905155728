@use "@styles/_variables.scss" as vars;

.info {
    display: flex;
    flex-direction: column;
}

.task_title {
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: vars.$black_grey;
}

.discipline_title {
    margin-bottom: 14px;
    word-wrap: break-word;

    @media (max-width: vars.$break-point-md) {
        margin-top: 6px;
        margin-bottom: 24px;
        @include vars.font_text18;
    }
}

.user_info {
    display: flex;
    align-items: center;
    column-gap: 12px;
}

.details-wrapper {
    width: calc(100% - 36px - 12px)
}

.user_name {
    @include vars.font_text15;
    margin-bottom: 2px;

    @media (max-width: vars.$break-point-md) {
        @include vars.font_text14;
    }
}

.avatar {
    cursor: initial;
}

.breadcrumbs_item {
    @include vars.font_text13;
}