$columns: 12;
$gap: 16px;

@mixin create-columns() {
    @for $i from 1 through $columns {
        .col-#{$i} {
            grid-column-end: span $i;
            height: 100%;
            position: relative;
        }
    }
}

.row {
    width: 100%;
}

.grid {
    display: grid;
    grid-gap: $gap;
    gap: $gap;
    width: 100%;
    height: 100%;

    grid-template-columns: repeat($columns, 1fr);
    grid-auto-rows: minmax(min-content, max-content);

    @include create-columns();

    &--expand {
        grid-template-columns: repeat($columns, 1fr);
        @include create-columns();
    }

    &-align-items {
        &--start {
            align-items: start;
        }

        &--center {
            align-items: center;
        }

        &--end {
            align-items: end;
        }

        &--strtch {
            align-items: stretch;
        }

        &--baseline {
            align-items: baseline;
        }
    }

    &-justify-content {
        &--start {
            justify-content: start;
        }

        &--center {
            justify-content: center;
        }

        &--flex-end {
            justify-content: end;
        }

        &--space-between {
            justify-content: space-between;
        }

        &--space-around {
            justify-content: space-around;
        }

        &--space-evenly {
            justify-content: space-evenly;
        }
    }
}
