@use "@styles/_variables.scss" as vars;

.container {
    width: 100%;
    display: flex;
}

.own {
    justify-content: flex-end;
}

.inner {
    width: 280px;
    position: relative;
    display: flex;
    align-items: center;
    border-radius: 12px;
}

.loader {
    position: absolute;
    left: -28px;
    width: 20px;
    height: 20px;
    fill: vars.$green_main;
}

.error-icon {
    position: absolute;
    left: -28px;
    width: 20px;
    height: 20px;

    & path {
        stroke: vars.$red_attention;
    }
}

.photo {
    width: 100%;
    display: block;
    border-radius: 12px;
}

.time {
    position: absolute;
    bottom: 8px;
    right: 12px;
}
