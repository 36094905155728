@use '@styles/_variables.scss' as vars;

.breadcrumps {
    padding-top: 26px;
    padding-left: 24px;
}

.root {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
}