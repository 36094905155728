@use '@styles/variables.scss' as vars;

.root {
    display: flex;
    flex-wrap: wrap;
    z-index: calc(vars.$hight_index - 1);
}

.fixed {
    position: sticky;
    top: 73px;
    display: flex;
    height: calc(100vh - 73px);

    @media (max-width: vars.$break-point-sm) {
        top: 56px;
        height: calc(100vh - 56px);
    }
}
