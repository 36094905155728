@use '@styles/_variables.scss' as vars;

.root {
    flex-grow: 1;
    padding: 28px 32px 20px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;

    &--student {
        padding: 32px 0px 22px 0px;
        max-width: 100%;

        @media (max-width: vars.$break-point-lg) {
            padding-top: 28px;
            padding-bottom: 14px;
        }
    }
}
