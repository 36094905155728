.enter {
    & > * {
        opacity: 0;
    }
}
.enterActive {
    & > * {
        opacity: 1;
        transition: opacity 0.4s;
    }
}
.exit {
    & > * {
        opacity: 1;
    }
}
.exitActive {
    & > * {
        opacity: 0;
        transition: opacity 0.4s;
    }
}
