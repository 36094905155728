@use "@styles/_variables.scss" as vars;

.root {
    border-radius: 8px;
    border: none;
    display: inline-flex;
    min-width: 64px;
    align-items: center;
    justify-content: center;
    user-select: none;

    color: vars.$white_main;
    background-color: grey;
    cursor: pointer;
    align-items: center;
    transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, color 0.2s ease-in-out;

    .icon {
        margin-right: 8px;
    }

    // Sizes
    &--normal {
        padding: 9.5px 16px 11.5px;
        @include vars.font_text15;
    }

    &--middle {
        padding: 6px 16px 8px;
        @include vars.font_text14;
    }

    &--small {
        padding: 6px 16px 8px;
        @include vars.font_text14;
    }

    &--tiny {
        padding: 6px 12px 8px;
        @include vars.font_text14;
        height: 34px;
    }

    // Types
    &--success {
        background-color: vars.$green_main;

        &:hover {
            background-color: vars.$green_main_hover;
        }
    }

    &--secondary {
        background-color: vars.$grey;
        color: vars.$black_main;

        &:hover {
            background-color: vars.$grey_lines_hover;
        }
    }

    &--danger {
        background-color: vars.$red_attention;

        &:hover {
            background-color: vars.$red_attention_hover;
        }
    }

    &--primary {
        background-color: vars.$black_main;

        &:hover {
            background-color: vars.$dark_hover;
        }
    }

    &--white {
        background-color: vars.$white_main;
        color: vars.$black_main;

        &:hover {
            background-color: vars.$white_main;
        }
    }

    &--dark {
        background-color: vars.$black_grey;

        &:hover {
            background-color: vars.$dark_hover;
        }
    }

    &--yellow {
        background-color: vars.$yellow;
        color: vars.$black_main;

        &:hover {
            background-color: darken(vars.$yellow, 20%);
        }
    }

    &--green-light {
        padding: 7px 16px 9px;
        color: vars.$black_main;
        background-color: vars.$grey_hover;

        &:hover {
            color: vars.$green_main;
            background-color: vars.$grey_e7;
        }
    }

    &--tabsDark {
        background-color: vars.$black_main;
        color: vars.$white_main;
        @include vars.font_text14();

        &:hover {
            background-color: vars.$black_main;
            color: vars.$white_main;
        }

        [class~="tab_items_count"] {
            background-color: vars.$black_grey;
            color: vars.$white_main;

            align-items: center;
            height: 22px;
        }
    }

    &--tabs {
        background-color: vars.$grey_hover;
        color: vars.$black_main;

        &:hover {
            background-color: vars.$grey_lines;
            color: vars.$black_main;
        }

        [class~="tab_items_count"] {
            background-color: vars.$white_main;
            color: vars.$grey_text;

            align-items: center;
            height: 22px;
        }
    }

    &--outline {
        @include vars.font_text14;
        background-color: transparent;
        color: vars.$green_main;
        border: 1px solid vars.$outline_borders;
        height: 42px;

        &:hover {
            background-color: transparent;
            color: vars.$green_main;
            border: 1px solid vars.$outline_hover;
        }
    }

    &--clear,
    &--clear:hover {
        padding: 0px;
        background-color: transparent;
        color: vars.$green_main_hover;
    }

    &--download {
        padding: 10px 16px;
        color: vars.$green_main;
        border: 1px solid vars.$grey_elements;
        background-color: vars.$white_main;

        &:hover {
            border-color: vars.$grey_text;
        }
    }

    &--menu {
        height: 28px;
        width: 28px;
        min-width: 28px;

        margin: 0px;
        position: relative;
        background-color: transparent;

        div {
            margin: 0px;
        }

        &:hover {
            background-color: vars.$white_main;
            box-shadow: 0 0 0 1px vars.$outline_borders;
        }

        .icon {
            margin: 0px;
        }
    }

    &--pagination {
        background-color: transparent;
        color: vars.$black_main;
        height: 32px;
        padding: 6px 10px;

        .icon {
            margin-left: 8px;
            margin-right: 0px;

            @media (max-width: vars.$break-point-lg) {
                margin-left: 0px;
                padding: 0px 2px;
            }
        }

        .icon-prev {
            margin-left: 0;
            margin-right: 8px;

            @media (max-width: vars.$break-point-lg) {
                margin-right: 0px;
                padding: 0px 2.5px;
            }
        }

        &:hover {
            background-color: transparent;
        }
    }

    &--success-light {
        height: 34px;
        padding: 6px 16px 8px 16px;

        background-color: vars.$light_green;
        color: vars.$supa_green;

        @include vars.font_text14();

        &:hover {
            background-color: darken(vars.$light_green, 10);
        }
    }

    // Disable
    &--disabled {
        color: vars.$white_main;
        background-color: vars.$grey_text;
        pointer-events: none;

        &:hover {
            color: vars.$white_main;
            background-color: vars.$grey_text;
        }
    }

    &--pagination.root--disabled,
    &--pagination.root--disabled:hover {
        color: vars.$grey_text;
        background-color: transparent;
        pointer-events: none;

        svg {
            path {
                stroke: vars.$grey_lines;
            }
        }
    }

    &--full_width {
        width: 100%;
        justify-content: center;
    }

    [class~="table-menu"] {
        padding: 10px 14px 8px;
    }
}