@use '@styles/variables.scss' as vars;

.cell {
    display: flex;
    align-items: baseline;
}

.score {
    display: flex;
}

//scores
.caption {
    display: flex;
}

.container {
    display: flex;
    column-gap: 4px;
}

.score {
    display: flex;
}

.bad,
.good,
.medium,
.excelent {
    display: flex;
    align-items: center;
    column-gap: 8px;

    &::before {
        content: '';
        display: block;
        width: 7px;
        min-width: 7px;
        height: 7px;
        border-radius: 50%;
    }
}

.bad {
    &::before {
        background-color: vars.$red_attention_hover;
    }
}

.medium {
    &::before {
        background-color: vars.$yellow_grade;
    }
}

.good {
    &::before {
        background-color: vars.$green_main;
    }
}


.excelent {
    &::before {
        background-color: vars.$green_success;
    }
}
