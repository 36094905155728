@use '@styles/_variables.scss' as vars;

.grid {
    grid-template-columns: repeat(7, minmax(0, 1fr));
    gap: 1px;
    height: auto;
    background-color: vars.$grey_elements;
    border: 1px solid vars.$grey_elements;
    border-top: none;
}

.header {
    @media (max-width: vars.$break-point-lg) {
        gap: 0;
        border-left: none;
        border-right: none;
    }
}

.column {
    background-color: vars.$grey_pale;
    position: relative;

    &__header {
        background-color: vars.$grey_hover;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        color: vars.$grey_text;
        border-top: 1px solid vars.$grey_elements;
        padding-bottom: 0;

        @media (max-width: vars.$break-point-lg) {
            border-top: none;
            background-color: vars.$white_main;
            padding-bottom: 12px;
        }
    }
}

.week_day_name {
    font-size: 1.2rem;
}