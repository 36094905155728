@use '@styles/_variables.scss' as vars;

.modal {
    max-width: 500px;
}

.container {
    border-radius: 8px;
    padding: 12px 12px 16px 12px;
    background-color: vars.$grey_hover;
}

.preview_wrapper {
    max-height: 400px;

    overflow-x: auto;

    &::-webkit-scrollbar {
        width: 4px;
        height: 100%;
        background-color: white;
        border: solid 3px transparent;
    }

    &::-webkit-scrollbar-track {
        border: solid 3px transparent;
    }

    &::-webkit-scrollbar-thumb {
        width: 3px;
        height: 39px;
        border-radius: 5px;
        background-color: vars.$grey_elements;
    }
}

.text {
    @include vars.font_text13;
}

.text_overflow {
    text-overflow: ellipsis;
    max-height: 36px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}


.text_offset {
    margin-top: 16px;
}

.more {
    background-color: transparent;
    border: none;
    padding: 0;
    @include vars.font_text13;
    color: vars.$green_main;
    cursor: pointer;
    margin-top: 8px;
}

