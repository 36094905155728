@use "@styles/_variables.scss" as vars;

.title {
    margin-bottom: 24px;

    @media (max-width: vars.$break-point-md) {
        margin-bottom: 20px;
    }

    @media (max-width: vars.$break-point-sm) {
        margin-bottom: 7px;
    }
}

.tabs_wrapper {
    width: 100%;
    position: relative;

    @media (max-width: vars.$break-point-md) {
        & ul {
            column-gap: 8px;
        }
    }

    @media (max-width: vars.$break-point-sm) {
        & ul {
            width: 100%;
            display: flex;

            & li {
                flex: 1;

                & button,
                a {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                }
            }
        }
    }

    &__btn {
        position: absolute;
        top: 0px;
        right: 0px;
        border-radius: 8px;

        @media (max-width: vars.$break-point-lg) {
            border: none;
            padding: 6px 0 8px;

            &:hover {
                border: none;
            }
        }

        @media (max-width: vars.$break-point-sm) {
            position: relative;
            margin-bottom: 19px;
            padding: 0px;
            height: unset;
        }
    }
}

.tabs {
    margin-bottom: 24px;

    @media (max-width: vars.$break-point-sm) {
        margin-bottom: 18px;
    }
}

.time {
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    color: vars.$black_main;

    & svg {
        margin-right: 8px;

        path {
            stroke: vars.$black_main;
        }
    }
}
