@use '@styles/variables.scss' as vars;


.container {
    display: flex;
    flex-direction: column;
}

.tabContainer {
    display: flex;
    justify-content: space-between;
    border: none;
}

.title {
    display: flex;
    margin-bottom: 4px;
}

.tabs {
    ul {
        display: flex;
        column-gap: 12px;
    }

}

.tab {
    display: flex;
}

.tabBtn {
    display: flex;
    background-color: vars.$grey_hover;
    color: vars.$black_main;
}

.tab[aria-selected="true"] {
    color: vars.$white_main;
    border-bottom: none;

    button {
        background-color: vars.$black_main;
        color: vars.$white_main;
    }
}

.panels {
    display: flex;
    margin-top: 30px;
}

.panel {
    display: flex;
    width: 100%;
}

.actions {
    display: flex
}
