@use '@styles/_variables.scss' as vars;

.title_wrapper {
    display: flex;
    align-items: center;
    column-gap: 12px;
    margin-bottom: 24px;
}

.header,
.cards {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.cards {
    margin-bottom: 65px;
}