@use '@styles/_variables.scss' as vars;

.paper {
    @media (max-width: vars.$break-point-sm) {
        border-radius: 16px 16px 0 0;
    }
}

.container {
    position: relative;
    width: 100vw;
    max-width: 375px;
    height: 100%;
    padding: 0 20px 28px;


    @media (max-width: vars.$break-point-sm) {
        padding: 32px 20px;
        max-width: unset;
        max-height: 376px;
    }

    .close {
        position: absolute;
        top: 16px;
        right: 16px;
        color: vars.$grey_text;
    }

    &_handle {
        width: 48px;
        height: 3px;
        content: "";
        background-color: vars.$outline_borders;
        border-radius: 3px;
        position: absolute;
        top: 6px;
        left: 50%;
        transform: translateX(-50%);
    }

    h1 {
        @include vars.font_text19;

        @media (min-width: vars.$break-point-sm) {
            width: calc(100% + 40px);
            padding: 16px 20px;
            margin: 0 -20px;
            box-shadow: 0px 6px 8px 0px rgba(30, 30, 30, 0.08);
        }
    }

    .content {
        margin-top: 24px;

        @media (max-width: vars.$break-point-sm) {
            margin-top: 0;
        }
    }
}

