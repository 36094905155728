@use "@styles/_variables.scss" as vars;

.container {
    display: flex;
    cursor: pointer;
    position: relative;
}

.user-notifications__counter {
    position: absolute;
    top: -1px;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    font-weight: 700;
    font-size: 10px;
    line-height: 0;
    color: vars.$white_main;
    background-color: vars.$red_attention;
    border-radius: 50%;
}
