@use "@styles/_variables.scss" as vars;

.item {
    cursor: pointer;
    border-bottom: 1px solid vars.$grey_lines;
    padding: 12px;
    display: flex;
    margin-left: calc(32px * var(--group-level));

    &:hover {
        background-color: vars.$grey_hover;

        [class~=table-menu] {
            opacity: 1;
        }
    }

    .button-arrow {
        margin-right: 12px;

        & svg {
            width: 12px;
            height: 12px;
            transition: transform 0.3s;
        }

        &--active {
            & svg {
                transform: rotate(90deg);
            }
        }
    }
}

.title-wrapper {
    display: flex;
    align-items: center;

    & > span {
        flex: 1
    }

    & > svg {
        margin-left: 6px;
        min-width: 20px;
    }
}
