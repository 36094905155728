@use "@styles/_variables.scss" as vars;

.content {
    display: flex;
    align-items: center;
    column-gap: 12px;
}

.modal__text {
    @include vars.font_text15;
    max-width: 500px;
    word-break: break-all;
}

.button_wrapper {
    width: 100%;
    display: flex;
    column-gap: 16px;
    justify-content: flex-end;
    margin-top: 24px;
}

.loader {
    max-width: fit-content;
}
