@use "@styles/_variables.scss" as vars;

.container {
    padding: 0 32px;
    width: 100%;

    @media (max-width: vars.$break-point-md) {
        padding: 0 40px;
    }

    @media (max-width: vars.$break-point-sm) {
        padding: 0 16px;
    }

    .content {
        max-width: 1280px;
        width: 100%;
    }
}
