@use "@styles/variables.scss" as vars;

.wrapper {
    height: 100%;
    max-height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 10px;
}

.parent-container {
    overflow: auto;

    &::-webkit-scrollbar {
        width: 4px;
        height: 100%;
        background-color: white;
        border: solid 3px transparent;
    }

    &::-webkit-scrollbar-track {
        border: solid 3px transparent;
    }

    &::-webkit-scrollbar-thumb {
        width: 3px;
        height: 39px;
        border-radius: 5px;
        background-color: vars.$grey_elements;
    }
}

.container {
    display: flex;
    flex-direction: column-reverse;

    padding: 16px 16px 0;
    margin-right: 4px;



    & > div {
        margin-top: 8px;

        @media (max-width: vars.$break-point-sm) {
            margin-top: 4px;
        }
    }
}

.drop-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}
