@use "@styles/_variables.scss" as vars;

.wrapper {

    div[class*="tox tox-tinymce"] {
        border: 1px solid vars.$grey_elements;
        transition: border-color 0.3s;
        z-index: vars.$low_index !important;

        &:hover {
            border-color: vars.$grey_text;
        }
    }

    div[class*="tox-fullscreen"] {
        z-index: vars.$hightest_index !important;
    }

    div[class*="tox-promotion"] {
        display: none;
    }
}

.error {
    div[class*="tox tox-tinymce"] {
        border-color: vars.$red_attention;

        &:hover {
            border-color: vars.$red_attention;
        }
    }
}
