@use "@styles/_variables.scss" as vars;

.title {
    margin-bottom: 16px;
}

.list {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

.item {
    display: flex;
    justify-content: space-between;
    row-gap: 12px;
    flex-direction: column;
    border-bottom: 1px solid vars.$grey_lines;
    padding-bottom: 12px;
}

.count {
    color: vars.$grey_text;
}

.content_list {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
}

.content_item {
    display: flex;
    justify-content: space-between;
    row-gap: 19px;
}

.link_wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    column-gap: 19px;
}

.link {
    &_header {
        @include vars.font_text15;

        &:hover {
            color: vars.$green_main;
        }
    }

    &--active {
        color: vars.$green_main;
        font-weight: 800;
    }
}

.sub_link {
    &:hover {
        color: vars.$green_main;
    }
}

.drawer {
    padding-top: 0;
    padding-bottom: 0;

    & > div {
        padding-top: 32px;
        padding-bottom: 32px;
    }
}

.select {
    margin-bottom: 24px;
}
