@mixin horizontal_scroll {
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none; // Safari and Chrome
        background-color: transparent;
    }
}

@mixin fade-in-ellipsis($width, $bg-color) {
    position: relative;

    &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: $width;
        background-image: -webkit-linear-gradient(90deg, rgba($bg-color, 0), $bg-color);
        background-image: linear-gradient(90deg, rgba($bg-color, 0), $bg-color);
    }
}