@use '@styles/_variables.scss' as vars;


.grid {
    grid-template-columns: repeat(7, minmax(0, 1fr));
    gap: 1px;
    height: auto;
    background-color: vars.$grey_elements;
    border: 1px solid vars.$grey_elements;
    border-top: none;
}

.column {
    background-color: vars.$grey_pale;
    position: relative;
}

.disable_month {
    color: vars.$grey_text;
}

.weekend {
    background-color: #f7f7f7;
}

.student {
    padding-bottom: 100%;

    @media (max-width: vars.$break-point-lg) {
        padding-bottom: 66%;
    }

    @media (max-width: vars.$break-point-md) {
        padding-bottom: 66%;
    }

    @media (max-width: vars.$break-point-sm) {
        padding-bottom: 100%;
    }
}