@use '@styles/_variables.scss' as vars;

.user-message {
    position: relative;
}

.user-message__modal {
    padding: 0;
    min-width: unset;

    @media (max-width: vars.$break-point-lg) {
        border-radius: 0;
        height: 100%;
    }
}

.user-message__modal-wrapper {
    @media (max-width: vars.$break-point-lg) {
        border-radius: 0;
        height: 100%;
    }
}

.user-message__icon {
    color: vars.$black_main;
    transition: color 0.5ms ease;
    &:hover {
        color: vars.$green_main;
    }
}

.user-message__counter {
    position: absolute;
    top: -6px;
    right: -6px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    font-weight: 700;
    font-size: 10px;
    line-height: 0;
    color: vars.$white_main;
    background-color: vars.$red_attention;
    border-radius: 50%;
}


