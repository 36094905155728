@use '@styles/_variables.scss' as vars;

.root {
    position: relative;
    width: 332px;
    padding: 32px 24px;

    &--small {
        width: 240px;
    }
}

.content {
    overflow-y: auto;
    height: 100%;
    scrollbar-width: none;


    &::-webkit-scrollbar {
        width: 0;
        height: 0;
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 20px;
        height: calc(100%);
        box-shadow: inset -21px 0px 20px -28px #a0a0a0;
        //background: linear-gradient(270deg, #A0A0A0 -300%, rgba(255, 255, 255, 0) 97.06%);
    }
}

.noScroll {
    overflow: hidden;
}