@import "@styles/_variables.scss";

.header {
    color: $black_main;
    font-size: 22px;
    font-weight: 800;
    line-height: 30px;
    margin-bottom: 32px;
}
.empty {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;

    .text {
        color: $black_main;
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;
        margin-bottom: 24px;
    }
    .button {
        margin-bottom: 24px;
    }
}
.grid {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    width: 100%;
}

.fullWidth {
    width: 100%;
}
