@use '@styles/_variables.scss' as vars;

.header {
    @include vars.centering;

    @media (min-width: vars.$break-point-lg) {
        justify-content: flex-start;
        column-gap: 40px;
    }

    @media (max-width: vars.$break-point-lg) {
        column-gap: unset;
        padding-top: 18px;
        padding-bottom: 18px;
    }

    @media (max-width: vars.$break-point-md) {
        padding-top: 15px;
        padding-bottom: 15px;
    }

    @media (max-width: vars.$break-point-sm) {
        padding-top: 0;
        padding-bottom: 0;
        min-height: 56px;
    }

    @media (min-width: vars.$break-point-md) {
        column-gap: 30px;
    }
}

.wrapper {
    display: flex;
    align-items: center;
    gap: 30px;

    @media (max-width: vars.$break-point-md) {
        gap: 24px;
    }
}

.tabs {
    width: auto;
    margin-bottom: 0;
    border-bottom: none;
    column-gap: 24px;

    @media (max-width: vars.$break-point-lg) {
        column-gap: 24px;
    }

    @media (max-width: vars.$break-point-md) {
        column-gap: 20px;
    }

    & > [class*="tab_root"] {
        & > a {
            display: block;
            top: 0;
            padding-bottom: 0;
            border-bottom: none;
            color: vars.$black_grey;
            margin: 0;

            &:hover {
                color: vars.$grey_text;
            }

            @media (max-width: vars.$break-point-lg) {
                font-size: 1.4rem;
            }
        }

        & > [class*="tab_root--selected"] {
            color: vars.$green_main;

            &:hover {
                color: vars.$green_main;
            }

            &::after {
                content: "";
                position: absolute;
                left: 0;
                top: 47px;
                width: 100%;
                height: 2px;
                background: vars.$green_main;

                @media (max-width: vars.$break-point-md) {
                    top: 44px;
                }
            }
        }
    }
}

.controls-wrapper {
    display: flex;
    flex-grow: 1;
    column-gap: 24px;
}

.more {
    font-family: inherit;
    font-size: 1.5rem;
    line-height: 20px;
    color: #555555;
    padding: 0;
    border: none;
    background-color: transparent;
    transition: color 0.3s ease-in-out;
    cursor: pointer;

    &:hover {
        color: #a0a0a0;
    }

    @media (max-width: vars.$break-point-lg) {
        font-size: 1.4rem;
    }

    .arrow {
        margin-left: 6px;
        transform: rotate(90deg);

        transition: transform 0.3s ease-in-out;
    }

    .arrow-rotate {
        transform: rotate(270deg);
    }
}

.popover {
    position: absolute;
    top: calc(100% + 1px);
    left: 0;
    width: 100%;
    background-color: vars.$white_main;

    display: grid;
    grid-template-rows: 0fr;
    transition: grid-template-rows 0.3s ease-in-out;

    .container {
        overflow: hidden;

        box-shadow: 0 4px 24px 0 rgba(53, 53, 53, 0.06);

        .content {
            @include vars.centering;

            padding-top: 28px;
            padding-bottom: 36px;

            display: flex;
            flex-direction: column;
            row-gap: 16px;

            h3 {
                @include vars.font_h3;

                color: vars.$black_main;
            }

            a {
                @include vars.font_text15;

                color: vars.$green_main;
            }
        }
    }
}

.popover-opened {
    grid-template-rows: 1fr;
}
