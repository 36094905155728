@use "@styles/_variables.scss" as vars;

.button_wrapper {
    width: 100%;
    display: flex;
    column-gap: 16px;
    justify-content: flex-end;
}

.modal__text {
    @include vars.font_text14;
    margin-bottom: 32px;
    max-width: 452px;
    word-break: break-all;
}

.row {
    display: flex;
    margin-bottom: 12px;
    align-items: center;
}

.loader {
    margin-right: 12px;
    width: unset;
    flex-grow: 0;
}

.loading_text {
    @include vars.font_text14;
    white-space: pre;
}
