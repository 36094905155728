@use '@styles/variables.scss' as vars;

.calendarContainer {
    display: flex;
}

.weeks {
    display: flex;
    margin-bottom: 8px;
}

.weekDay {
    display: flex;
    color: vars.$grey_text;
    width: 40px;
    height: 24px;
    justify-content: center;
    align-items: center;

    @include vars.font_text16();
}

.row {
    display: flex;

    &:not(:last-child) {
        margin-bottom: 8px;
    }
}

.rowDay {
    display: flex;
    width: 40px;
    height: 32px;

    justify-content: center;
    align-items: center;
    cursor: pointer;

    @include vars.font_text17();
    position: relative;
    z-index: vars.$low_index;

    &::after {
        content: "";
        position: absolute;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        opacity: 0;

        background-color: vars.$grey_hover;
        z-index: vars.$negative_index;
    }

    &:hover {
        &::after {
            opacity: 1;
        }
    }
}

.disable {
    color: vars.$grey_text;
    cursor: auto;

    &:hover,
    &::after {
        background-color: vars.$white_main !important;
    }
}

.isToday {
    &::after {
        opacity: 1;
        content: "";
        box-shadow: 0 0 0 1px vars.$grey_elements;
        background: transparent;
    }
}

.calendar {
    display: flex;

    .table {
        &:first-of-type {
            margin-right: 32px;
        }
    }
}

.isSelected {
    color: white;
    outline: none;
    user-select: none;

    &::after {
        content: "";
        position: absolute;
        width: 32px;
        height: 32px;
        opacity: 1;

        background-color: vars.$green_success;
        box-shadow: none;

        z-index: vars.$negative_index;
    }
}

.isRangeSelected {
    background-color: vars.$grey_hover;

    &::after {
        opacity: 0;
    }

    &:hover {
        &::before {
            box-shadow: none;
        }
    }
}

.isEndSelectedRange,
.isStartSelectedRange {
    color: vars.$white_main;

    &:after {
        opacity: 1;
        background-color: vars.$green_success;
    }

    &::before {
        content: "";
        width: 20px;
        height: 32px;
        position: absolute;
        background-color: vars.$white_main;
        top: 0px;
        z-index: vars.$negative_index;
    }
}

.isStartSelectedRange {
    &::before {
        left: 0px;
    }
}

.isEndSelectedRange {
    &::before {
        right: 0px;
    }
}

.isFirstDay {
    border-bottom-left-radius: 50px;
    border-top-left-radius: 50px;

    &.isEndSelectedRange {
        width: 32px;
        margin: 0px 4px;
    }
}

.isLastDay {
    border-bottom-right-radius: 50px;
    border-top-right-radius: 50px;

    &.isStartSelectedRange {
        width: 32px;
        margin: 0px 4px;
    }
}

.pickContainer {
    display: flex;
    position: absolute;
    top: 60px;
    bottom: 32px;
    right: 24px;
    left: 24px;
}