@use "@styles/variables.scss" as vars;

.container {
    display: flex;
    flex-direction: column;

    padding: 28px 32px;

    @media (max-width: vars.$break-point-lg) {
        padding: 24px 24px 28px 24px;
        width: 629px;
    }

    @media (max-width: vars.$break-point-md) {
        padding: 20px 20px 24px 20px;
        width: 100%;
    }

    @media (max-width: vars.$break-point-sm) {
        padding: 16px 16px 24px 16px;
    }
}

.title {
    @include vars.font_text19();
    margin-bottom: 20px;
}

.description {
    width: 671px;
    margin-bottom: 24px;
    max-width: 100%;

    @media (max-width: vars.$break-point-lg) {
        margin-bottom: 20px;
    }
}

.checkboxes {
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;
    row-gap: 16px;

    @media (max-width: vars.$break-point-lg) {
        margin-bottom: 24px;
    }
}

.checkbox {
    display: flex;
}

.link {
    color: vars.$green_main;
}

.next {
    display: flex;
    width: fit-content;

    @media (max-width: vars.$break-point-lg) {
        width: 240px;
    }

    @media (max-width: vars.$break-point-sm) {
        width: 100%;
    }
}
