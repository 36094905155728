@use "@styles/variables.scss" as vars;

.tabs {
    display: flex;
    margin-bottom: 25px;
    border-bottom: 1px solid vars.$grey_lines;
    flex-grow: 0;

    padding-left: var(--page-padding);
    padding-right: var(--page-padding);
    @include vars.horizontal_scroll;

    @media (max-width: vars.$break-point-lg) {
        border-bottom: 1px solid vars.$grey_lines;
        margin-left: calc(var(--page-padding) * -1);
        margin-right: calc(var(--page-padding) * -1);
        width: calc(100% + var(--page-padding) * 2);
        max-width: calc(100% + var(--page-padding) * 2);
        --page-padding: 0;
        margin-bottom: 24px;
    }

    @media (max-width: vars.$break-point-sm) {
        --page-padding: 16px;
        overflow: scroll;
    }

    & li>div {
        white-space: nowrap;

        &:hover {
            color: vars.$grey_text;
        }
    }

    & li[class*='react-tabs__tab--selected'] {

        div {
            &:hover {
                color: vars.$green_main;
            }
        }
    }
}