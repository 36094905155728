@use '@styles/variables' as vars;

.container {
    width: 100%;
    padding: 8px 10px 9px;
    border: 1px solid vars.$grey_elements;
    border-radius: 8px;
    outline-color: vars.$grey_text;
    outline-width: 1px;
    font-family: vars.$baseFont;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 2rem;
    color: vars.$black_main;
    resize: none;
}