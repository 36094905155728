@use '@styles/_variables.scss' as vars;

.footer_container {
    display: flex;
    margin-top: 24px;
    justify-content: flex-end;

}

.btn_container {
    display: flex;
    column-gap: 16px;
}

.caption {
    margin-bottom: 4px;
}

.container {
    display: flex;
    flex-direction: column;
}

.attention {
    color: vars.$red_attention;
}
