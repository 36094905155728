@use "@styles/_variables.scss" as vars;

.wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 500px;

    .header {
        display: flex;
        gap: 0 20px;
        max-width: 95%;
        .name {
            @include vars.font_text22_extra_bold;
        }
    }

    .progress {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px 0;
        width: 195px;
        .name_progress {
            @include vars.font_text14;
            display: flex;
            gap: 7px;
            color: vars.$grey_text;
        }
    }

    .disciplines {
        display: flex;
        flex-direction: column;
        gap: 12px;
        margin-bottom: 20px;

        .disciplines_header {
            @include vars.font_text18;
        }

        .disciplines_list {
            display: flex;
            flex-direction: column;
            gap: 8px;
            list-style: disc;

            .list_item {
                @include vars.font_text14;

                margin-left: 24px;
                pointer-events: none;

                &_active {
                    color: vars.$green_main;
                    cursor: pointer;
                    pointer-events: all;
                }

                .text_wrapper {
                    display: flex;
                    align-items: flex-start;
                    justify-content: space-between;
                    gap: 10px;

                    .semester {
                        color: vars.$black_main;
                        white-space: nowrap;
                    }
                }
            }
        }
    }
}
