@use '@styles/mixins/typo' as mixins;
@use '@styles/variables.scss' as vars;

.wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;

    &:hover .controls {
        opacity: 1;
    }
}

.container {
    display: flex;
}

.heading {
    @include mixins.font_h2()
}

.labels {
    display: flex;
    align-items: flex-end;
}

.controls {
    display: flex;
    opacity: 0;
    transition: opacity 300ms ease;
}
