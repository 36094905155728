.container {
    width: 100%
}

.title {
    display: flex;

    h1 {
        margin-left: 12px;
    }
}


.control_heading {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 15px;
    /* identical to box height, or 150% */

    letter-spacing: 0.05em;
    text-transform: uppercase;

    margin-bottom: 4px;
}

.control_heading_big {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #222626;

    margin-bottom: 16px;
}

.container {
    margin-top: 24px;
    width: 100%;
}

.author_container {
    width: 100%;
}

.author_inner {
    display: flex;
    justify-content: space-between;
}

.author_breadcrumb {
    display: flex;
    align-items: center;
}

.breadcrumb_item {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;

    color: #A0A0A0;

    &::after {
        width: 12px;
        height: 12px;
        content: '';
        position: relative;
        top: 2px;

        display: inline-flex;

        margin-left: 4px;
        margin-right: 4px;
        background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4 2L8 6L4 10' stroke='%23A0A0A0' stroke-width='1.3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    }

    &:last-child {
        &::after {
            display: none;
        }
    }
}

.leftcolumn {
    display: flex;
    flex-direction: column;
}

.author {
    display: flex;
}

.author_name {
    margin-left: 12px;
}

.question_container {
    margin-top: 24px;
}

.heading_card {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 800;
    font-size: 22px;
    line-height: 30px;

    color: #222626;

    margin-bottom: 16px;
}

.heading_sub_card {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;

    color: #222626;

    margin-bottom: 8px;
    margin-top: 24px;
}

.paragraph {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    color: #222626;
}

.paragraph_gray {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    color: #A0A0A0;
}

.fileUpload {
    display: flex;
    padding: 9px 24px 9px 16px;
    height: 42px;

    border: 1px solid #E0E0E0;
    border-radius: 8px;

    max-width: 240px;

    align-items: center;
    cursor: pointer;

    margin-bottom: 8px;
}

.fileUploadIcon {
    margin-right: 8px;
    display: block;
    position: relative;
    top: 3px;
}

.fileUploadCaption {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}


.control_container {
    background: #F4F5F5;
    border-radius: 12px;
    padding: 24px;
    margin-top: 24px;
    display: flex;
    flex-direction: column;
}

.control_caption {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;

    color: #222626;
}


.control_caption_sub {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;

    color: #222626;

    margin-top: 24px;
    margin-bottom: 8px;
}

.goal {
    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 14px;

    width: 105px;
    height: 44px;

    background: #FFFFFF;
    border-radius: 8px;

    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 21px;

    color: #222626;
    align-items: center;
    justify-content: center;

    margin-top: 12px;

    margin-bottom: 8px;
}