@use "@styles/variables.scss" as vars;

.list_wrapper {
    background-color: vars.$grey_hover;
    border-radius: 10px;
    padding: 0px 16px;

    &--hidden {
        padding-right: 6px;
    }
}

.list {
    display: flex;
    flex-direction: column;

    &--hidden {
        overflow-y: auto;
        max-height: 146px;
        padding-right: 6px;

        &::-webkit-scrollbar {
            scroll-padding-top: 10px;
            width: 4px;
            padding-top: 12px;
            background-color: vars.$grey_hover;
        }

        &::-webkit-scrollbar-thumb {
            border-top: 12px solid vars.$grey_hover;
            border-bottom: 12px solid vars.$grey_hover;
            padding-top: 10px;
            width: 4px;
            border-radius: 5px;
            background-color: vars.$grey_elements;
        }
    }

    &__item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 12px;
        padding: 12px 0;
        border-bottom: 1px solid vars.$grey_elements;

        &:last-child {
            border-bottom: none;
        }
    }
}

.list_item {
    &__wrapper {
        display: flex;
        align-items: center;
        max-width: calc(100% - 40px);
    }

    &__icon {
        color: vars.$green_main;
    }

    &__name {
        padding-left: 8px;
        padding-right: 8px;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &__button {
        min-width: initial;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        background: vars.$grey_hover;
        border-radius: 8px;
        color: vars.$grey_text;

        &:hover {
            background: vars.$grey_elements;
        }
    }
}
