@use '@styles/_variables.scss' as vars;

.select {
    margin-bottom: 32px;
}

.button_wrapper {
    width: 100%;
    display: flex;
    column-gap: 16px;
    justify-content: flex-end;
}
