@use "@styles/_variables.scss" as vars;


.container {
    display: flex;
    cursor: pointer;
    height: 36px;
    display: flex;
    align-items: center;
}

.clip {
    width: 24px;
    height: 24px;

    path {
        stroke: vars.$grey_text;
    }
}
