@use '@styles/_variables.scss' as vars;

.root {
    display: flex;
    align-items: center;
    column-gap: 12px;
    padding: 16px 20px;
    margin-bottom: 18px;
    background: vars.$light_green;
    border-radius: 10px;

    @media (max-width: vars.$break-point-lg) {
        column-gap: 10px;
        margin-bottom: 16px;

        & svg {
            width: 24px;
            height: 24px;
        }
    }

    @media (max-width: vars.$break-point-md) {
        padding: 14px 16px;
    }

    & > p {
        margin-bottom: 0;

        @media (max-width: vars.$break-point-md) {
            font-size: 1.2rem;
            line-height: 1.6rem;
        }
    }
}
