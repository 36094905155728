@use "@styles/_variables.scss" as vars;

.container {
    padding-top: 32px;

    @media (max-width: vars.$break-point-lg) {
        padding-top: 28px;
    }

    @media (max-width: vars.$break-point-sm) {
        padding-top: 24px;
    }
}

.main_title {
    margin-bottom: 15px;
}

.main_content {
    table {
        max-width: 800px;
        margin-bottom: 20px;
    }

    td {
        padding: 8px;
        border: 1px solid black;
    }

    li:not(:last-child) {
        margin-bottom: 4px;
    }
}

.paragraph {
    margin-bottom: 16px;
}
