@import "@styles/_variables.scss";

.page {
    padding: 32px;
    width: 100%;
    .header {
        display: flex;
        align-items: center;
        gap: 16px;
        height: fit-content;
        span {
            color: $black_main;
            font-size: 28px;
            font-weight: 800;
            line-height: 36px;
            letter-spacing: -0.2px;
        }
    }

    .breadcrumbs {
        margin-top: 6px;
        margin-left: 40px;
        .breadcrumbs_item {
            @include font_text13;
        }
    }

    .content {
        margin-top: 24px;

        max-width: 1034px;
        width: 100%;

        padding: 24px;

        border-radius: 16px;
        border: 1px solid #f0f0f0;

        background: $white_main;

        box-shadow: 0px 4px 24px 0px rgba(53, 53, 53, 0.06);

        display: flex;
        flex-direction: column;
        gap: 32px;

        .accordeon {
            display: flex;
            flex-direction: column;
            gap: 8px;

            .accordeonItemContent {
                display: flex;
                flex-direction: column;
                gap: 4px;
                .discipline {
                    display: flex;
                    align-items: center;
                    gap: 6px;

                    &:first-child {
                        margin-top: 16px;
                    }
                }
            }
        }

        .buttons {
            display: flex;
            flex-direction: row;
            gap: 16px;
        }
    }
}

.empty {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;

    .text {
        color: $black_main;
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;
        margin-bottom: 24px;
    }
    .button {
        margin-bottom: 24px;
    }
}
