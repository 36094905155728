@import "@styles/_variables.scss";

.root {
    background-color: inherit;
    margin: 0 !important;
    box-shadow: none !important;
    border: 1px solid $grey_lines;
    border-radius: 12px !important;
    transition: border-color 0.2s ease;

    &:hover {
        border-color: $grey_hover;
    }

    &:first-child {
        border-radius: 16px;
    }

    &:last-child {
        border-radius: 16px;
    }

    &::before {
        display: none;
    }

    &--expanded {
        border-color: $grey_hover;
    }

    :global {
        .MuiAccordionSummary-root {
            min-height: 0 !important;
            border-radius: 12px;

            @media (min-width: $break-point-lg) {
                &:hover {
                    background-color: $grey_hover;

                    .MuiAccordionSummary-expandIconWrapper {
                        svg {
                            color: $black_main;
                        }
                    }
                }
            }
            @media (max-width: $break-point-lg) {
                align-items: flex-start;
                padding: 0 12px;
            }
        }

        .Mui-expanded.MuiAccordionSummary-root {
            background-color: $grey_hover;
        }


        .MuiAccordionSummary-expandIconWrapper {
            transition: opacity 0.3s ease;

            @media (max-width: $break-point-lg) {
                margin-top: 13px;
            }
        }

        .MuiAccordionSummary-content {
            margin: 0 !important;
            padding: 10px 0;
            @include font_text18;
            font-weight: 700;
            color: var(--steel-gray-900);

            @media (max-width: $break-point-lg) {
                padding: 10px 0;
                @include font_text18;
            }
        }

        .MuiAccordionDetails-root {
            display: flex;
            flex-direction: column;
            row-gap: 8px;
            white-space: pre-wrap;
            @include font_text18;
            font-weight: 400;
            color: var(--grey32);
            padding: 8px 16px;

            @media (max-width: $break-point-lg) {
                @include font_text14;
            }
        }
    }
}
