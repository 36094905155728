.container {
    width: 100%;
}

.table_header {
    display: flex;

    justify-content: space-between;
}

.table {
    display: flex;
    margin-top: 24px;
}

.header_controls {
    display: flex;
    column-gap: 16px;
}

.groups {
    width: 240px;
}

.search {
    display: flex;
    width: 280px;
}
