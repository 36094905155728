@use "@styles/_variables.scss" as vars;

.tab_list {
    display: flex;
    width: 100%;
    border-bottom: 1px solid vars.$grey_lines;
    margin-bottom: 32px;

    column-gap: 30px;

    @media (max-width: vars.$break-point-lg) {
        column-gap: 24px;
    }

    @media (max-width: vars.$break-point-md) {
        column-gap: 20px;
    }
}

.tab_list--btns {
    border-bottom: none;
    margin-bottom: 24px;
    column-gap: 16px;

    @media (max-width: vars.$break-point-lg) {
        margin-bottom: 20px;
    }
}

[class~="tab_items_count"] {
    display: flex;
    align-items: center;
    padding: 3px 7px 3px 7px;
    margin-left: 8px;
    border-radius: 50px;
    line-height: 1.6rem;
    height: fit-content;
    transition: all 0.3s ease;
    @include vars.font_text13;

    @media (max-width: vars.$break-point-lg) {
        padding: 2px 7px 3px 7px;
        @include vars.font_text12;
    }
}

.tabs_content {
    display: flex;

    @media (max-width: vars.$break-point-lg) {
        align-items: center;
    }
}