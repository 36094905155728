@use "@styles/variables.scss" as vars;

.dropbtn {
    color: black;

    &:hover {
        color: black;
    }
}

.menu {
    display: flex;
    background: vars.$white_main;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    border-radius: 10px;

    ul {
        margin: 0px;
        margin: 8px 0px;

        li {
            display: flex;
            padding: 6px 20px 8px;
            transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1);
            white-space: nowrap;

            &:hover {
                background-color: vars.$grey_hover;
                cursor: pointer;
            }
        }
    }
}

.active {
    color: vars.$green_main;
}
