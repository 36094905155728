@use "@styles/_variables.scss" as vars;

.main {
    padding-top: 32px;
    padding-left: 0;
    padding-right: 0;

    @media (max-width: vars.$break-point-lg) {
        padding-top: 28px;
    }

    @media (max-width: vars.$break-point-sm) {
        padding-top: 24px;
    }
}

.content_wrapper {
    margin-top: 24px;

    @media (max-width: vars.$break-point-lg) {
        margin-top: 32px;
    }
}

.section_list {
    margin-top: 0px;
}

.title {
    word-break: break-word;
}

.stickers {
    display: flex;
    column-gap: 12px;
    margin-top: 12px;

    @media (max-width: vars.$break-point-lg) {
        margin-top: 8px;
        column-gap: 6px;
    }
}

.sticker {
    display: flex;
    align-items: center;
    column-gap: 6px;
    padding: 10px 14px;
    background-color: vars.$grey_hover;
    border-radius: 8px;

    @media (max-width: vars.$break-point-lg) {
        background-color: inherit;
        padding: 0px;
        @include vars.font_text12;
    }

    &>span {
        @media (max-width: vars.$break-point-lg) {
            margin-left: 8px;
        }
    }
}

.deadline_sticker {
    @media (max-width: vars.$break-point-lg) {
        display: none;
    }
}

.dashboard {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
}

.sidebar-buttons-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
    column-gap: 16px;
    row-gap: 12px;
    margin-top: 20px;

    @media (max-width: vars.$break-point-sm) {
        flex-direction: column;
        column-gap: 12px;

    }

    @media (max-width: vars.$break-point-md) {
        margin-top: 0px;
        row-gap: 16px;
    }
}

.sidebar-wrapper {
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    
    @media (max-width: vars.$break-point-sm) {
        margin-top: 18px;
    }
}

.deadline_alert {
    display: flex;
    padding: 16px 16px 20px;
    border-radius: 12px;
    margin-bottom: 24px;

    @media (max-width: vars.$break-point-lg) {
        border-radius: 10px;
        padding: 14px 16px 20px;
    }

    @media (max-width: vars.$break-point-md) {
        margin-bottom: 0px;
    }

    &--doubt {
        background-color: vars.$red_pale;

        path {
            stroke: vars.$red_attention;
        }
    }

    &--expires {
        background-color: vars.$yellow_pale;

        path {
            stroke: vars.$orange;
        }
    }

    &--success {
        background-color: vars.$green_main;
        color: vars.$white_main;

        path {
            stroke: vars.$green_main;

            @media (max-width: vars.$break-point-lg) {
                stroke: vars.$white_main;
            }
        }

        rect {
            @media (max-width: vars.$break-point-lg) {
                stroke: vars.$white_main;
                fill: vars.$white_main;
            }
        }

        .deadline_alert__icon {
            padding: 0px;

            @media (max-width: vars.$break-point-lg) {
                margin-top: 4px;
            }
        }
    }

    &__icon {
        display: flex;
        align-items: center;
        justify-content: center;

        margin: 4px 12px 0px 0px;
        min-width: 32px;
        height: 32px;
        padding: 0px 0px 4px 2px;

        border-radius: 50%;
        background-color: vars.$white_main;

        @media (max-width: vars.$break-point-lg) {
            margin: 0px 10px 0px 0px;

            border: none;
            background-color: inherit;
            min-width: fit-content;
            height: fit-content;
        }
    }

    &__header {
        @include vars.font_text18;
        margin-bottom: 8px;

        @media (max-width: vars.$break-point-lg) {
            margin-bottom: 2px;

            @include vars.font_text14_bold;
            line-height: 2rem;
        }
    }

    &__description {
        @media (max-width: vars.$break-point-lg) {
            @include vars.font_text12;
        }
    }
}