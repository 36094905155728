@use "@styles/_variables.scss" as vars;

.info {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
}

.user_info {
    display: flex;
    align-items: center;
    column-gap: 12px;
}

.user_name {
    @include vars.font_text15;
    margin-bottom: 2px;
}

.avatar {
    cursor: initial;
}

.breadcrumbs_item {
    @include vars.font_text13;
}

.paper {
    width: 100%;
}
