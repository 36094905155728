@use "@styles/_variables.scss" as vars;

.root {
    border-radius: 16px;
    overflow: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;
    border: 1px solid vars.$grey_lines;
    box-sizing: content-box;

    &:hover {
        box-shadow: 0px 4px 24px rgba(53, 53, 53, 0.06);
    }

    @media (max-width: vars.$break-point-lg) {
        border-radius: 0;
        border: none;
        box-sizing: border-box;

        &:hover {
            box-shadow: none;
        }
    }
}

.image_container {
    position: relative;
    font-size: 0;
    line-height: 0;
    padding-bottom: 54%;
    overflow: hidden;

    @media (max-width: vars.$break-point-lg) {
        border-radius: 12px;
        overflow: hidden;
        border: none;
    }

    @media (max-width: vars.$break-point-sm) {
        border-radius: 10px;
        padding-bottom: 73%;
    }

    .image_wrapper {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: vars.$grey_hover;
    }
}

.score {
    display: flex;
    align-items: center;
    padding: 6px 10px;
    gap: 8px;
    position: absolute;
    left: 16px;
    top: 16px;
    background: vars.$white_main;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12);
    border-radius: 6px;
    font-size: 1.3rem;
    line-height: 1.7rem;
    z-index: vars.$low_index;

    @media (max-width: vars.$break-point-lg) {
        left: 8px;
        top: 8px;
        @include vars.font_text11;
        padding: 4px 6px;
    }
}

.image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.progress_block {
    position: absolute;
    left: 11px;
    bottom: 0;
    min-width: 81.87px;
    height: 24px;
    display: flex;
    align-items: center;
    column-gap: 6px;
    padding: 0 14px;
    color: vars.$green_success;
    background: transparent url("/assets/icons/student-discipline-svg.svg") no-repeat center;

    span {
        @include vars.font_text13;

        @media (max-width: vars.$break-point-lg) {
            @include vars.font_text10;
            column-gap: 4px;
        }
    }
}

.description {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 12px 18px 16px;
    height: 188px;

    @media (max-width: vars.$break-point-lg) {
        padding: 10px 0px 0px;
        height: 109px;
    }

    @media (max-width: vars.$break-point-md) {
        padding: 10px 0px 0px;
        height: 84px;
    }

    @media (max-width: vars.$break-point-sm) {
        height: 95px;
    }
}

.title {
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 2rem;
    margin-bottom: 12px;

    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;

    &:hover {
        color: vars.$green_main;
        transition: color 0.2s ease;
    }

    @media (max-width: vars.$break-point-lg) {
        @include vars.font_text14;
        margin-bottom: 6px;
        -webkit-line-clamp: 2;
    }

    @media (max-width: vars.$break-point-md) {
        font-size: 1.3rem;
        line-height: 1.7rem;
    }

    @media (max-width: vars.$break-point-sm) {
        font-size: 1.3rem;
        line-height: 1.7rem;
        -webkit-line-clamp: 3;
    }
}

.status {
    padding: 6px 10px 7px;
    border: 1px solid vars.$grey_elements;
    border-radius: 6px;
    margin-bottom: 18px;
    font-size: 1.2rem;
    line-height: 1.5rem;
    color: vars.$grey_text;
}

.deadline {
    @include vars.font_text12;
    color: vars.$grey_text;

    @media (max-width: vars.$break-point-lg) {
        @include vars.font_text11;
    }
}
