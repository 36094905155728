@use "@styles/_variables.scss" as vars;

.list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 8px;
    row-gap: 8px;
}

.link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: vars.$white_main;

    &:hover {
        background-color: vars.$light_green;
        transition: background-color 0.3s ease;
    }
}
