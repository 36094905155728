@use '@styles/_variables.scss' as vars;

.wrapper {
    padding: 24px;
    background: vars.$grey_hover;
    border-radius: 16px;
    width: calc(100% + var(--padding-container) * 2);
    margin: 0 calc(var(--padding-container) * -1);

    --padding-container: 0;

    @media (max-width: vars.$break-point-lg) {
        --padding-container: 24px;

        border-top-left-radius: 0;
        border-top-right-radius: 0;
        padding-bottom: 26px;
    }

    @media (max-width: vars.$break-point-md) {
        --padding-container: 24px;

        padding: 20px 20px 24px;
    }

    @media (max-width: vars.$break-point-sm) {
        padding: 16px 16px 24px;
    }
}

.title {
    margin-bottom: 12px;
}

.content {
    display: flex;
    flex-direction: column;
    row-gap: 24px;

    @media (max-width: vars.$break-point-md) {
        row-gap: 24px;
    }
}

.scores {
    display: block;
    padding: 12px 14px;
    background: vars.$white_main;
    border-radius: 8px;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 2.1rem;

    @media (max-width: vars.$break-point-md) {
        padding: 8px 10px;
    }

    &_wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        row-gap: 8px;

        @media (max-width: vars.$break-point-md) {
            row-gap: 8px;
        }
    }
}

.date,
.teacher {
    font-weight: 500;
    font-size: 1.3rem;
    line-height: 1.8rem;
    color: vars.$grey_text;
}

.feedback_title {
    margin-bottom: 8px;
}

.review_container {
    width: 100%;

    &>p {
        max-width: 100%;
        word-wrap: break-word;
    }
}

.retake-button {
    margin-top: 16px;
}

.noPadding {
    padding: 0;
}
