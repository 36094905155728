@use '@styles/variables.scss' as vars;

.title {
    margin-bottom: 24px;
}

.action {
    margin-bottom: 24px;
}

.input_wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    margin-bottom: 32px;
}

.button_wrapper {
    width: 100%;
    display: flex;
    column-gap: 16px;
    justify-content: flex-end;
    margin-top: 16px;
}

.select {
    margin-bottom: 32px;

    &__input {
        border-color: vars.$outline_borders !important;
        transition: border-color 0.3s;

        &:hover {
            border-color: vars.$outline_hover !important;
        }
    }

    &__icon {
        color: vars.$grey_text;
    }
}