@use '@styles/_variables.scss' as vars;

.header {
    @include vars.centering;

    @media (min-width: vars.$break-point-lg) {
        justify-content: flex-start;
        column-gap: 40px;
    }

    @media (max-width: vars.$break-point-lg) {
        column-gap: unset;
        padding-top: 18px;
        padding-bottom: 18px;
    }

    @media (max-width: vars.$break-point-md) {
        padding-top: 15px;
        padding-bottom: 15px;
    }

    @media (max-width: vars.$break-point-sm) {
        padding-top: 0;
        padding-bottom: 0;
        min-height: 56px;
    }

    @media (min-width: vars.$break-point-md) {
        column-gap: 30px;
    }
}

.wrapper {
    display: flex;
    align-items: center;
    gap: 30px;

    @media (max-width: vars.$break-point-md) {
        gap: 24px;
    }
}

.tabs {
    width: auto;
    flex-grow: 1;
    margin-bottom: 0;
    border-bottom: none;
    column-gap: 30px;

    @media (max-width: vars.$break-point-lg) {
        column-gap: 24px;
    }

    @media (max-width: vars.$break-point-md) {
        column-gap: 20px;
    }

    & > [class*="tab_root"] {
        & > a {
            display: block;
            top: 0;
            padding-bottom: 0;
            border-bottom: none;
            color: vars.$black_grey;
            margin: 0;

            &:hover {
                color: vars.$grey_text;
            }

            @media (max-width: vars.$break-point-lg) {
                font-size: 1.4rem;
            }
        }

        & > [class*="tab_root--selected"] {
            color: vars.$green_main;

            &:hover {
                color: vars.$green_main;
            }

            &::after {
                content: "";
                position: absolute;
                left: 0;
                top: 47px;
                width: 100%;
                height: 2px;
                background: vars.$green_main;

                @media (max-width: vars.$break-point-md) {
                    top: 44px;
                }
            }
        }
    }
}
