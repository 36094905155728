@use '@styles/_variables.scss' as vars;

.paper {
    border-radius: 16px 16px 0 0;
}

.container {
    position: relative;
    padding: 32px 20px;
    width: 100%;
    max-height: 376px;

    &_handle {
        width: 48px;
        height: 3px;
        content: "";
        background-color: vars.$outline_borders;
        border-radius: 3px;
        position: absolute;
        top: 6px;
        left: 50%;
        transform: translateX(-50%);
    }
}
