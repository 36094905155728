@use '@styles/_variables.scss' as vars;

.root {
    margin-top: 16px;
}

.list {
    display: flex;
    flex-direction: column;
    row-gap: 4px;

    &__item {}

    &__item_wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        min-height: 42px;
        padding: 8px 12px;
        border: 1px solid vars.$grey_elements;
        border-radius: 8px;
    }
}

.close {
    transition: stroke 0.2s ease-in;
    cursor: pointer;
    height: 20px;

    &:hover {
        svg {
            path {
                stroke: black;
            }
        }

    }
}