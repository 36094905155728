@use '@styles/_variables.scss' as vars;

.root {
    @media (max-width: vars.$break-point-lg) {
        padding-bottom: 0;
    }

    @media (max-width: vars.$break-point-md) {
        padding: 20px 20px 0;
    }

    @media (max-width: vars.$break-point-sm) {
        padding: 16px 16px 0;
    }

    &:hover .delete {
        opacity: 1;
    }
}

.row {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 14px;
}

.content {
    display: flex;
    flex-direction: column;
    row-gap: 22px;
}

.file_upload {
    display: flex;
    flex-direction: column;
    row-gap: 6px;
    max-width: 360px;
    width: 100%;

    & a {
        max-width: 100%;

        & p {
            width: 90%;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    @media (max-width: vars.$break-point-sm) {
        max-width: 100%;
    }
}

.gradeComment {
    word-wrap: break-word;
    max-width: 722px;
    overflow: hidden;
}

.margin {
    @media (max-width: vars.$break-point-lg) {
        margin-bottom: 22px;
    }
}

.date {
    font-weight: 500;
    font-size: 1.3rem;
    line-height: 1.8rem;
    color: vars.$grey_text;
}

.assessment {
    padding: 22px 24px;
    background: vars.$grey_hover;
    border-radius: 16px;

    &__title {
        margin-bottom: 10px;
    }

    &__content {
        display: flex;
        flex-direction: column;
        row-gap: 22px;
    }

    &__scores {
        display: block;
        padding: 6px 14px;
        background: vars.$white_main;
        border-radius: 8px;
        font-weight: 700;
        font-size: 1.5rem;
        line-height: 2.1rem;

        &_wrapper {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            row-gap: 6px;
        }
    }

    &__feedback_title {
        margin-bottom: 6px;
    }
}

.controls {
    display: flex;
}

.delete {
    display: flex;
    opacity: 0;
    transition: opacity 300ms ease;
}

.timer {
    border-radius: 6px;
    background: vars.$grey_hover;
    display: flex;
    color: vars.$grey_text;

    font-size: 1.4rem;
    line-height: 2rem;

    padding: 6px 10px;

    margin-right: 8px;



    svg {
        margin-right: 6px;
        path{
            stroke: vars.$grey_text;
        }
    }
}

