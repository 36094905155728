@use '@styles/variables.scss' as vars;


.item {
    display: flex;
    padding: 12px;
    border-radius: 10px;

    justify-content: flex-start;
    border: 1px solid vars.$grey_lines;
    align-items: center;

    margin-bottom: 8px;
}

.select {
    display: flex;
}

.content {
    display: flex;
    flex-direction: column
}

.title {
    @include vars.font_text14();
    color: vars.$black_main;
}

.control {
    display: none;
    width: 240px;
    margin-top: 10px;
}

.checked {
    background-color: vars.$grey_hover;

    .control {
        display: block;
    }
}