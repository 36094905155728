@use "@styles/_variables.scss" as vars;

.container {
    padding-top: 32px;

    @media (max-width: vars.$break-point-lg) {
        padding-top: 28px;
    }

    @media (max-width: vars.$break-point-sm) {
        padding-top: 24px;
    }
}

.main_title {
    margin-bottom: 15px;
}

.main_content {
    a {
        color: vars.$green_main;

        &:hover {
            text-decoration: underline;
        }
    }

    strong {
        font-weight: bold;
    }

    em {
        font-style: italic;
    }

    ol {
        display: block !important;

        ol {
            padding-inline-start: 40px !important;

            @media (max-width: vars.$break-point-sm) {
                padding-inline-start: 20px !important;
            }
        }
    }

    li {
        &:not(:last-child) {
            margin-bottom: 16px;
        }
    }

    & > ol > li {
        &:not(:last-child) {
            margin-bottom: 24px;
        }
    }
}

.paragraph {
    padding-bottom: 16px;

    @media (max-width: vars.$break-point-sm) {
        padding-bottom: 12px;
    }
}
