@use "@styles/_variables.scss" as vars;

.data_list {
    width: 100%;

    &__header {
        padding: 12px;
        padding-top: 0;
        grid-template-columns: 70% 30%;
        gap: 0;
        color: vars.$grey_text;

        @media (max-width: vars.$break-point-lg) {
            grid-template-columns: 60% 40%;
        }

    }
}

.student_grid {
    grid-template-columns: 70% 30%;
    gap: 0;

    @media (max-width: vars.$break-point-lg) {
        grid-template-columns: 60% 40%;
    }

    @media (max-width: vars.$break-point-sm) {
        grid-template-columns: repeat(1, 1fr);
        row-gap: 4px;
    }
}

.list_item {
    display: flex;
    align-items: baseline;
    column-gap: 4px;
    padding: 12px 12px;
    border-radius: 8px;

    @media (max-width: vars.$break-point-lg) {
        padding: 12px 12px;
    }

    &__header {
        padding: 9px 12px 9px;
        background-color: vars.$light_green;

        @media (max-width: vars.$break-point-lg) {
            padding: 10px 12px 10px;
        }

        @media (max-width: vars.$break-point-md) {
            padding: 10px 12px 11px 12px;
        }

        @media (max-width: vars.$break-point-sm) {
            flex-direction: column;
        }
    }

    &__title_discipline {
        font-weight: 700;
        font-size: 1.5rem;
        line-height: 2.1rem;
        word-break: break-word;
        hyphens: auto;

        &:hover {
            text-decoration: underline;
        }

        @media (max-width: vars.$break-point-sm) {
            margin-bottom: 2px;
            font-weight: 700;
            font-size: 1.5rem;
            line-height: 1.9rem;
        }

        @media (max-width: vars.$break-point-lg) {
            line-height: 2rem;
        }
    }

    &__task_name {
        @media (max-width: vars.$break-point-sm) {
            text-transform: uppercase;
            margin-bottom: 2px;
            font-weight: 700;
            font-size: 1rem;
            line-height: 1.5rem;
        }
    }

    &__student {
        cursor: pointer;

        &:hover {
            color: vars.$green_main;
        }
    }
}

.list_block {
    position: relative;

    &:last-child {
        .list_item {
            padding-bottom: 10px;
        }
    }
}

.block_overdue {
    margin-left: 4px;
    padding: 2px 8px 3px;
    border: 1px solid rgba(251, 60, 60, 0.5);
    border-radius: 25px;
    font-weight: 600;
    font-size: 0.9rem;
    line-height: 1.5rem;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: vars.$red_attention;

    @media (max-width: vars.$break-point-lg) {
        padding: 0;
        border: none;
        margin-left: 0;
    }
}

.deadline_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 10px;
}

.deadline {
    display: flex;
    align-items: center;
    column-gap: 6px;

    &__date {
        @media (max-width: vars.$break-point-lg) {
            font-size: 1.2rem;
            color: vars.$grey_text;
        }
    }
}

.grade_link {
    color: vars.$green_main;
}

.empty_message {
    margin: 120px auto 0px auto;
    text-align: center;
    color: vars.$grey_empty;

    &>* {
        color: vars.$black_main;
        margin: 0px auto 12px auto;
    }

    &>*:last-child {
        margin-bottom: 0px;
    }

    &_header {
        @include vars.font_text18;
    }

    &_text {
        @include vars.font_text14;
    }
}

.paper {
    padding: 8px 12px 10px 12px;
    max-width: 220px;
    border: none !important;
}

.column_label {
    color: vars.$grey_text;
}

.with-shadow {
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1) !important;
}

.rows {
    position: relative;
    padding-bottom: 2px;
}


.with-shadow {
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1) !important
}
