@use '@styles/_variables.scss' as vars;

.container {
    position: relative;
}

.button_wrapper {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 0;
    top: 46px;
    background-color: vars.$white_main;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 8px 0;
    z-index: vars.$hight_index;
    width: 144px;
}

.menu {
    left: unset;
    right: 0;
}

.item {
    padding: 6px 20px 8px;
    height: 34px;
    cursor: pointer;

    &:hover {
        background-color: vars.$grey_hover;
    }
}
