@use "@styles/_variables.scss" as vars;

.question {
    &_paper {
        padding: 24px 24px 28px 24px;

        &:not(last-child) {
            margin-bottom: 24px;
        }
    }

    &_header {
        @include vars.font_text15_bold;
        margin-bottom: 16px;
    }

    &_description {
        margin-bottom: 24px;

        p:last-child {
            margin-bottom: 0px;
        }
    }
}

.answers {
    &_header {
        color: vars.$outline_hover;
        margin-bottom: 16px;
    }

    &_wrapper {
        width: fit-content;
        margin-bottom: 24px;
    }

    &_input {
        justify-content: space-between;
        &:not(:last-child) {
            margin-bottom: 16px;
        }
    }

    &_select_wrapper {
        display: flex;
        align-items: center;
        pointer-events: none;

        &.answers_input {
            justify-content: flex-start;
        }

        p {
            margin: 0px 16px 0px 0px;
            color: vars.$grey_text;
        }

        .icon {
            margin-left: 6px;
        }
    }

    &_select {
        width: 280px;
    }
}

.radio {
    label {
        color: vars.$grey_text;
        display: flex;
        align-items: center;
    }
}

.checkbox {
    label {
        span {
            color: vars.$grey_text;
            display: flex;
            align-items: center;
        }
    }
}

.icon {
    margin-left: 4px;
}

.right_answer {
    label {
        color: vars.$green_success;

        span {
            color: vars.$green_success;
        }
    }
}

.wrong_answer {
    label {
        color: vars.$red_attention;

        span {
            color: vars.$red_attention;
        }
    }
}

.right_answer_description {
    border-radius: 8px;
    padding: 12px;
    background-color: vars.$grey_hover;
}

.question_wrapper {
    scroll-margin-top: 250px;
}

.csv-preview {
    display: flex;
    align-items: center;
    height: 100%;
    white-space: pre-wrap;

    & p {
        display: inline;
    }
}
