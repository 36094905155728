@use "@styles/_variables.scss" as vars;

.wrapper {
    display: flex;
    min-height: 100vh;
}

.form_wrapper {
    display: flex;
    flex-direction: column;
    width: 476px;
    padding: 44px 48px;

    @media (max-width: vars.$break-point-lg) {
        width: 415px;
        padding: 32px 40px;
    }

    @media (max-width: vars.$break-point-sm) {
        width: 100%;
        padding: 24px 20px;
    }
}

.header {
    margin-bottom: 36px;

    @media (max-width: vars.$break-point-lg) {
        margin-bottom: 32px;
    }
}

.logo {
    width: 324px;
    height: 48px;

    & svg {
        width: inherit;
        height: inherit;
    }

    @media (max-width: vars.$break-point-lg) {
        width: 280px;
        height: 41.4px;
    }
}

.background_wrapper {
    flex-grow: 1;
    background: #535458 url("../../assets/image/background-login-form.jpg") no-repeat left;
    background-size: cover;
}

.form {
    flex-grow: 1;
}

.footer {
    flex-shrink: 0;

    &__title {
        margin-bottom: 12px;

        @media (max-width: vars.$break-point-lg) {
            margin-bottom: 8px;
            @include vars.font_h3;
        }
    }

    &__schedule {
        margin-bottom: 20px;
    }
}

.contacts {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    &__block {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    &__label {
        color: vars.$grey_text;
    }
    &__link {
        font-size: 2rem;
        font-weight: 600;
        color: vars.$green_main;

        @media (max-width: vars.$break-point-lg) {
            @include vars.font_h3;
        }

        &:hover {
            color: vars.$green_dark;
        }
    }
}
