@use "@styles/_variables.scss" as vars;

.container {
    padding: 28px 32px;

    @media (max-width: vars.$break-point-md) {
        padding: 20px 20px 24px 20px;
    }

    @media (max-width: vars.$break-point-sm) {
        padding: 16px 16px 24px 16px;
    }
}

.header {
    display: flex;
    column-gap: 16px;
    align-items: center;
    margin-bottom: 24px;

    @media (max-width: vars.$break-point-lg) {
        margin-bottom: 20px;
    }
}

.icon {
    display: flex;
}

.description {
    margin-bottom: 24px;
}

.title {
    @include vars.font_text19();

    @media (max-width: vars.$break-point-sm) {
        @include vars.font_h3();
    }
}

.btn_wrapper {
    width: fit-content;

    // @media (max-width: vars.$break-point-lg) {
    //     width: 240px;
    // }
    @media (max-width: vars.$break-point-sm) {
        width: 100%;
    }

    button {
        @media (max-width: vars.$break-point-sm) {
            width: 100%;
        }
    }
}
