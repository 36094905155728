@use "@styles/_variables.scss" as vars;

.title_wrapper {
    display: flex;
    align-items: center;
    column-gap: 16px;
    margin-bottom: 26px;
}

.paper {
    min-width: 700px;
}

.form_content {
    display: flex;
    flex-direction: column;
    row-gap: 32px;

    &__button_wrapper {
        display: flex;
        align-items: center;
        column-gap: 16px;
    }
}

.fieldset {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
}

.fieldset_title {
    margin-bottom: 16px;
}

.input_wrapper {
    display: grid;
    grid-template-columns: 25% auto;
    column-gap: 16px;

    &--center_content {
        & > label,
        p {
            height: 42px;
            display: flex;
            align-items: center;
        }
    }
}

.add_button__wrapper {
    position: relative;
}

.content_list {
    margin-bottom: 20px;

    &__item {
        position: relative;
        display: grid;
        grid-template-columns: 25% auto;
        column-gap: 16px;
        align-items: center;
        padding: 12px 0;
        border-bottom: 1px solid vars.$grey_lines;

        &:hover .content_list__button {
            opacity: 1;
        }

    }

    &__user_name {
        display: flex;
        align-items: center;
        column-gap: 12px;
    }

    &__button {
        position: absolute;
        top: 22px;
        right: 1px;
        opacity: 0;
        transition: opacity 300ms ease;
    }
}

.content_list__button_hover {
    &:hover {
        opacity: 0.8;
    }
}

.avatar_color {
    background-color: vars.$grey_hover;
    color: vars.$black_main;
}