.switcher-wrapper {
    display: flex;
    align-items: center;
    max-width: 452px;
    margin-bottom: 16px;

    &>span {
        margin-left: 16px;
    }
}

.inherit-button {
    margin: 24px 0 32px;
    width: 452px;
    padding: 9px 16px 10px;
    height: unset;

    .loader {
        height: 40px;
        width: 40px;
    }
}