@use '@styles/_variables.scss' as vars;

.container {
    max-width: 500px;
}

.wrapper {
    overflow-y: auto;
    max-height: 300px;

    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none; // Safari and Chrome
        background-color: transparent;
    }
}

.offset {
    margin-bottom: 16px;

    &:last-child {
        margin-bottom: 0;
    }
}

.total {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
    @include vars.font_text15_bold;

    & p {
        margin: 0;

        @media (max-width: vars.$break-point-sm) {
            font-weight: 600;
        }
    }
}

.footer {
    margin-top: 32px;
    width: 100%;
    display: flex;
    justify-content: flex-end;

    @media (max-width: vars.$break-point-sm) {
        & button {
            width: 100%;
            height: 42px;
        }
    }
}

.title {
    word-wrap: break-word;
    margin-bottom: 16px;
}

.drawer-content {
    max-height: unset;
}
