@use '@styles/_variables.scss' as vars;

.title_wrapper {
    display: flex;
    align-items: center;
    column-gap: 12px;
    margin-bottom: 24px;
}

.create_section {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-top: 104px;

    &__background {
        width: 264px;
        height: 231px;
        background: url("/assets/image/add-lecture-preview.svg") no-repeat center;
    }

    &__title {
        margin-bottom: 12px;
    }

    &__text {
        width: 280px;
        text-align: center;
        margin-bottom: 24px;
    }
}

.add_button {
    margin-bottom: 24px;
}

.topic_cards_wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    width: 100%;
}

