@use '@styles/_variables.scss' as vars;


.subscribeItem {
    display: flex;

    flex-direction: column;

    border-radius: 10px;
    background-color: vars.$grey_hover;
    padding: 14px 12px;

    margin-bottom: 8px;

    &:last-child {
        margin-bottom: 0px;
    }

    cursor: pointer;
}

.subscribeItemHeader {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.headerWrapper {
    display: flex;
    flex-grow: 1;
}

.checkbox {
    padding-top: 1px;
    margin-right: 12px;
    z-index: vars.$default_index;
}

.caption {
    display: flex;
    flex-grow: 1;
    margin-right: 16px;
}

.switcher {
    display: flex;
}

.actions {
    display: flex;
    margin-top: 32px;
    column-gap: 16px;
}

.forms {
    display: flex;
    margin-top: 12px;
    column-gap: 8px;
}

.control {
    display: flex;
    width: 200px;
}

.content {
    display: flex;
    flex-direction: column;
}