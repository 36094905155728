@use "@styles/_variables.scss" as vars;

.title_wrapper {
    display: flex;
    align-items: center;
    column-gap: 12px;
    margin-bottom: 26px;

    h1:not(:last-child) {
        margin-bottom: 4px;
    }
}

.profile {
    width: 1034px;
}

.profile_header_container {
    display: flex;
}

.profile_header {
    display: flex;
    align-items: center;
}

.profile_photo_container {
    width: 96px;
    height: 96px;
    margin-right: 20px;
    border-radius: 50%;
    overflow: hidden;
    background-color: vars.$grey_hover;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    position: relative;

    img {
        max-width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.header_info {
    width: 644px;
    margin-right: 24px;

    word-wrap: break-word;
}

.status {
    display: inline-block;
    padding: 4px 12px;
    margin-bottom: 8px;
    background: vars.$grey_hover;
    color: vars.$grey_text;
    font-weight: 700;
    border-radius: 50px;

    &_success {
        background: vars.$green_success;
        color: vars.$white_main;
    }
}

.profile_breadcrumps {
    color: vars.$outline_hover;
    @include vars.font_text14;
}

.profile_photo_name {
    color: vars.$grey_text;
    @include vars.font_text32;
}

.profile_photo_icon {
    background-color: vars.$profile_grey_dark;
    width: 100%;
    height: 28px;

    position: absolute;
    bottom: 0;

    display: flex;
    align-items: center;
    justify-content: center;
}

.profile_main_info {
    display: grid;
    grid-template-columns: 613px 373px;
    grid-template-rows: auto auto;
    row-gap: 24px;

    padding-top: 24px;
    border-top: 1px solid vars.$grey_lines;
}

.subtitle {
    margin-bottom: 16px;
}

.section_net {
    display: grid;
    grid-template-columns: 194px auto;
    gap: 16px;
    margin-bottom: 24px;
}

.section {
    @include vars.font_text14;

    &:nth-child(2) {
        grid-row-start: 1;
        grid-row-end: 2;
        grid-column-start: 2;

        border-left: 1px solid vars.$grey_lines;
        padding-left: 16px;

        .section_link {
            margin-bottom: 8px;
        }
    }
}

.section_link {
    @include vars.font_text14;
    color: vars.$green_main;
    cursor: pointer;
}

.section_caption {
    color: vars.$grey_text;
    margin-bottom: 24px;
}

.activity {
    margin-top: 16px;
    margin-bottom: 24px;
    background-color: vars.$grey_hover;
    padding: 10px 16px;
    border-radius: 8px;

    & > * {
        color: vars.$black_grey;

        &:not(:last-child) {
            margin-bottom: 6px;
        }
    }
}

.remove {
    display: flex;
    cursor: pointer;

    & > *:first-child {
        margin-right: 8px;

        path {
            stroke: vars.$red_attention_hover;
        }
    }
}

.remove_link {
    color: vars.$red_attention_hover;
}

.socialNetworks {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
}

.input {
    width: 240px;
}

.save_btn {
    width: fit-content;
    margin-left: 210px;
}

.field {
    display: flex;
    margin-bottom: 16px;

    &:last-child {
        margin-bottom: 0px;
    }
}

.label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 194px;
    margin-right: 16px;
}

.role {
    margin-top: 8px;
}

.content {
    display: flex;
    width: 194px;
    margin-right: 16px;
    flex-direction: column;
}

.userActivity {
    display: flex;
    column-gap: 16px;
}

.chatAvailability {
    display: flex;
    column-gap: 16px;
    margin-top: 16px;
}

.caption {
    display: flex;
}

.activitySwitcher {
    display: flex;
}

.notice {
    display: flex;
    align-items: center;

    & > p {
        margin-right: 16px;
    }
}
