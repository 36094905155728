@use '@styles/_variables.scss' as vars;

.main {
    flex-grow: 0;
    width: 100%;
    max-width: 800px;
    padding-top: 32px;
    padding-left: 0;
    padding-right: 0;

    @media (max-width: vars.$break-point-lg) {
        padding-top: 28px;
    }

    @media (max-width: vars.$break-point-sm) {
        padding-top: 24px;
    }

    @media (max-width: vars.$break-point-md) {
        padding-top: 0px;
    }
}

.main_title {
    margin-bottom: 22px;

    @media (max-width: vars.$break-point-md) {
        margin-bottom: 20px;
    }
}

.content_wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    width: 100%;

    @media (max-width: vars.$break-point-lg) {
        row-gap: 20px;
    }
}
