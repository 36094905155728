@use '@styles/_variables.scss' as vars;

.root {
    border-bottom: 1px solid vars.$grey_lines;

    &:last-child {
        border-bottom: none;
    }

    &--video {
        border-bottom: none;
    }

    @media (max-width: vars.$break-point-lg) {
        margin-bottom: 16px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    & + .root--video {
        margin-top: 16px;
    }
}

.no-adaptive-border {
    @media (max-width: vars.$break-point-lg) {
        border-bottom: none;
    }
}
