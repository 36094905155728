@use "@styles/_variables.scss" as vars;

.main_title {
    margin-bottom: 8px;
    @include vars.font_title;

    @media (max-width: vars.$break-point-lg) {
        @include vars.font_h1;
        margin-bottom: 12px;
    }

    @media (max-width: vars.$break-point-sm) {
        @include vars.font_text19;
    }
}

.lecture_title {
    margin-bottom: 16px;
    @include vars.font_text11;
    text-transform: uppercase;
    color: vars.$black_grey;

    @media (max-width: vars.$break-point-lg) {
        font-size: 1rem;
        font-weight: 700;
        letter-spacing: 0.05em;
        margin-bottom: 12px;
    }
}

.chapter_count {
    display: flex;
    padding: 4px 10px;
    margin-bottom: 24px;
    color: vars.$grey_text;
    border: 1px solid vars.$grey_elements;
    border-radius: 6px;

    @media (max-width: vars.$break-point-lg) {
        margin-bottom: 20px;
    }

    @media (max-width: vars.$break-point-sm) {
        margin-bottom: 16px;
    }
}

.main_content {
    margin-bottom: 30px;

    p {
        margin-bottom: 16px;

        @media (max-width: vars.$break-point-sm) {
            margin-bottom: 12px;
        }
    }

    ul, ol {
        @media (max-width: vars.$break-point-sm) {
            margin-bottom: 12px;
        }
    }
}

.pagination_block {
    display: flex;
    column-gap: 16px;

    &__btn {
        display: flex;
        align-items: center;
        column-gap: 8px;

        @media (max-width: vars.$break-point-md) {
            width: calc((100% - 8px) / 2);
        }

        @media (max-width: vars.$break-point-sm) {
            width: calc(100% - 8px - 80px);
        }

        svg {
            margin-top: 1px;
            color: vars.$white_main;
            transition: color 300ms;
        }

        &:hover svg {
            color: vars.$white_main;
        }

        &--prev {
            @media (max-width: vars.$break-point-md) {
                width: calc((100% - 16px) / 2);
            }

            @media (max-width: vars.$break-point-sm) {
                width: 80px;
            }

            svg {
                color: vars.$black_main;
                transition: color 300ms;
            }

            &:hover svg {
                color: vars.$black_main;
            }
        }
    }

    @media (max-width: vars.$break-point-md) {
        width: 100%;

        &__btn,
        &__btn--prev {
            flex-grow: 1;
        }
    }

    @media (max-width: vars.$break-point-sm) {
        &__btn--prev {
            flex-grow: 0;
        }
    }
}
