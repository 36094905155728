.MuiPopover-root {
    // yes, but..

    .MuiPaper-root {
        top: 10px;
    }

    .MuiPaper-root:not([class*="with-shadow"]) {
        box-shadow: none !important;
    }

    .MuiPaper-root:not([class*="border-radius"]) {
        border-radius: 16px !important;
    }
}
