@use '@styles/_variables.scss' as vars;

.card {
    display: flex;
    padding: 24px;
    gap: 16px;
    border-radius: 16px;
    overflow: hidden;
    border: 1px solid vars.$grey;
    transition: border 0.2s ease-out;
    cursor: pointer;
    max-width: 100%;

    &:hover {
        border: 1px solid vars.$outline_hover;
    }

    @media (max-width: vars.$break-point-md) {
        border-radius: 0;
        padding: 12px;
        gap: 12px;
        border: none;
        border-top: 1px solid vars.$grey;

        &:hover {
            border: none;
            border-top: 1px solid vars.$grey;
        }

        &:first-child {
            border-top: none;

            &:hover {
                border: none;
            }
        }
    }

    .title {
        margin-bottom: 12px;
    }

    .img_container {
        flex-shrink: 0;
        position: relative;
        width: 60px;
        height: 60px;
        overflow: hidden;

        @media (max-width: vars.$break-point-md) {
            width: 24px;
            height: 24px;
        }

        img {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            object-fit: cover;
        }
    }

    .name {
        @include vars.font_text15_semi_bold;

        color: vars.$green_main;

        max-width: 100%;
        text-overflow: ellipsis;
        white-space: pre-line;
        display: -moz-box;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;

        @media (max-width: vars.$break-point-md) {
            -webkit-line-clamp: 1;
        }
    }
}
