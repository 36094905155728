@use "@styles/_variables.scss" as vars;

[class~="tab_root"] {
    flex-shrink: 0;
}

.link {
    display: flex;
    cursor: pointer;
    position: relative;
    top: 1px;
    outline: none;
    font-family: inherit;
    font-size: 1.5rem;
    padding-bottom: 16px;
    box-sizing: border-box;

    @media (max-width: vars.$break-point-lg) {
        padding-bottom: 14px;
    }
}

[class*="tab_root--selected"] {
    color: vars.$green_main;
    border-bottom: 1px solid vars.$green_main;
}

.btnLink {
    @include vars.font_text15;
    color: inherit;
    padding: 10px 16px 10px;

    @media (max-width: vars.$break-point-lg) {
        @include vars.font_text14;
        padding: 6px 16px 10px;
        line-height: 1.8rem;
    }
}

.btn {
    padding: 0;
}
