@use "@styles/_variables.scss" as vars;

.main {
    width: 100%;
    padding-top: 32px;
    padding-left: 0;
    padding-right: 0;

    @media (max-width: vars.$break-point-lg) {
        padding-top: 28px;
    }

    @media (max-width: vars.$break-point-sm) {
        padding-top: 24px;
    }
}

.content_wrapper {
    margin-top: 24px;

    @media (max-width: vars.$break-point-lg) {
        margin-top: 32px;
    }
}

.section_list {
    margin-top: 0px;
}

.title {
    word-break: break-word;
}

.dashboard {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
}

.sidebar-buttons-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
    column-gap: 16px;
    row-gap: 12px;
    margin-top: 20px;

    @media (max-width: vars.$break-point-sm) {
        flex-direction: column;
        column-gap: 12px;

    }

    @media (max-width: vars.$break-point-md) {
        margin-top: 0px;
        row-gap: 16px;
    }
}

.sidebar-wrapper {
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;

    @media (max-width: vars.$break-point-sm) {
        margin-top: 18px;
    }
}
