@use "@styles/_variables.scss" as vars;

.markdown {
    background-color: transparent;
    color: vars.$black_main;
    font-family: vars.$baseFont;
    font-size: 1.4rem;
    display: flex;
    flex-direction: column;

    & a {
        color: vars.$green_main;
    }

    & em {
        font-style: italic;
    }

    & p {
        margin-bottom: 10px;
    }

    & ul, ol {
        display: flex;
        flex-direction: column;
        padding-left: 0;
        margin-bottom: 10px;

        & li {
            display: flex;
            flex-direction: column;
            position: relative;
            list-style-type: circle;
            padding-left: 12px;

            &::before {
                content: "-";
                position: absolute;
                left: 0;
            }

            & p {
                margin-top: 0;
                margin-bottom: 10px;
            }
        }
    }

    & table tr {
        background-color: transparent;
    }
}
