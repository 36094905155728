@use "@styles/variables.scss" as vars;

.cell {
    display: flex;
    align-items: baseline;
}

.name {
    @include vars.font_text14();
    word-break: break-word;
    display: flex;
    column-gap: 8px;
    flex-wrap: wrap;
}

.link {
    cursor: pointer;

    &:hover {
        color: vars.$green_main_hover;
    }
}

.paper {
    padding: 12px 16px;
    max-width: 320px;
    border: none;
}

.with-shadow {
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1) !important;
}
