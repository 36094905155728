.container {
    display: flex;
    align-items: center;
    overflow: hidden;
    column-gap: 10px;
    text-overflow: ellipsis;
}


.picture {
    display: flex;
}

.content {
    display: flex;
    flex-direction: column;
}

.label,
.value {
    display: flex;

}