@use '@styles/_variables.scss' as vars;

.menu--delete {
    color: vars.$red_attention;
}

.btnOpen {
    opacity: 1;
    box-shadow: 0 0 1px vars.$black_main;
}
