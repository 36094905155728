@use "@styles/_variables.scss" as vars;

.container {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;

    padding: 16px 0px;
}

.dateContainer {
    display: flex;
    padding: 4px 10px;
    border: 1px solid vars.$grey_elements;
    border-radius: 50px;

    @include vars.font_text11();
    color: vars.$grey_text;
    user-select: none;
}
