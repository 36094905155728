@use '@styles/_variables.scss' as vars;

.form {
    width: fit-content;
    display: flex;
    column-gap: 16px;
}

.container {
    width: 100%;
}

.chartContainer {
    box-shadow: 0px 4px 24px 0px rgba(53, 53, 53, 0.06);
    border-radius: 16px;
    border: 1px solid var(--grey-lines, #ECEDED);
    background: var(--white-main, #FFF);
    width: 100%;
    padding: 24px 24px 28px 24px;
}

.chart {
    height: 500px;
}

.controls_container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    column-gap: 16px;

    width: 100%;
    margin-bottom: 40px;
}

.datePicker {
    min-width: 240px;
}

.filter {
    min-width: 260px;
}

.title {
    margin-bottom: 24px;
}

.sub_title {
    margin-bottom: 10px;
}

.archive_btn {
    position: relative;

    &--notify {
        &::after {
            content: "";
            position: absolute;
            right: -4px;
            top: -4px;
            width: 10px;
            height: 10px;
            background: vars.$red_attention;
            border: 2px solid vars.$white_main;
            border-radius: 50%;
        }
    }
}

.list-container {
    width: 100%;
    margin-top: 24px;


    .header {
        grid-gap: 3%;
        padding: 0 12px 12px;
        border-bottom: 1px solid vars.$grey_lines;
    }

    .row {
        grid-gap: 3%;
        padding: 12px 12px 0 12px;
        border-bottom: 1px solid vars.$grey_lines;
        height: 48px;
        cursor: pointer;

        &:hover {
            background-color: vars.$grey_hover;
        }
    }

    .relative {
        position: relative;
        display: flex;
        align-items: center;
    }

    .menu {
        position: absolute;
        right: 0;
    }
}

.pagination {
    width: 100%;
}

.column_label {
    color: vars.$grey_text;
    padding-top: 0px;
    min-width: 140px;
}

.alertContainer {
    color: #888;
    font-family: Mulish;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 120px
}