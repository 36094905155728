@use "@styles/_variables.scss" as vars;

.root {
    position: relative;
    padding: 8px 12px 8px calc(36px + 16px * var(--group-level));
    cursor: pointer;

    &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: calc(16px + 16px * var(--group-level));
        transform: translateY(-50%);
        width: 4px;
        height: 4px;
        background-color: vars.$grey_text;
        border-radius: 50%;
    }
}

.root-single {
    &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: calc(16px + 16px * var(--group-level));
        transform: translateY(-50%);
        width: 4px;
        height: 4px;
        background-color: vars.$grey_text;
        border-radius: 50%;
    }
}

.option {
    padding: 8px 12px 8px calc(36px + 16px * var(--group-level));

    &--hover {
        transition: background-color 0.3s ease;

        &:hover {
            background-color: vars.$grey_hover;
        }
    }
}

.label {
    position: relative;
    display: flex;
    align-items: center;
    column-gap: 10px;
    padding: 8px 12px 8px calc(36px + 16px * var(--group-level));
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: vars.$grey_hover;
    }

    &__icon {
        position: absolute;
        top: calc(50% - 3px);
        left: calc(16px + 16px * var(--group-level));
        transform: rotate(-90deg);
        transition: transform 0.3s ease;
        color: vars.$grey_text;

        &--active {
            transform: rotate(0deg);
        }
    }
}

.active {
    &:before {
        content: "";
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        width: 16px;
        height: 11px;
        background: url("../../../../../../../assets/icons/check-value-darck.svg");
    }
}

.checkbox {
    pointer-events: none;
}

.searchable {
    mark {
        color: vars.$green_main;
        background-color: transparent;
        font-weight: 700;
    }
}
