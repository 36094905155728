@use '@styles/_variables.scss' as vars;

.wrapper {
    position: relative;
    padding: 4px;
    padding-top: 5px;

    &--student {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        @media (max-width: vars.$break-point-lg) {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            &:hover {
                background-color: vars.$light_green;

                & .day--current-student {
                    color: vars.$black_main;
                }

                & .status--today {
                    background-color: vars.$green_main;
                }
            }
        }

        .status {
            display: none;

            @media (max-width: vars.$break-point-lg) {
                display: block;
                position: absolute;
                top: 12px;
                left: 12px;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background-color: vars.$green_main;
            }

            @media (max-width: vars.$break-point-md) {
                width: 5px;
                height: 5px;
                top: 6px;
                left: 6px;
            }

            &--today {
                background-color: vars.$white_main;
            }
        }
    }

    &--selected {
        @media (max-width: vars.$break-point-lg) {
            background-color: vars.$light_green;
        }
    }
}

.button_wrapper {
    display: flex;
    justify-content: center;
    padding-top: 4.5px;
}

.button {
    font-size: 1.2rem;
    font-weight: 700;
    color: vars.$black_main;
    text-transform: uppercase;
}

//.drop_menu {
//    width: 109%;
//    top: 1px;
//    left: 50%;
//    transform: translate(-50%, -50%);
//}
//
//.week_day_name {
//    font-size: 1.2rem;
//}

.show_day {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 33px;
    padding-bottom: 4px;

    &--student {
        @media (max-width: vars.$break-point-lg) {
            height: auto;
            padding-bottom: 0;
        }
    }
}

.day {
    padding-top: 3px;
    padding-right: 7px;

    &--student {
        @media (max-width: vars.$break-point-lg) {
            padding-top: 0;
            padding-right: 0;
        }
    }

    &--current {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 28px;
        height: 28px;
        padding: 0;
        background-color: vars.$green_main;
        border-radius: 50%;
        color: vars.$white_main;
    }

    @media (max-width: vars.$break-point-lg) {
        &--current-day {
            background-color: vars.$green_main;
            color: vars.$white_main;
        }
    }
}

.event_list_wrapper {

    overflow: hidden;

    &--methodist {
        min-height: 112px;
    }

    &--student {
       height: 112px;
    }
}

.visible_events {
    position: absolute;
    width: 109%;
    top: -3px;
    left: 50%;
    transform: translate(-50%);
    padding-top: 12px;
    padding-left: 12px;
    padding-right: 5px;
    background-color: vars.$white_main;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    z-index: vars.$hight_index;

    &__title_wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;
    }

    &__event_list_wrapper {
        height: 176px;
        padding-right: 4px;
        overflow-y: scroll;

        &::-webkit-scrollbar-track {

        }

        &::-webkit-scrollbar {
            width: 3px;
            height: 100%;
            background-color: white;
        }

        &::-webkit-scrollbar-thumb {
            width: 3px;
            height: 39px;
            border-radius: 5px;
            background-color: vars.$grey_elements;
        }

    }
}

