@use "@styles/_variables.scss" as vars;

.drop-box-wrapper {
    position: absolute;
    width: 100%;
    height: calc(100% - 64px);
    top: 64px;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 24px;
    background-color: #ecededcc;
    border-bottom-right-radius: 16px;

    @media (max-width: vars.$break-point-lg) {
        border-bottom-right-radius: 0;
    }

    .drop-box {
        border: 2px dashed vars.$grey_text;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 16px;
        width: 100%;
        height: 100%;

        & > span {
            @include vars.font_text19;
            color: vars.$black_grey;
        }
    }
}
