@use "@styles/_variables.scss" as vars;

.option_list_wrapper {
    overflow-x: auto;
    max-height: 300px;

    &::-webkit-scrollbar {
        scroll-padding-top: 10px;
        width: 4px;
        padding-top: 12px;
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        padding-top: 10px;
        width: 4px;
        border-radius: 5px;
        background-color: vars.$grey_elements;
    }

    ::-webkit-scrollbar-track-piece:end {
        background: transparent;
        margin-bottom: 10px;
    }

    ::-webkit-scrollbar-track-piece:start {
        background: transparent;
        margin-top: 10px;
    }
}

.empty {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: vars.$grey_text;
    cursor: default;
    height: 36px;
}

.root {
    position: relative;
    padding: 8px 12px 8px 16px;
    cursor: pointer;
}

.option {
    padding: 8px 12px 8px 16px;

    &--hover {
        transition: background-color 0.3s ease;

        &:hover {
            background-color: vars.$grey_hover;
        }
    }
}

.label {
    position: relative;
    display: flex;
    align-items: center;
    column-gap: 10px;
    padding: 8px 12px 8px calc(36px + 16px);
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: vars.$grey_hover;
    }

    &__icon {
        position: absolute;
        top: calc(50% - 3px);
        left: calc(16px + 16px);
        transform: rotate(-90deg);
        transition: transform 0.3s ease;
        color: vars.$grey_text;

        &--active {
            transform: rotate(0deg);
        }
    }
}

.active {
    &:before {
        content: "";
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        width: 16px;
        height: 11px;
        background: url("../../../../../../../assets/icons/check-value-darck.svg");
    }
}

.checkbox {
    pointer-events: none;
}

.searchable {
    mark {
        color: vars.$green_main;
        background-color: transparent;
        font-weight: 700;
    }
}
