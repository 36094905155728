@import "@styles/_variables.scss";

.card {
    display: flex;
    width: 293px;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    cursor: pointer;
    position: relative;

    border-radius: 16px;
    border: 1px solid $grey_lines;
    transition: border-color 0.4s;
    background: $white_main;

    &:hover {
        border: 1px solid $grey_text;
    }

    .icon {
        width: 60px;
        height: 60px;
        img {
            width: 60px;
            height: 60px;
        }
    }

    p {
        color: $green_main;
        font-size: 15px;
        font-weight: 700;
        line-height: 21px;
    }

    .delete {
        position: absolute;
        top: 16px;
        right: 16px;
        svg {
            path {
                stroke: $grey_text;
            }
        }
    }
}
