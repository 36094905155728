.wrapper {
    width: 100%;
    max-width: 100%;
    white-space: nowrap;
    position: relative;

    &_shadow {
        &::before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            width: 30px;
            background-image: linear-gradient(90deg, rgba(255, 255, 255, 0), #ffffff);
        }

        .root {
            padding-right: 12px;
        }
    }

    .root {
        display: flex;

        width: 100%;
        max-width: 100%;

        overflow-x: auto;

        &::-webkit-scrollbar {
            display: none;
        }
    }
}

.no-scroll.wrapper {
    &::before {
        display: none;
    }
}

.no-scroll {
    .root {
        padding-right: 0;
    }
}

.item {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    display: flex;

    color: #a0a0a0;

    a {
        color: #a0a0a0;
        transition: color 0.2s ease-in-out;
        &:hover {
            color: #222626;
        }
    }

    &::after {
        width: 12px;
        height: 12px;
        content: "";
        position: relative;
        top: 4px;

        display: inline-flex;

        margin-left: 4px;
        margin-right: 4px;
        background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4 2L8 6L4 10' stroke='%23A0A0A0' stroke-width='1.3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    }

    &:last-child {
        &::after {
            display: none;
        }
    }
}
