@use "@styles/_variables.scss" as vars;

.empty_message {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin: 0 auto;
    margin-top: 50px;

    &__icon {
        margin-bottom: 12px;
    }

    &__text {
        margin-bottom: 25px;
        @include vars.font_text18;
        font-weight: 700;
        color: vars.$black_main;
    }
}

.list_wrapper {
    width: 100%;
    row-gap: 8px;
}

.pagination {
    width: 100%;
}

.control_work {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__wrapper {
        display: flex;
        align-items: center;
        width: 100%;

        @media (max-width: vars.$break-point-lg) {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }
    }

    &__grade_wrapper {
        margin-right: 10px;

        @media (max-width: vars.$break-point-lg) {
            display: flex;
            align-items: center;
            column-gap: 10px;
            margin-bottom: 6px;
        }
    }

    &__grade {
        @include vars.font_text14;
        font-weight: 500;
        padding: 3px 16.5px;
        background-color: vars.$grey_hover;
        border-radius: 50px;

        transition: background-color 0.2s ease;
    }

    &__discipline_wrapper {
        display: flex;
        align-items: center;
        column-gap: 4px;
        margin-right: 8px;
    }

    &__discipline_name_wrapper {
        @media (max-width: vars.$break-point-lg) {
            display: flex;
            justify-content: flex-start;
            column-gap: 10px;
            width: 100%;
            margin-bottom: 6px;
        }

        @media (max-width: vars.$break-point-md) {
            justify-content: space-between;
        }
    }

    &__discipline_name {
        @include vars.font_text15;
        font-weight: 700;
        margin-right: 4px;

        transition: color 0.2s ease;

        &:hover {
            color: vars.$green_main;
        }
    }

    &__material_name,
    &__attempt,
    {
        @include vars.font_text13;
        font-weight: 500;
        color: vars.$black_grey;
    }

    &__separator {
        width: 5px;
        height: 5px;
        background-color: vars.$grey_text;
        border-radius: 50%;
    }

    &__feedback_btn {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 4px;
        transition: color 0.2s ease;

        &:hover {
            color: vars.$green_main;
        }

        @media (max-width: vars.$break-point-lg) {
            margin-right: 0px;
        }

        @media (max-width: vars.$break-point-md) {
            margin-right: -20px;
        }

        &__label {
            margin-right: 0;
        }
    }

    &__download_btn {
        margin-right: 12px;
        color: vars.$grey_text;
        transition: color 0.2s ease;

        &:hover {
            color: vars.$black_main;
        }
    }
}

.accordion {
    :global(.MuiAccordionSummary-root):hover {
        .control_work__grade {
            background-color: vars.$white_main;
        }

    }
}

