@use "@styles/_variables.scss" as vars;

.wrapper {
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-top: 48px;

    .title {
        @include vars.font_text22_extra_bold;
    }

    .skills_container {
        display: grid;
        grid-template-columns: 302px auto;
        gap: 0 24px;
        @media (max-width: vars.$break-point-md) {
            grid-template-columns: 1fr 1fr;
        }
        @media (max-width: vars.$break-point-sm) {
            grid-template-columns: auto;
        }
    }

    .board {
        align-self: start;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        gap: 16px;
        width: 302px;
        padding: 29px 32px;
        border-radius: 16px;
        background: vars.$grey_hover;
        @media (max-width: vars.$break-point-md) {
            grid-column: 1/3;
            width: 100%;
        }
        .name {
            @include vars.font_text18;
            max-width: 50%;
        }
    }
    .cards_wrapper {
        align-self: start;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 24px;
        @media (max-width: vars.$break-point-md) {
            grid-column: 1/3;
            margin-top: 20px;
            grid-template-columns: 1fr 1fr;
            gap: 16px;
        }
        @media (max-width: vars.$break-point-sm) {
            grid-template-columns: 1fr;
        }
    }
}

.progress {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px 0;
    max-width: 195px;
    width: 75%;
    white-space: nowrap;
    .name_progress {
        @include vars.font_text14;
        display: flex;
        gap: 7px;
        color: vars.$grey_text;
    }
}

.card_item {
    padding: 24px;
    border: 1px solid vars.$grey;
    cursor: pointer;
    border-radius: 16px;
    width: auto;
    min-width: 180px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: space-between;
    position: relative;

    &:hover {
        border-color: vars.$outline_hover;
    }

    .card_title {
        @include vars.font_text15_semi_bold;
        color: vars.$black;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -moz-box;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
    }
    .card_title_blocked {
        color: vars.$grey_text;
    }

    .card_sticker {
        width: 50px;
        height: 30px;
        border-radius: 60px 0 0 60px;
        background: vars.$green_success;
        position: absolute;
        bottom: 27px;
        right: 0;

        &_disabled {
            background: vars.$grey_text;
        }
    }
}
