@use "@styles/variables" as vars;
@use "@styles/mixins/typo" as mixins;

.btn {
    height: 32px;
    min-width: 32px;

    background: vars.$white_main;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    color: vars.$black_main;
    //margin: 0px 4px;

    @include mixins.font_text14;

    &:hover {
        background-color: vars.$grey_lines;
        color: vars.$black_main;
    }
}

.disabled {
    cursor: default;

    &:hover {
        background: vars.$white_main;
        color: vars.$black_main;
    }
}

.selected,
.selected:hover {
    background-color: vars.$green_main;
    color: vars.$white_main;
}
