@use "@styles/variables.scss" as vars;

.wrapper {
    display: flex;
    width: 100%;
    z-index: vars.$default_index;
    margin-bottom: -50px;

    @media (max-width: vars.$break-point-lg) {
        flex-direction: column;
    }
}

.lectures_menu_wrapper {
    position: sticky;
    bottom: 0;
}

.lectures_menu {
    width: calc(100% + 32px * 2);
    transform: translateX(-50%);
    position: relative;
    left: 50%;
    height: 62px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 20px;
    padding: 0 32px;
    background-color: vars.$white_main;
    box-shadow: 0 -2px 10px 1px rgb(30 30 30 / 8%);

    &__btn {
        padding: 10px;
        padding-right: 0;
    }

    @media (max-width: vars.$break-point-md) {
        padding: 0 40px;
        width: calc(100% + 40px * 2);
        height: 50px;
    }

    @media (max-width: vars.$break-point-sm) {
        padding: 0 16px;
        width: calc(100% + 16px * 2);
        height: 46px;
    }
}

.swipeable_drawer {
    [class~="css-1160xiw-MuiPaper-root-MuiDrawer-paper"] {
        overflow: hidden;
    }

    &__title_wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        column-gap: 20px;
        padding: 14px 24px;
        position: relative;
        z-index: vars.$hightest_index;
        height: 58px;
        min-height: 58px;

        & h3 {
            font-size: 2rem;
        }


        &::after {
            content: "";
            position: absolute;
            width: 100%;
            height: 8px;
            left: 0;
            bottom: -8px;
            background: linear-gradient(0deg, rgba(30, 30, 30, 0.08) 0%, rgba(224, 224, 224, 0) 100%);
            transform: matrix(1, 0, 0, -1, 0, 0);
        }
    }

    &__close_btn {
        font-size: 0;
        line-height: 0;
        color: vars.$grey_text;

        &:hover {
            & svg path {
                stroke: vars.$black_main;
            }

        }
    }
}
