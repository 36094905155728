@use "@styles/variables.scss" as vars;

.content_wrapper {
    width: 100%;
    padding-top: 24px;

    @media (max-width: vars.$break-point-lg) {
        padding-top: 28px;
    }

    @media (max-width: vars.$break-point-sm) {
        padding-top: 24px;
    }
}

.title_wrapper {
    display: flex;
    flex-direction: column;

    margin-bottom: 24px;

    @media (max-width: vars.$break-point-sm) {
        margin-bottom: 20px;
    }
}

.container {
    width: 800px;

    @media (max-width: vars.$break-point-lg) {
        width: 629px;
    }

    @media (max-width: vars.$break-point-md) {
        width: 100%;
    }

    padding: 0px;
}

.paper_fit {
    width: fit-content;

    @media (max-width: vars.$break-point-md) {
        margin: 0 auto;
    }
}

.inner {
    padding: 24px;
}
