.materials_content {
    margin-top: 24px;
}

.material {
    padding: 8px 0;
    min-height: 52px;

    a {
        font-weight: 500;
    }
}
