@use '@styles/variables' as vars;


.select_column {
    display: flex;
}

.container {
    margin-top: 24px;
    width: 100%;
}

.pagination {
    width: 100%;
}

.header {
    position: relative;
    border: none;
}

.header_row {
    border: none;
}

.header_cell {
    display: flex;
    color: vars.$grey_text;
    @include vars.font_text14;
}

.cell {
    display: flex;
}

.bucket {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.row {
    &:hover {
        background-color: vars.$grey_hover;
    }
}

.delete_bucket {
    display: flex;
    position: relative;
    margin-right: 16px;
    cursor: pointer;
}

.csv-preview {
    display: flex;
    align-items: center;
    height: 100%;
    white-space: pre;

    & p {
        display: inline;
    }
}