@use '@styles/_variables.scss' as vars;

.fieldset {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

.full_height {
    height: 100%;
}

.editor {
    height: 100%;

    & > div {
        height: 100%;
    }
}
