@use '@styles/_variables.scss' as vars;

.title {
    

    @media (max-width: vars.$break-point-md) {
        @include vars.font_text19;
    }
}

.header {
    display: flex;
    justify-content: space-between;

    margin-bottom: 16px;

    @media (max-width: vars.$break-point-md) {
        margin-bottom: 20px;
    }
}

.complete_paper {

    @media (max-width: vars.$break-point-lg) {
        padding-bottom: 0px;
    }
}

.edit_papper {

    @media (max-width: vars.$break-point-lg) {
        padding-bottom: 24px;
    }
}

.content {
    display: flex;
    flex-direction: column;
    row-gap: 24px;

    @media (max-width: vars.$break-point-md) {
        row-gap: 20px;
    }
}

.file_upload {
    display: flex;
    flex-direction: column;
    row-gap: 8px;

    @media (max-width: vars.$break-point-md) {
        row-gap: 4px;
    }
}

.button {
    width: 100%;
    max-width: 250px;
    min-width: 100%;
}
.button_text {
    display: flex;
}
.button_text_title {
    display: block;
    max-width: 100%;
    max-height: 16px;
    line-height: 1.6rem;
    overflow: hidden;
    word-break: break-all;
    color: vars.$black_main;
}

.date {
    font-weight: 500;
    font-size: 1.3rem;
    line-height: 1.8rem;
    color: vars.$grey_text;

    @media (max-width: vars.$break-point-md) {
        font-size: 1.2rem;
    }
}

.comment_title {
    margin-bottom: 8px;

    @media (max-width: vars.$break-point-md) {
        @include vars.font_text15;
        margin-bottom: 8px;
    }
}

.comment_content {
    display: block;
    word-break: break-all;
}

.assessment {
    padding: 22px 24px;
    background: vars.$grey_hover;
    border-radius: 16px;

    &__title {
        margin-bottom: 10px;
    }

    &__content {
        display: flex;
        flex-direction: column;
        row-gap: 22px;
    }

    &__scores {
        display: block;
        padding: 6px 14px;
        background: vars.$white_main;
        border-radius: 8px;
        font-weight: 700;
        font-size: 1.5rem;
        line-height: 2.1rem;

        &_wrapper {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            row-gap: 6px;
        }
    }

    &__feedback_title {
        margin-bottom: 6px;
    }
}

.controls {
    display: flex;
}

.menu {
    margin-right: 8px;
    svg circle{
        fill: vars.$grey_text;
    }
}