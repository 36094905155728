@use '@styles/_variables.scss' as vars;

.item {
    padding: 14px 0 16px;
    display: flex;
    column-gap: 14px;
    border-bottom: 1px solid vars.$grey_lines;

    &:last-child {
        border-bottom: none;
        padding-bottom: 0;
    }

    @media (max-width: vars.$break-point-md) {
        padding: 16px 0;
    }
}

.avatar {
    width: 42px;
    height: 42px;
    min-width: 42px;
}

.user_info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.role {
    @include vars.font_text9;
    font-weight: 600;
    text-transform: uppercase;
    color: vars.$black_grey;
    letter-spacing: 0.05em;

    &:not(:last-child) {
        margin-bottom: 4px;
    }
}

.name {
    &:not(:last-child) {
        margin-bottom: 8px;
    }
}

.chat_btn {
    font-weight: 700;
    font-size: 1.4rem;
    line-height: 1.9rem;
}
