@use '@styles/_variables.scss' as vars;

.container {
    position: relative;
}

.button_wrapper {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 0;
    top: 42px;
    background-color: vars.$white_main;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 8px 0;
    z-index: vars.$hight_index;
    width: 176px;
}

.menu {
    left: unset;
    right: 0;
}

.item {
    padding: 8px 16px;
    height: 36px;

    &:not(.disabled) {
        cursor: pointer;
    }

    &:not(.disabled):hover {
        background-color: vars.$grey_hover;
    }

    & > div {
        width: 100%;
        display: flex;
        justify-content: center;

        & > div {
            width: 20px;
            height: 20px;
        }
    }
}
