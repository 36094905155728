@use '@styles/variables.scss' as vars;

.container {
    height: 516px;

    .item {
        padding: 12px;
        display: flex;
        cursor: pointer;
        border-radius: 10px;
        border: 1px solid vars.$grey;
        margin-top: 8px;
    }

    .checkbox-wrapper {
        display: flex;
        align-items: center;
        margin-right: 12px;

        .checkbox {
            pointer-events: none;
        }
    }

    p {
        font-weight: 500;
    }
}
