@use '@styles/_variables.scss' as vars;

.root {
    border-radius: 12px;
}

.link_wrapper {
    width: 100%;
    position: relative;
    padding-bottom: 56%;
}

.link {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    font-size: 0;
    line-height: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: vars.$grey_hover;
    border-radius: 12px;
    overflow: hidden;

    &__icon {
        height: 55%;
        width: 100%;
    }

    &>span {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.preview {
    width: 100%;
}
