@use '@styles/_variables.scss' as vars;

.root {
    width: 201px;
    display: flex;
    flex-direction: column;
    row-gap: 8px;

    @media (max-width: vars.$break-point-lg) {
        flex-direction: row;
        align-items: center;
        column-gap: 6px;
    }
}

.label {
    display: flex;
    align-items: center;
    column-gap: 6px;

    &__text {
        font-size: 1.3rem;
        line-height: 1.8rem;
        color: vars.$green_success;
    }
}

.container_bar {
    height: 4px;
    width: 100%;
    background-color: vars.$grey_lines;
    border-radius: 10px;
}

.placeholder {
    height: 100%;
    background-color: vars.$green_success;
    border-radius: inherit;
    transition: width 1s ease-in-out
}
