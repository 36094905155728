@use "@styles/variables.scss" as vars;

.container {
    display: flex;
    width: 100%;
}

.tabList {
    width: 100%;
    display: flex;
    margin-bottom: 24px;

    & li {
        @media (max-width: vars.$break-point-lg) {
            margin-right: 24px;
        }

        @media (max-width: vars.$break-point-md) {
            margin-right: 20px;
        }
    }
}

.tabs-wrapper {
    overflow-x: auto;
    overflow-y: hidden;
    height: 38px;
    margin-bottom: -1px;
    z-index: vars.$low_index;

    &::-webkit-scrollbar {
        display: none;
    }

    padding-left: var(--page-padding);
    padding-right: var(--page-padding);
    @include vars.horizontal_scroll;

    @media (max-width: vars.$break-point-lg) {
        height: auto;
        margin-bottom: 0;
        margin-left: calc(var(--page-padding) * -1);
        margin-right: calc(var(--page-padding) * -1);
        width: calc(100% + var(--page-padding) * 2);
        max-width: calc(100% + var(--page-padding) * 2);
        --page-padding: 0;
    }

    @media (max-width: vars.$break-point-sm) {
        --page-padding: 16px;
    }
}

.studentTabList {
    margin: 0 16px;
    width: calc(100% - 16px * 2);
    position: relative;
    padding-bottom: 0;
    border-bottom: 1px solid vars.$grey_lines;
    padding-left: var(--page-padding);
    padding-right: var(--page-padding);

    & li {
        padding-bottom: 14px;
    }

    @media (max-width: vars.$break-point-lg) {
        margin: 0;
        max-width: unset;
        margin-left: calc(var(--page-padding) * -1);
        margin-right: calc(var(--page-padding) * -1);
        min-width: 100%;
        width: fit-content;
        --page-padding: 0;

        & li {
            font-size: 1.4rem;
            padding-bottom: 12px;
        }

    }

    @media (max-width: vars.$break-point-sm) {
        --page-padding: 16px;
        min-width: calc(100% + var(--page-padding) * 2);
    }
}

.tabs {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.notFound {
    display: flex;
    justify-content: center;
    align-items: center;
}

.paper {
    margin-bottom: 24px;
    padding: 24px 24px 32px;
}
