@use '@styles/_variables.scss' as vars;

.dropdown {
    position: absolute;
    top: 40px;
    right: 0;
    width: 247px;
    padding: 18px 24px;
    background-color: vars.$white_main;
    border-radius: 10px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}
