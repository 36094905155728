@use "@styles/_variables.scss" as vars;

.title {
    margin-bottom: 20px;

    @media (max-width: vars.$break-point-lg) {
        @include vars.font_h2;
    }
}

.input_layout {
    display: flex;
    flex-wrap: wrap;
    row-gap: 20px;
    margin-bottom: 32px;

    @media (max-width: vars.$break-point-lg) {
        margin-bottom: 24px;
    }

    & input {
        height: 54px;
        font-size: 16px;
    }
}

.button_submit {
    margin-bottom: 20px;
    height: 54px;
}

.recover_password {
    display: flex;
    column-gap: 5px;

    &__text {
        color: vars.$grey_text;
    }

    &__link {
        color: vars.$green_main;

        &:hover {
            color: vars.$green_dark;
        }
    }
}

.back_link {
    display: flex;
    column-gap: 12px;
    align-items: center;
    margin-bottom: 16px;

    @media (max-width: vars.$break-point-lg) {
        margin-bottom: 15.6px;
    }
}

.notification_error {
    width: 100%;
    padding: 24px;
    padding-top: 20px;
    background: vars.$white_main;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.06);
    border: 1px solid #f0f0f0;
    border-radius: 16px;

    &__title_wrapper {
        display: flex;
        column-gap: 12px;
        margin-bottom: 16px;
        align-items: center;
    }

    &__title {
        background-position: 0px 8px;
    }

    &__subtitle {
        font-weight: 600;
        margin-bottom: 3px;
    }

    &__link {
        color: vars.$green_main;

        &:hover {
            color: vars.$green_dark;
        }
    }

    &__content_wrapper {
        display: flex;
        flex-direction: column;
        row-gap: 12px;
    }

    p {
        margin-bottom: 0px;
    }
}

.list {
    display: flex;
    flex-direction: column;
    row-gap: 8px;

    &__item {
        position: relative;
        padding-left: 16px;

        &::before {
            content: "";
            position: absolute;
            top: 8px;
            left: 1px;
            width: 5px;
            height: 5px;
            background-color: vars.$black_main;
            border-radius: 50%;
        }
    }
}
