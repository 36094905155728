@use '@styles/variables.scss' as vars;

.container {
    display: flex;
    flex-direction: column;
}

/// Header 
.header {
    display: flex;
}

/// Body