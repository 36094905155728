@use "@styles/_variables.scss" as vars;

.title_wrapper {
    display: flex;
    align-items: center;
    column-gap: 12px;
    margin-bottom: 26px;
}

.paper {
    min-width: 700px;
}

.title {
    margin-bottom: 16px;
}

.form_content {
    &__button_wrapper {
        display: flex;
        align-items: center;
        column-gap: 16px;
    }
}

.fieldset {
    display: flex;
    flex-direction: column;
    row-gap: 16px;

    &--border_line {
        border-bottom: 1px solid vars.$grey_lines;
        padding-bottom: 24px;
    }
}

.input_wrapper {
    display: grid;
    grid-template-columns: 25% auto;
    column-gap: 16px;

    &--center_content {
        & > label,
        p {
            height: 42px;
            display: flex;
            align-items: center;
        }
    }
}

.input_score_wrapper {
    display: flex;
    align-items: center;
    column-gap: 12px;
}

.permission_block_wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 26px;
}

.select_list_wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 16px;
    margin-bottom: 8px;

    .select {
        width: 400px;
    }
}

.select_list {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    &__item {
        display: flex;
        align-items: center;
        column-gap: 12px;
    }
}
