@use "@styles/_variables.scss" as vars;

.title_wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 12px;
    margin-bottom: 26px;
}

.title_wrapper {
    display: flex;
    align-items: center;
    column-gap: 12px;
}

.paper {
    min-width: 700px;
}

.form_content {
    &__button_wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 16px;
    }

    &__btn_wrapper {
        display: flex;
        align-items: center;
        column-gap: 16px;
    }
}

.delete_btn {
    width: 40px;
    height: 40px;
    background: vars.$grey_hover;
    border-radius: 6px;
}

.fieldset {
    display: flex;
    flex-direction: column;
    row-gap: 16px;

    &--border_line {
        border-bottom: 1px solid vars.$grey_lines;
        padding-bottom: 24px;
    }
}

.date_wrapper {
    width: 200px;
    display: flex;
}

.input_wrapper {
    display: grid;
    grid-template-columns: 25% 75%;
    column-gap: 16px;
    padding-right: 16px;


    &--center_content {

        &>label,
        p {
            height: 42px;
            display: flex;
            align-items: center;
        }
    }

    &_section_end {
        margin-bottom: 32px;
    }

    [class~="control"] {
        display: none;
    }
}

.preview_video_wrapper {
    margin-bottom: 20px;
}

.preview_video {
    margin-bottom: 12px;
}

.preview_button_wrapper {
    display: flex;
    align-items: center;
    column-gap: 12px;
}

.preview_button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    background: vars.$grey_hover;
    border-radius: 6px;
}

.video_wrapper {
    align-items: flex-start;
}

.duration_wrapper {
    display: flex;
    align-items: center;

    p:not(:last-child) {
        margin-right: 16px;
    }
}

.form_select {
    padding: 2px 0px;
}

.short_input {
    width: 50px;
    margin-right: 12px;
}

.permission_block_wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 26px;
}

.select_list_wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 16px;
    margin-bottom: 8px;

    .select {
        width: 400px;
    }
}

.select_list {
    display: flex;
    flex-direction: column;
    row-gap: 16px;

    &__item {
        display: flex;
        align-items: center;
        column-gap: 12px;
    }
}

.file_wrapper {
    flex: 1 1 566px;
    min-width: 0px;
}

.tree-select {
    cursor: pointer;

    &__input {
        border-color: vars.$grey_elements;
        transition: border-color 0.3s;

        &:hover {
            border-color: vars.$outline_hover;
        }
    }

    &__icon {
        color: vars.$grey_text;
    }
}