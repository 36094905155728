@use "@styles/_variables.scss" as vars;

.disciplines_count {
    margin-bottom: 24px;
}

.data_list {
    &__header {
        padding: 0px 12px;
        border-bottom: 1px solid vars.$grey_lines;
    }
}

.list_item {
    align-items: center;
    column-gap: 16px;
    padding: 12px 12px;
    border-bottom: 1px solid vars.$grey_lines;

    &:last-child {
        border-bottom: none;
    }
}

.column_content {
    display: flex;
    justify-content: flex-end;
}

.empty_message {
    margin: 120px auto 0px auto;
    text-align: center;

    &_text {
        @include vars.font_text14;
        color: vars.$grey_88;
    }
}

.list_wrapper {
    width: 100%;
}

.flex_end {
    display: flex;
    justify-content: flex-end;
}

.pagination {
    width: 100%;
}

.column_label {
    color: vars.$grey_text;
    padding-top: 0px;
    min-width: 140px;
}

.rowLink {
    display: flex;
}