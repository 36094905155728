@use '@styles/_variables.scss' as vars;

.container {
    min-height: 764px;
    overflow: hidden;
    border-radius: 10px;
    background-color: vars.$grey_hover;
    padding: 12px;
}

.document {
    position: relative;
    min-height: inherit;
    overflow-y: auto;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);

    /* ===== Scrollbar CSS ===== */
    /* Firefox */
    * {
        scrollbar-width: auto;
        scrollbar-color: vars.$grey_elements;
    }

    /* Chrome, Edge, and Safari */
    *::-webkit-scrollbar {
        width: 15px;
    }

    *::-webkit-scrollbar-track {
        background: #ffffff;
    }

    *::-webkit-scrollbar-thumb {
        background-color: #7d7d7d;
        border-radius: 10px;
        border: 4px solid #ffffff;
    }

    :global {
        #pdf-controls {
            position: absolute;
            top: unset;
            bottom: 20px;
            left: 50%;
            transform: translateX(-50%);

            #pdf-download {
                display: none;
            }
        }
    }
}

.cross {
    width: 40px;
    height: 40px;
    background-color: vars.$grey_hover;
    padding: 8px;
    top: 0;
    right: 0;

    position: absolute;
    z-index: 2;

    &:hover {
        cursor: pointer;
    }
}
div.DocViewer {
    position: relative;
    min-height: inherit;
}
