@use '@styles/_variables.scss' as vars;

.fieldset_title {
    margin-bottom: 16px;
}

//.input_wrapper {
//    display: grid;
//    grid-template-columns: 25% auto;
//    column-gap: 16px;
//}

.content_list {
    margin-bottom: 20px;

    &__item {
        padding-left: 36px;
    }
}
