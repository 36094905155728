@use '@styles/_variables.scss' as vars;

.label {
    padding: 2px 6px;
    box-shadow: 0 0 0 1px vars.$grey_elements;
    background-color: vars.$white_main;
    color: vars.$black_main;
    border-radius: 25px;
    max-height: 20px;
    text-transform: uppercase;
    display: inline-flex;
    cursor: pointer;
    user-select: none;
    @include vars.font_text9;
    flex-grow: 0;
    flex-shrink: 0;

    margin-left: 8px;
}

.color_green {
    box-shadow: 0 0 0 1px rgba(vars.$green_main, 0.5);
    color: vars.$green_main;
}

.color_red {
    box-shadow: 0 0 0 1px rgba(vars.$red_attention, 0.5);
    color: vars.$red_attention;
}

// .color_gray {
//     box-shadow: 0 0 0 1px rgba(vars.$grey_elements, 0.5);
//     color: vars.$grey_elements;
// }