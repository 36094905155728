@use '@styles/variables.scss' as vars;


.container {
    display: flex;
    column-gap: 16px;
    justify-content: flex-start;

    margin-bottom: 24px;
}

.column {
    display: flex;
    flex-direction: column;
    width: 50%
}

.label {
    @include vars.font_text14();
    margin-bottom: 4px;
    color: vars.$black_main;
}

.checkbox {
    display: flex;
    margin-top: 12px;
}