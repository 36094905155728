@use "@styles/_variables.scss" as vars;

.wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.title_navigate {
    display: flex;
    margin-bottom: 8px;
    cursor: pointer;

    @media (max-width: vars.$break-point-lg) {
        margin-bottom: 12px;
    }

    &:hover {
        & > * {
            color: vars.$grey_text;
            transition: color 300ms;
        }

        svg {
            color: vars.$grey_text;
            transition: color 300ms;
        }
    }

    .icon_navigate {
        margin-right: 12px;
    }
}

.header {
    margin-bottom: 24px;

    @media (max-width: vars.$break-point-md) {
        margin-bottom: 20px;
    }

    @media (max-width: vars.$break-point-sm) {
        margin-bottom: 24px;
    }
}

.test_info {
    background-color: vars.$grey_hover;
}

.paper {
    max-width: 800px;
    width: 100%;
    padding: 24px 24px 28px 24px;
    border-radius: 16px;
    overflow: hidden;

    overflow-wrap: break-word;

    @media (max-width: vars.$break-point-lg) {
        max-width: 629px;
    }

    @media (max-width: vars.$break-point-md) {
        padding: 20px 20px 24px 20px;
        max-width: 688px;
    }

    @media (max-width: vars.$break-point-sm) {
        padding: 16px 16px 20px 16px;
        max-width: 100%;
    }

    &:not(:last-child) {
        margin-bottom: 24px;

        @media (max-width: vars.$break-point-md) {
            margin-bottom: 20px;
        }
    }

    h1 {
        margin-bottom: 16px;
    }
}

.main_info {
    margin-bottom: 20px;

    @media (max-width: vars.$break-point-lg) {
        margin-bottom: 12px;
    }

    p {
        @include vars.font_text18;

        @media (max-width: vars.$break-point-lg) {
            @include vars.font_text15;
            font-weight: 700;
        }

        &:not(:last-child) {
            margin-bottom: 6px;

            @media (max-width: vars.$break-point-lg) {
                margin-bottom: 4px;
            }
        }
    }
}

.description {
    p {
        &:not(:last-child) {
            margin-bottom: 16px;
        }
    }
}

.button_wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    column-gap: 20px;
    width: 100%;
    margin-top: 32px;

    @media (max-width: vars.$break-point-lg) {
        column-gap: 16px;
        margin-top: 28px;
    }

    @media (max-width: vars.$break-point-sm) {
        flex-direction: column;
        margin-top: 24px;

        column-gap: 0px;
        row-gap: 16px;
    }

    &_end {
        justify-content: flex-end;
    }

    & > p {
        margin-bottom: 0;

        @media (max-width: vars.$break-point-lg) {
            @include vars.font_text16;
        }

        @media (max-width: vars.$break-point-sm) {
            font-weight: 700;
        }
    }
}

.button_start {
    @media (max-width: vars.$break-point-sm) {
        width: 100%;
    }
}

.results {
    border: 1px solid vars.$grey_lines;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.06);
    padding: 24px 24px 32px 24px;

    @media (max-width: vars.$break-point-lg) {
        padding: 0;
    }

    &_title {
        margin-bottom: 16px;

        @media (max-width: vars.$break-point-lg) {
            @include vars.font_text19;
            margin-bottom: 20px;
            padding: 24px 24px 0px 24px;
        }

        @media (max-width: vars.$break-point-md) {
            padding: 20px 20px 0px 20px;
        }

        @media (max-width: vars.$break-point-sm) {
            padding: 16px 16px 0px 16px;
        }
    }
}

.attempt {
    display: flex;
    align-items: center;

    @media (min-width: vars.$break-point-lg) {
        padding: 18px 16px;
        border-radius: 8px;
        border: 1px solid vars.$grey_lines;
    }

    @media (max-width: vars.$break-point-sm) {
        flex-wrap: wrap;
        row-gap: 12px;
    }

    &:not(:first-child) {
        @media (max-width: vars.$break-point-lg) {
            padding-top: 16px;
        }
    }

    &:not(:last-child) {
        margin-bottom: 16px;

        @media (max-width: vars.$break-point-lg) {
            border-bottom: 1px solid vars.$grey_lines;
            padding-bottom: 16px;
            margin: 0px 24px;
        }

        @media (max-width: vars.$break-point-md) {
            margin: 0px 20px;
        }

        @media (max-width: vars.$break-point-sm) {
            margin: 0px 16px;
        }
    }

    &:last-child {
        @media (max-width: vars.$break-point-lg) {
            padding: 16px 24px 28px 24px;
        }

        @media (max-width: vars.$break-point-md) {
            padding: 16px 20px 24px 20px;
        }

        @media (max-width: vars.$break-point-sm) {
            padding: 16px 16px 24px 16px;
        }
    }

    &_header {
        @include vars.font_text15;
        margin-right: 12px;

        @media (max-width: vars.$break-point-lg) {
            @include vars.font_text14;
        }

        @media (max-width: vars.$break-point-sm) {
            flex-grow: 1;
        }
    }

    &_date {
        color: vars.$black_grey;
        margin-right: 20px;
        flex-grow: 1;

        @media (max-width: vars.$break-point-sm) {
            @include vars.font_text11;
            order: 10;
            width: 100%;
            text-align: end;
            margin-right: 0px;
        }
    }

    &_mark {
        @include vars.font_text14_bold;
        margin-left: 8px;

        @media (max-width: vars.$break-point-sm) {
            margin-left: 10px;
        }
    }

    &_link {
        display: flex;
        align-items: center;

        @include vars.font_text14_bold;
        cursor: pointer;

        @media (max-width: vars.$break-point-sm) {
            order: 20;
            width: 100%;
            display: flex;
            justify-content: flex-end;
        }

        .attempt_icon {
            margin-left: 8px;
        }
    }

    &_best {
        padding: 2px 8px 3px;
        margin-right: 10px;

        border-radius: 25px;
        border: 1px solid vars.$green_success_05;

        text-transform: uppercase;
        color: vars.$green_success;
        @include vars.font_text9;

        @media (max-width: vars.$break-point-sm) {
            margin-right: 0;
        }
    }

    &_active {
        background-color: vars.$light_green;
        border: none;

        .attempt_date {
            @media (max-width: vars.$break-point-sm) {
                width: fit-content;
            }
        }
    }
}

.drawer {
    &_title {
        margin-bottom: 12px;
    }

    .button_wrapper {
        margin-top: 24px;
    }
}
