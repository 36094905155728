@use '@styles/_variables.scss' as vars;

.list {
    display: flex;
    flex-direction: column;
    row-gap: 4px;

    &__item {
        position: relative;
        padding: 2px 3px;

        color: vars.$black_main;
        background-color: vars.$light_green;
        transition: background-color 0.2s ease;

        border-radius: 4px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        cursor: pointer;

        &:hover {
            background-color: vars.$grey_lines;
        }
    }

    &__text {
        font-size: 1.3rem;

        &--time {
            font-weight: 700;
        }
    }
}
