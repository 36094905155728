.container {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

.field {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
}

.actions {
    display: flex;
    column-gap: 16px;

    margin-top: 24px;
    justify-content: flex-end;
}