@use '@styles/variables.scss' as vars;


.container {
    position: fixed;
    display: flex;

    bottom: 16px;
    border-radius: 8px;

    background-color: vars.$black_main;

    height: 46px;
    width: 1034px;

    z-index: vars.$hight_index;

}

.selectContainer {
    display: flex;
    flex-direction: column;

    padding: 13px 12px;
    margin-right: 24px;

    min-width: 150px;
}

.checkbox {
    display: flex;
}

.children {
    display: flex;
    width: 100%;

    padding-right: 8px;
}

.action {
    display: flex;
}

.wrapperContainer {
    display: flex;
    width: 100%;
}

.hidden {
    visibility: none;
    opacity: 0;
}