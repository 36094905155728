@use "@styles/variables" as vars;

.container {
    display: flex;
    column-gap: 8px;
}

.icon {
    display: flex;
    align-items: center;

    svg {
        width: 18px;
        height: 18px;
    }
}

.paper {
    padding: 12px 16px;
    max-width: 320px;
    border: none;
}

.caption {
    @include vars.font_text14();
    font-weight: 700;
    margin-bottom: 4px;
}

.subCaption {
    display: flex;
    margin-bottom: 12px;
    @include vars.font_text13();
    line-height: 1.9rem;
}

.grades {
    display: flex;
    flex-direction: column;
    margin-bottom: 4px;
    column-gap: 12px;
}

.grade {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    column-gap: 8px;
}

.circle,
.circleLabel {
    display: flex;
}

.circleLabel {
    @include vars.font_text13();
}

.circle {
    display: flex;

    &::before {
        content: "";
        position: relative;
        display: flex;
        width: 9px;
        height: 9px;
        border-radius: 50%;
    }
}

.bad {
    &::before {
        background-color: vars.$red_attention_hover;
    }
}

.medium {
    &::before {
        background-color: vars.$yellow_grade;
    }
}

.good {
    &::before {
        background-color: vars.$green_main;
    }
}

.excelent {
    &::before {
        background-color: vars.$green_success;
    }
}

.with-shadow {
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
}
