@use '@styles/_variables.scss' as vars;

.form_wrapper {
    border-top: 1px solid vars.$grey_lines;
    padding-top: 24px;

    @media (max-width: vars.$break-point-md) {
        padding-top: 20px;
    }
}

.form_content {
    &__button_wrapper {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        column-gap: 16px;

        @media (max-width: vars.$break-point-md) {
            justify-content: flex-start;
        }

        @media (max-width: vars.$break-point-sm) {
            width: 100%;
            flex-direction: column;
            justify-content: space-between;
            row-gap: 16px;

            button {
                width: 100%;
            }
        }
    }
}

.answer_title {
    @media (max-width: vars.$break-point-md) {
        @include vars.font_text18;
        line-height: 2.4rem;
    }
}

.fieldset {
    display: flex;
    flex-direction: column;
    row-gap: 16px;

    @media (max-width: vars.$break-point-md) {
        @include vars.font_text18;
        line-height: 2.4rem;
    }
}

.input_wrapper {
    display: grid;
    grid-template-columns: 25% auto;
    gap: 16px;

    &--center_content {
        align-items: center;
    }

    label {
        @media (max-width: vars.$break-point-md) {
            @include vars.font_text14;
        }
    }

    @media (max-width: vars.$break-point-md) {
        grid-template-columns: none;
        width: 100%;
        gap: 4px;
    }

    @media (max-width: vars.$break-point-sm) {
        grid-template-columns: none;
        row-gap: 4px;
        width: 100%;
    }
}

.permission_block_wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 26px;
}

.input_score_wrapper {
    display: flex;
    align-items: center;
    column-gap: 12px;

    max-width: 100px;

    @media (max-width: vars.$break-point-sm) {
        width: 100%;
        max-width: 100%;
    }
}

.textarea {
    width: 100%;
    padding: 8px 10px 9px;
    border: 1px solid vars.$grey_elements;
    border-radius: 8px;
    outline-color: vars.$grey_text;
    outline-width: 1px;
    font-family: vars.$baseFont;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 2rem;
    color: vars.$black_main;
    resize: none;
}

.checker {
    @media (max-width: vars.$break-point-md) {
        display: flex;
        justify-content: flex-start;
        column-gap: 16px;
    }

    @media (max-width: vars.$break-point-sm) {
        display: flex;
        justify-content: space-between;
    }
}

.action_btns {
    padding: 24px 0px;
}
