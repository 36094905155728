@use '@styles/variables' as vars;

.root {
    flex-shrink: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    // svg {
    //     width: 100%;
    //     height: 100%;
    // }

    &--mr {
        margin-right: 0;
    }

    &__icon {
        &--black {
            color: vars.$black_main;

            &:hover {
                color: vars.$grey_text;
            }
        }
    }


}

.play_video {
    color: white;
}

.left_arrow {
    transform: rotate(90deg);
}

.right_arrow {
    transform: rotate(-90deg);
}

.arrow_link {
    &:hover {
        path {
            stroke: vars.$green_main;
        }
    }
}