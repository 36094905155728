

.massContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.massControls {
    display: flex;
    column-gap: 8px;
}

.massControl {
    display: flex;

    &:nth-child(2) {
        width: 158px
    }

}

.actionBtn {
    display: flex;
    align-items: center;
}