@use "@styles/_variables.scss" as vars;

.container {
    display: flex;
    justify-content: space-between;
    flex-grow: 0;
    flex-shrink: 0;

    border-top: 1px solid vars.$grey_lines;

    &:hover .controls {
        opacity: 1;
    }
}

.icon {
    margin-right: 10px;
    padding: 12px 0px;
    display: flex;
}

.title {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    max-width: calc(100% - 64px);
}

.inner {
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer;
}

.controls {
    display: flex;
    opacity: 0;
    transition: opacity 300ms ease;
}

.iconControl {
    display: block;
}
