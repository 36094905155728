@use "@styles/_variables.scss" as vars;

.title {
    margin-bottom: 20px;
}

.tabs {
    column-gap: 16px;
    overflow: scroll;

    padding-bottom: 2px;
    padding-left: var(--page-padding);
    padding-right: var(--page-padding);

    @include vars.horizontal_scroll;

    @media (max-width: vars.$break-point-lg) {
        margin-left: calc(var(--page-padding) * -1);
        margin-right: calc(var(--page-padding) * -1);
        width: calc(100% + var(--page-padding) * 2);
        max-width: calc(100% + var(--page-padding) * 2);
        --page-padding: 0;

        column-gap: 8px;
    }

    @media (max-width: vars.$break-point-md) {
        --page-padding: 40px;
    }

    @media (max-width: vars.$break-point-sm) {
        --page-padding: 16px;
    }

    & li > div {
        white-space: nowrap;
    }
}
