@import "@styles/_variables.scss";

.accordeonItem {
    border-radius: 10px;
    padding: 12px 10px;
    background: $grey-hover;
    border: 1px solid $grey-hover;
    transition: background-color 0.4s, border-color 0.4s;
    &.open {
        background: $white_main;
        border: 1px solid $grey-lines;
        .arrow {
            transform: rotate(180deg);
        }
        .accordeonContent {
            grid-template-rows: 1fr;
        }
    }
    .accordeonHeader {
        display: flex;
        .bold {
            font-size: 16px;
            font-weight: 700;
            line-height: 21px;
        }
        .arrow {
            transition: transform 0.4s;
        }
    }
    .accordeonContent {
        display: grid;
        grid-template-rows: 0fr;
        transition: grid-template-rows 0.4s;

        .content {
            overflow: hidden;
        }
    }
}
