@use '@styles/variables.scss' as vars;


.controls {
    display: flex;
    justify-content: space-between;

    margin-bottom: 17px;
}

.controlMonth {
    @include vars.font_text18();

    cursor: pointer;

    display: flex;
    column-gap: 8px;
}

.controlNav {
    display: flex;
    cursor: pointer;
}

.nextNav,
.prevNav {
    opacity: 1;
    transition: opacity 0.1s ease-in;

    &:hover {
        opacity: 0.7;
    }
}

.arrow {
    transform: rotate(90deg);
    transform-origin: center;
}

.arrowUp {
    transform: rotate(270deg);
}

.caption {
    display: flex;
}

.disabled {
    opacity: 0.7;
    cursor: default;
}
