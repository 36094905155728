@use "@styles/_variables.scss" as vars;

.wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    border-radius: 8px;
    background-color: vars.$white_main;
}

.root_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid vars.$grey_elements;
    border-radius: 8px;
    height: 100%;
    transition: border-color 0.3s;

    &:hover {
        border-color: vars.$grey_text;
    }

    &--focus {
        color: vars.$black_main;
        border-color: vars.$grey_text;

        &:hover {
            border-color: vars.$grey_text;
        }
    }

    &--error {
        border-color: vars.$red_attention;

        &:hover {
            border-color: vars.$red_attention;
        }
    }

    &--disabled {
        background-color: vars.$grey_hover;
        border-color: vars.$grey_elements;

        &:hover {
            border-color: vars.$grey_elements;
        }
    }
}

.root {
    width: 100%;
    height: 100%;
    padding-top: 11px;
    padding-left: 10px;
    padding-bottom: 11px;
    padding-right: 10px;
    font-family: inherit;
    background-color: inherit;
    border-radius: 8px;
    border: none;
    outline: none;
    box-sizing: border-box;
    @include vars.font_text14;

    &::placeholder {
        color: vars.$grey_text;
    }

    &:-webkit-autofill {
        background-color: transparent !important;
        box-shadow: 0 0 0 500px vars.$white_main inset;
    }

    &--small {
        padding-top: 6.5px;
        padding-bottom: 7.5px;
    }

    &--input_number {
        -moz-appearance: textfield;
        appearance: textfield;
        margin: 0;

        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }
}

.show_password_button {
    margin-right: 10px;

    &>svg {
        width: 18px;
        color: vars.$grey_text;
        transition: color 0.3s;
    }

    &:hover svg {
        color: vars.$black_main;
    }
}

.cancel_button {
    margin-right: 10px;

    &>svg {
        width: 17px;
        color: vars.$grey_text;
        transition: color 0.3s;
    }

    &:hover svg {
        color: vars.$black_main;
    }
}

.helper_text {
    &--error {
        color: vars.$red_attention;
        white-space: pre-wrap;
        word-wrap: break-word;
    }
}

.search_icon {
    margin-left: 10px;
    position: relative;
    top: 1px;
}

.input_icon {
    margin-right: 10px;
    position: relative;
    top: 1px;
}