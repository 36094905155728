@use "@styles/_variables.scss" as vars;

.root {
    height: 42px;
    position: relative;
    padding: 11px 30px 11px 10px;
    background: transparent;
    border: 1px solid vars.$outline_borders;
    border-radius: 8px;
    cursor: pointer;
    transition: border-color 0.3s;

    &--disabled {
        cursor: default;
        background-color: vars.$grey_hover;
    }

    &:not(.root--disabled):hover {
        border-color: vars.$outline_hover;
    }

    .icon {
        position: absolute;
        top: 14px;
        right: 10px;
        width: 12px;
        height: 12px;
        transition: 0.3s ease;
        color: vars.$grey_text;

        &--active {
            transform: rotate(180deg);
        }
    }

    &--active {
        border-color: vars.$outline_hover;
    }

    &--error {
        border-color: vars.$red_attention;

        &:hover {
            border-color: vars.$red_attention;
        }
    }
}

.option_name {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
