@use "@styles/_variables.scss" as vars;

.title_wrapper {
    display: flex;
    align-items: center;
    column-gap: 12px;
    margin-bottom: 26px;
}

.form_content {
    &__button_wrapper {
        display: flex;
        align-items: center;
        column-gap: 16px;
    }
}

.paper {
    min-width: 700px;
}

.fieldset {
    display: flex;
    flex-direction: column;
    row-gap: 16px;

    &--border_line {
        border-bottom: 1px solid vars.$grey_lines;
        padding-bottom: 24px;
    }
}

.input_wrapper {
    display: grid;
    grid-template-columns: 25% auto;
    column-gap: 16px;

    &--center_content {
        & > label,
        p {
            height: 42px;
            display: flex;
            align-items: center;
        }
    }
}

.input_score_wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    column-gap: 12px;
}

.input_score_group {
    display: flex;
    align-items: center;
    position: relative;
    column-gap: 12px;
}

.input_error_container {
    display: flex;
    margin-top: 4px;
    color: vars.$red_attention;
    @include vars.font_text14();
}

.permission_block_wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 26px;
}
.anti_plagiarism_label {
    display: flex;
    align-items: center;
    gap: 0 10px;
}
.anti_plagiarism_block {
    display: flex;
    align-items: center;
    gap: 0 16px;
}

.select_list_wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 16px;
    margin-bottom: 8px;

    .select {
        width: 400px;
    }
}

.select_list {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    &__item {
        display: flex;
        align-items: center;
        column-gap: 12px;
    }
}

.file_wrapper {
    flex: 1 1 720px;
    min-width: 0px;
}
