@use '@styles/_variables.scss' as vars;

.select_wrapper {
    width: 100%;
}

.drop_menu_wrapper {
    position: relative;
}

.input_wrapper {
    position: relative;
    padding-top: 11px;
    padding-bottom: 11px;
    padding-left: 10px;
    padding-right: 30px;
    background: #ffffff;
    border: 1px solid vars.$grey_text;
    border-radius: 8px;

    &::after {
        content: '';
        position: absolute;
        top: 14px;
        right: 10px;
        width: 15px;
        height: 15px;
        background: url('../../../../assets/icons/arrow-icon.svg') no-repeat center;
        transition: 0.5s ease;
    }

    &--active::after {
        transform: rotate(180deg);
    }
}

.select_input {
    pointer-events: none;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
}

.drop_menu {
    width: 100%;
    position: absolute;
    top: 47px;
}

.option {
    padding: 6px 14px;

    &:hover {
        background-color: vars.$grey_hover;
        cursor: pointer;
    }
}

.placeholder {
    display: flex;
}