.message {
    margin-top: 16px;
}

.actions {

    margin-top: 32px;
    display: flex;
    column-gap: 16px;

    justify-content: flex-end;
}