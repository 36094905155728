@use '@styles/_variables.scss' as vars;

.wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.title_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    column-gap: 12px;
    padding: 24px;
    border-bottom: 1px solid vars.$grey_lines;
}

.content_wrapper {
    display: flex;

    width: 100%;
    position: relative;
    flex-grow: 1;

    overflow: auto;
    max-height: calc(100vh - 210px);
    height: calc(100vh - 210px);

    &__btn {
        position: absolute;
        top: -13px;
        right: 24px;
        border-radius: 8px;
    }
}

.page_header {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.title_navigate {
    display: flex;
    align-items: center;

    h1 {
        margin-left: 16px;
    }
}

.mass_container {
    display: flex;
    justify-content: space-between;
    padding: 6px 0px;

    & > *:not(:last-child) {
        margin-right: 8px;
    }
}

.action_btn {
    display: flex;
    height: 35px;

    circle {
        fill: vars.$white_main;
    }

    & > div {
        margin: 0px 0px 0px 10px;
    }
}

.draft_container {
    padding: 24px 24px;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
}

.user_table {
    display: flex;
}

.buttons_wrapper {
    display: flex;
}

.deadline_text {
    font-size: 1.5rem;
    line-height: 2.1rem;
    color: vars.$red_attention;
    margin-right: 16px;
}

.deadline_wrapper {
    display: flex;
    justify-content: space-between;
    margin-right: 16px;
    align-items: center;

    &:last-child {
        margin-right: 0px;
    }
}

.deadline_btns {
    display: flex;
    column-gap: 16px;
}

.grades {
    display: flex;
    flex-direction: column;
}

.user_heading {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    width: 100%;
}

.grade_title {
    margin-bottom: 28px;
}

.user_sub_header {
    font-size: 1.8rem;
    line-height: 2.4rem;
}
