@use "@styles/_variables.scss" as vars;

.disciplines_count {
    margin-bottom: 24px;
}

.data_list {
    &__header {
        padding: 0px 12px;
        border-bottom: 1px solid vars.$grey_lines;
    }
}

.list_item {
    align-items: center;
    column-gap: 16px;
    padding: 12px 12px;
    border-bottom: 1px solid vars.$grey_lines;
}

.column_content {
    display: flex;
    justify-content: flex-end;
}

.empty_message {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin: 0 auto;
    margin-top: 50px;

    &__icon {
        margin-bottom: 12px;
    }

    &__text {
        margin-bottom: 25px;
        @include vars.font_text18;
        font-weight: 700;
        color: vars.$black_main;
    }
}

.list_wrapper {
    width: 100%;
}

.flex_end {
    display: flex;
    justify-content: flex-end;
}

.pagination {
    width: 100%;
}

.column_label {
    color: vars.$grey_text;
    padding-top: 0px;
    min-width: 140px;
}

.rowLink {
    display: flex;
}
