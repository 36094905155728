@use '@styles/_variables.scss' as vars;

.wrapper {
    padding: 12px 0;
    background-color: vars.$white_main;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    border: 1px solid vars.$grey_lines;
    z-index: vars.$hightest_index;

    a {
        margin-bottom: 16px;
    }
}

