@use '@styles/_variables.scss' as vars;

.button {
    width: 100%;
    justify-content: space-between;
    color: vars.$black_main;

    &:hover {
        color: vars.$black_main;
    }

    span {
        display: flex;
        align-items: center;

        & > svg {
            margin-right: 8px;

            & path {
                stroke: vars.$green_main;
            }
        }
    }
}

.container {
    position: relative;
    width: 100vw;
    max-width: 375px;
    height: 100%;
    padding: 0 20px 28px;

    @media (max-width: vars.$break-point-sm) {
        padding: 28px 20px;
        max-width: unset;
    }

    .close {
        position: absolute;
        top: 16px;
        right: 18px;
        color: vars.$grey_text;

        transition: stroke 0.2s ease-in;

        @media (max-width: vars.$break-point-sm) {
            right: 16px;
        }

        & svg {
            width: 24px;
            height: 24px;
        }

        &:hover {
            svg {
                width: 24px;
                height: 24px;

                path {
                    stroke: black;
                }
            }

        }
    }

    h3 {
        @include vars.font_text19;

        @media (min-width: vars.$break-point-sm) {
            width: calc(100% + 40px);
            padding: 16px 20px;
            margin: 0 -20px;
            box-shadow: 0px 6px 8px 0px rgba(30, 30, 30, 0.08);
        }
    }

    .content {
        margin-top: 8px;
        gap: 16px;
    }
}
