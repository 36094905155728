.root {
    display: flex;
    flex-shrink: 0;
    flex-grow: 0;
    align-items: center;
    justify-content: flex-start;

    &_wrapper {
        position: relative;
    }
}

.inner {
    display: flex;
}