@use "@styles/_variables.scss" as vars;

.container {
    display: flex;
    width: 100%;
}

.status {
    display: flex;
}

.bubble {
    max-width: 480px;
    display: flex;
    align-items: flex-end;
    position: relative;

    @media (max-width: vars.$break-point-sm) {
        max-width: 100%;
    }

    .inner {
        border-radius: 12px;
        border-bottom-left-radius: 0px;
    }

    &::before {
        content: '';
        display: block;
        width: 10px;
        min-width: 10px;
        height: 14px;
        background-image: url("data:image/svg+xml,%3Csvg width='10' height='14' viewBox='0 0 10 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 0C10 4.43333 8.55805 12.04 0.57186 12.6C-0.352214 12.8916 -0.0937281 14 0.898347 14H10V0Z' fill='%23F4F5F5'/%3E%3C/svg%3E%0A");
    }
}

.inner {
    display: flex;
    padding: 8px 14px;

    justify-content: right;
    background-color: vars.$grey_hover;

    width: 100%;
}

.own {
    justify-content: flex-end;

    .bubble {
        .inner {
            background-color: vars.$light_green;

            border-radius: 12px;
            border-bottom-right-radius: 0px;
        }

        &::before {
            display: none;
        }

        &::after {
            content: '';
            display: block;
            width: 10px;
            min-width: 10px;
            height: 14px;
            background-image: url("data:image/svg+xml,%3Csvg width='10' height='14' viewBox='0 0 10 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0C0 4.43333 1.44195 12.04 9.42814 12.6C10.3522 12.8916 10.0937 14 9.10165 14H0V0Z' fill='%23E4F1EF'/%3E%3C/svg%3E%0A");
            position: absolute;
            bottom: 0;
            right: -10px;
        }
    }
}

.photo {
    padding: 0px;
    overflow: hidden;
    border-radius: 12px;
}

.noPseudo {
    border-radius: 12px;

    &::after,
    &::before {
        display: none;
    }
}

.radius {
    border-radius: 12px;
}
