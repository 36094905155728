@use "@styles/_variables.scss" as vars;

.navigation {
    width: 299px;

    padding: 16px 12px;
    margin-bottom: 24px;
    border-radius: 12px;
    background-color: vars.$grey_hover;

    h3 {
        margin-bottom: 4px;
    }

    .attempt_date {
        color: vars.$grey_text;
        margin-bottom: 16px;
    }

    &_items {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        row-gap: 10px;
        column-gap: 8px;
    }

    &_item {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 32px;
        height: 32px;
        background-color: vars.$green_success;
        color: vars.$white_main;

        border-radius: 6px;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.08);

        &--wrong {
            background-color: vars.$red_attention;
        }
    }
}
