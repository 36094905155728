@use "@styles/_variables.scss" as vars;

.wrapper {
    display: flex;
}

.questions {
    width: 100%;
    max-width: 929px;
    margin-right: 16px;
    z-index: vars.$default_index;
}
