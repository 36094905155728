@use '@styles/_variables.scss' as vars;

.delete_button {
    width: 20px;
    height: 20px;
    opacity: 0;
    transition: opacity 300ms ease;
}

.lecture_item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 12px;
    padding: 10px 0;
    border-bottom: 1px solid vars.$grey_lines;
    padding-left: 36px;
    height: 44px;

    &:hover .delete_button {
        opacity: 1;
    }

    &--no-editable {
        padding-left: 0;
    }
}

.link {
    word-break: break-all;

    &:hover {
        color: vars.$green_main;
    }
}
