@use "@styles/variables.scss" as vars;

.container {
    display: flex;

    border: 1px solid vars.$grey_lines;
    border-radius: 16px;

    width: 1050px;
    height: 80vh;
    max-height: 700px;

    overflow: hidden;

    @media (max-width: vars.$break-point-lg) {
        width: 95vw;
        height: 100%;
        max-height: 100vh;
        border-radius: 0;
        border: none;
    }

    @media (max-width: vars.$break-point-sm) {
        width: 100%;
    }
}

.header {
    display: flex;
}

// Messages
.contacts {
    width: 328px;
    display: flex;
    flex-direction: column;

    border-right: 1px solid vars.$grey_lines;

    @media (max-width: vars.$break-point-md) {
        border: none;
        width: 100%;
    }
}

// Chat Messages
.chatWindow {
    position: relative;
}

.messagesContent {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
}

.chatMessages {
    display: flex;
}

.contactList {
    display: flex;
    height: 100%;
    border-right: 1px solid vars.$grey_lines;
}

// Content
.content {
    width: 100%;
}

.contactHeader {
    display: flex;
    justify-content: space-between;
}

.close {
    display: flex;
    padding: 20px 24px 0px 0px;
    align-items: flex-start;
}

.closeIcon {
    cursor: pointer;
}

.textInput {
    display: flex;

}
