.caption {
    margin-bottom: 4px;
}

.input_modal_container {
    display: flex;
    flex-direction: column;
    margin: 16px 0px 24px;
}

.button_wrapper {
    width: 100%;
    display: flex;
    column-gap: 16px;
    justify-content: flex-end;
}

.textarea {
    min-height: 202px;
    resize: none;
}

.select {
    margin-bottom: 32px;
}

.modal {
    max-width: 500px;
}

.file-upload {
    margin-bottom: 24px;
    white-space: nowrap;
    max-width: 100%;

    & > div {
        max-width: 100%;

        & > div {
            max-width: 100%;

            & > div:first-child {
                max-width: calc(100% - 36px);
                min-width: 172px;

                & > div {
                    justify-content: flex-start;
                }
            }
        }
    }
}
