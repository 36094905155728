@use "@styles/_variables.scss" as vars;

.main {
    padding-left: 0;
    padding-right: 0;
    max-width: 100%;

    @media (max-width: vars.$break-point-md) {
        padding-bottom: 14px;
    }
}

.title {
    margin-bottom: 20px;
}

.tabs {
    & ul {
        display: flex;
        max-width: 100%;
        padding-bottom: 2px;
        overflow-x: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none; // Safari and Chrome
            background-color: transparent;
        }
    }
}
