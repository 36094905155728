@use '@styles/_variables.scss' as vars;

.wrapper {
    //position: absolute;
    //left: 0;
    //top: calc(100% + 4px);
    background: vars.$white_main;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    opacity: 0;
    z-index: vars.$hight_index;

    &--position-bottom {
        position: absolute;
        left: 0;
        top: calc(100% + 4px);
    }

    &--position-top {
        position: absolute;
        left: 0;
        bottom: calc(100% + 4px);
    }
}

.root {
    padding: 8px 0;

    &__item {
        padding: 6px 20px 8px;
        transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1);

        &:hover {
            background-color: vars.$grey_hover;
            cursor: pointer;
        }
    }
}
