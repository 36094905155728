@use '@styles/variables.scss' as vars;

.header {
    width: 100%;
    user-select: none;
    display: grid;

    padding-bottom: 12px;
}

.isHeaderLine {
    border-bottom: 1px solid vars.$grey_lines;
}