@use "@styles/variables.scss" as vars;
@use "@styles/mixins/typo" as mixins;

.root {
    display: flex;
    overflow: hidden;

    &__default {
        display: flex;
        align-items: center;
        justify-content: center;

        &-icon {
            display: inline-flex;
            margin-right: 8px;
        }

        &-label {
            transition: color 0.1s ease-in;
            color: vars.$green_main;
            @include vars.font_text14();
            letter-spacing: normal;
        }
    }

    &__photo {
        &-image {
            height: 180px;
            object-fit: cover;
        }
    }

    &__uploading {
        display: flex;
        &-icon {
            margin-right: 10px;
            &-btn {
                margin-right: 8px;
                height: 20px;
            }
        }

        &-caption {
            @include mixins.font_text14();
            margin-right: 6px;
        }

        &-size {
            color: vars.$grey_text;
            @include mixins.font_text14();
        }

        &-content {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
        }

        &-actions {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            height: 20px;
        }
    }

    &__icon {
        margin-right: 10px;
        height: 20px;
        &-btn {
            margin-right: 8px;
            height: 20px;
        }
    }

    .root__content {
        width: calc(100% - 28px);
    }

    .root__caption {
        @include mixins.font_text14();
        margin-right: 6px;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
