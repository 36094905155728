@use "@styles/variables.scss" as vars;
@use "@styles/mixins/typo" as mixins;

.root {
    display: flex;
    overflow: hidden;
    position: relative;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    &__default {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        &-icon {
            display: inline-flex;
            margin-bottom: 10px;
        }

        &-label {
            transition: color 0.1s ease-in;
            color: vars.$green_main;
            //TODO: Replace this shit
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: normal;
        }
    }

    &__delete {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        box-shadow: inset 0px 0px 50px -4px #000000;
        opacity: 0;
        transition: opacity 0.1s ease-in;
        z-index: vars.$low_index;
        width: 300px;
        height: 180px;

        &:hover {
            opacity: 1;
        }

        &-icon {
            height: 36px;
            width: 36px;
        }
    }

    &__photo {
        height: 100%;
        width: 100%;
        position: relative;
        background-color: vars.$grey_hover;

        &-image {
            object-fit: cover;
            height: 100%;
            width: 100%;
        }
    }

    &__uploading {
        display: flex;
        &-icon {
            margin-right: 10px;
            &-btn {
                margin-right: 8px;
                height: 20px;
            }
        }

        &-caption {
            @include mixins.font_text14();
            margin-right: 6px;
        }

        &-size {
            color: vars.$grey_text;
            @include mixins.font_text14();
        }

        &-content {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
        }

        &-actions {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            height: 20px;
        }
    }
}
