.title {
    margin-bottom: 16px;
}

.controls_container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    width: 100%;
    margin-bottom: 24px;
}

.teacher_filter {
    width: 320px;
}

.form {
    width: fit-content;
    display: flex;
    column-gap: 16px;
}
