@use '@styles/_variables.scss' as vars;

.root {
    position: relative;
    display: flex;
    column-gap: 16px;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid vars.$grey_lines;

    &__list_btn {
        position: absolute;
        top: 50%;
        right: 12px;

        transform: translateY(-50%);

        display: flex;
        align-items: center;
        column-gap: 12px;
        height: 100%;

        opacity: 0;
        transition: opacity 300ms ease;

        @media (max-width: vars.$break-point-md) {
            height: initial;
            top: initial;
            bottom: 12px;
            transform: initial;
            align-items: flex-end;
        }
    }

    &:hover {
        background-color: vars.$grey_hover;
    }

    &:hover &__list_btn {
        opacity: 1;
    }
}

.link {
    display: grid;
    grid-template-columns: 40% 20% 40%;
    grid-auto-rows: minmax(min-content, max-content);
    gap: 16px;
    width: 100%;
    height: 100%;
    padding: 12px;
    transition: background-color 0.3s ease;

    @media (max-width: vars.$break-point-md) {
        pointer-events: none;
    }

    @media (max-width: vars.$break-point-md) {
        grid-template-columns: 80% 20%;
    }

    &:hover {
        background-color: vars.$grey_hover;
    }
}

.column {
    word-wrap: break-word;
}

.btn_icon {
    display: block;
    font-size: 0;
    line-height: 0;

    svg {
        color: vars.$grey_text;
        transition: color 0.2s ease;

        &:hover {
            color: vars.$black_main;
        }
    }
}

.link:hover .link_icon {
    svg {
        color: vars.$black_main;
    }
}

.format {
    text-transform: uppercase;

    @media (max-width: vars.$break-point-md) {
        display: flex;
        justify-content: flex-end;
        margin-right: 18px;
    }
}
