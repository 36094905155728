@use '@styles/variables.scss' as vars;

.student {
    display: flex;
    align-items: center;

    padding: 10px 12px 10px 12px;

    border-top: 1px solid vars.$grey_hover;
    border-bottom: 1px solid vars.$grey_hover;

    & > *:not(:last-child) {
        margin-right: 10px;
    }

    &:hover {
        background-color: vars.$grey_hover;

        .student_photo {
            background-color: vars.$white_main;
        }
    }
}

.student_selected {
    background-color: vars.$grey_hover;

    .student_photo {
        background-color: vars.$white_main;
    }
}

.checkbox {
    display: flex;
    align-items: center;
}

.student_photo {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 36px;
    height: 36px;
    border-radius: 50%;
    overflow: hidden;

    border: 1px solid vars.$grey_lines;
    background-color: vars.$grey_hover;

    img {
        max-width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .student_photo_name {
        @include vars.font_text14_bold;
    }
}

.student_name {
    @include vars.font_text14;
    width: 162px;
}

.icon {
    width: 16px;
    height: 16px;
    margin-left: 8px;
}

.select_container {
    display: flex;
    align-items: center;
    .student_photo {
        margin-right: 10px;
    }
    &:hover {
        cursor: pointer;
    }
}

.check_all {
    color: vars.$grey_text;
    border-top: none;
}

.no_students {
    color: vars.$grey_text;
    @include vars.font_text14;
    margin: 72px auto 0px;
    width: fit-content;
}
