@use "./typo" as vars;

@mixin centering {
    padding-left: calc(50% - 640px);
    padding-right: calc(50% - 640px);

    // чтобы контент не прижимался к краям
    @media (max-width: calc(vars.$break-point-lg + 32px * 2)) {
        padding-left: 32px;
        padding-right: 32px;
    }

    @media (max-width: vars.$break-point-lg) {
        padding-left: 32px;
        padding-right: 32px;
    }

    @media (max-width: vars.$break-point-md) {
        padding-left: 40px;
        padding-right: 40px;
    }

    @media (max-width: vars.$break-point-sm) {
        padding-left: 16px;
        padding-right: 16px;
    }
}
