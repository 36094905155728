@use '@styles/_variables.scss' as vars;

.table {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100%;

    overflow-x: scroll;
    overflow: hidden;
}

.scrollBody {
    display: flex;
    overflow-x: auto;
    width: 100%;
}

.pagination {
    display: flex;
}

.noPaddings {
    padding: 6px 0px;
}


// Если нет материалов
.container {
    display: flex;
    flex-direction: column;

    row-gap: 12px;

    margin-top: 64px;
    margin-bottom: 80px;
    margin-left: auto;
    margin-right: auto;
}

.picture,
.title,
.description {
    display: flex;
    align-items: center;
    justify-content: center;

    text-align: center;
}

.picture {
    width: 264px;
    height: 231px;
}

.title {
    @include vars.font_text18()
}