@use "@styles/_variables.scss" as vars;

.main {
    padding: 140px 0 22px;
    align-items: center;

    @media (max-width: vars.$break-point-lg) {
        padding: 120px 0 22px;
    }

    @media (max-width: vars.$break-point-md) {
        padding: 120px 0 14px;
    }

    @media (max-width: vars.$break-point-sm) {
        padding: 48px 0 14px;
    }
}

.picture {
    margin-bottom: 40px;
    width: 540px;
    height: auto;

    @media (max-width: vars.$break-point-lg) {
        width: 440px;
    }

    @media (max-width: vars.$break-point-sm) {
        width: 100%;
        max-width: 311px;
    }
}

.text {
    width: 100%;
    max-width: 320px;
    text-align: center;
    margin-bottom: 22px;
    font-size: 1.5rem;
}

.button {
    @media (max-width: vars.$break-point-lg) {
        max-width: 311px;
        width: 100%;
    }
}
