@use "@styles/_variables.scss" as vars;

.root {
    display: flex;
    column-gap: 4px;
    align-items: center;
    color: vars.$black_main;
    transition: color 300ms;

    &:hover {
        color: vars.$grey_text;
    }

    &--download {
        @include vars.font_text13_bold;
        color: vars.$green_main;

        span {
            @include vars.font_text13_bold;

            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        &:hover {
            color: vars.$grey_text;
        }
    }

    &--disabled {
        pointer-events: none !important;
        cursor: default !important;
        color: vars.$grey_text;
    }
}
