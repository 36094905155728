.root {
    margin: 0;
    padding: 0;
    background-color: transparent;
    text-align: center;
    vertical-align: middle;
    border: none;
    cursor: pointer;

    &:hover {
        background-color: transparent;
    }
}
