@use "@styles/variables.scss" as vars;

.wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    width: 100%;

    @media (max-width: vars.$break-point-sm) {
        row-gap: 8px;
    }
}

.container {
    display: flex;
    flex-shrink: 0;
    flex-grow: 0;

    width: 100%;

    column-gap: 10px;
    position: relative;
}

.icon-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loader {
    width: 20px;
    height: 20px;
    fill: vars.$green_main;
}

.error-icon {
    width: 20px;
    height: 20px;

    & path {
        stroke: vars.$red_attention;
    }
}

.iconContainer {
    display: flex;
    border-radius: 50%;
    width: 40px;
    min-width: 40px;
    height: 40px;


    align-items: center;
    justify-content: center;

    background-color: vars.$white_main;
}

.pointer {
    cursor: pointer;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: calc(100% - 50px);
}

.title {
    @include vars.font_text14();
    color: vars.$green_main;
    font-weight: 700;

    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.size {
    @include vars.font_text13();
    color: vars.$grey_text;
    margin-top: 4px;
}

.error {
    color: vars.$red_attention;
}

.metaContainer {
    display: flex;
    align-items: flex-end;
    column-gap: 12px;
    justify-content: space-between;
}

.text-message {
    width: 100%;
}
