@use '@styles/_variables.scss' as vars;


.paper {
    min-width: 700px;
}

.title {
    margin-bottom: 20px;
}

.list {
    margin-bottom: 20px;
}

.button {
    column-gap: 8px;
}
