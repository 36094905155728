@use "@styles/_variables.scss" as vars;

.caption {
    padding: 20px;
    border-bottom: 1px solid vars.$grey_lines;
    position: relative;
    height: 64px;
    display: flex;
    align-items: center;

    @media (max-width: vars.$break-point-lg) {
        padding: 20px 20px 20px 24px;
    }

    @media (max-width: vars.$break-point-md) {
        border-bottom: none;
        padding: 16px 24px;
        height: 58px;
        min-height: 58px;

        &::after {
            content: "";
            position: absolute;
            bottom: -8px;
            height: 8px;
            width: 100%;
            left: 0;
            background: linear-gradient(0deg, rgba(30, 30, 30, 0.08) 0%, rgba(224, 224, 224, 0) 100%);
            transform: matrix(1, 0, 0, -1, 0, 0);
        }
    }

    @media (max-width: vars.$break-point-sm) {
        padding: 14px 16px;
        height: 54px;
        min-height: 54px;
    }

    & h3 {
        font-size: 2rem;
        font-weight: 800;
    }

    .closeIcon {
        position: absolute;
        cursor: pointer;
        right: 18px;

        @media (max-width: vars.$break-point-md) {
            right: 16px;
        }

        & svg {
            width: 24px;
            height: 24px;
        }

        &:hover {
            path {
                stroke: vars.$black_main;
            }
        }
    }
}
