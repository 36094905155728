@use "@styles/_variables.scss" as vars;

.data_list {
    &__header {
        padding: 12px 12px 0px 12px;
        padding-top: 0;
    }
}

.list_item {
    display: flex;
    align-items: center;
    column-gap: 4px;
    padding: 12px 12px;
    border-top: 1px solid vars.$grey_lines;

    &:first-child {
        border-top: none;
    }

    &:last-child {
        border-bottom: none;
    }

    @media (max-width: vars.$break-point-sm) {
        padding: 12px 0px;
    }

    &__grid {
        grid-template-columns: 50% 50%;

        @media (max-width: vars.$break-point-lg) {
            grid-template-columns: 100%;
            row-gap: 8px;
        }

        @media (max-width: vars.$break-point-sm) {
            padding: 0px;
        }
    }

    &__discipline_name {
        display: block;
        margin-bottom: 4px;

        @media (max-width: vars.$break-point-lg) {
            font-weight: 700;
        }
    }

    &__info_task_grid {
        grid-template-columns: 60% 26% 6%;

        @media (max-width: vars.$break-point-lg) {
            grid-template-columns: minmax(min-content, max-content) 25% auto;
        }

        @media (max-width: vars.$break-point-md) {
            gap: 12px;
        }

        @media (max-width: vars.$break-point-sm) {
            grid-template-columns: minmax(min-content, max-content);
            row-gap: 2px;
        }
    }

    &:last-child {
        border-bottom: none;
    }

    &__header {
        padding: 8px 12px 9px;
        background-color: vars.$light_green;
    }

    &__title_task {
        @include vars.font_text10;
        color: vars.$grey_text;
        text-transform: uppercase;

        &:hover {
            text-decoration: underline;
        }
    }

    &__date {
        @media (max-width: vars.$break-point-lg) {
            color: vars.$black_grey;
            @include vars.font_text13;
        }
    }

    &__user_name {
        cursor: pointer;

        &:hover {
            color: vars.$green_main;
        }

        @media (max-width: vars.$break-point-lg) {
            color: vars.$black_grey;
            @include vars.font_text13;
        }
    }

    &__grade {
        @media (max-width: vars.$break-point-lg) {
            @include vars.font_text13;
        }
    }
}

.deadline_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 10px;
}

.empty_message {
    margin: 120px auto 0px auto;
    text-align: center;

    & > * {
        margin: 0px auto 12px auto;
    }

    & > *:last-child {
        margin-bottom: 0px;
    }

    &_header {
        @include vars.font_text18;
    }

    &_text {
        @include vars.font_text14;
    }
}

.list_wrapper {
    width: 100%;
    margin-top: 8px;
}

.flex_end {
    display: flex;
    justify-content: flex-end;

    & > div {
        min-width: unset !important;
    }

    @media (max-width: vars.$break-point-sm) {
        grid-row: 1 / 3;
        grid-column: 2 / 3;
        align-self: end;
    }
}

.pagination {
    width: 100%;
}

.column_label {
    color: vars.$grey_text;
}

.loader {
    margin: 120px auto 0px auto;
}
