@use "@styles/_variables.scss" as vars;

.wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;

    @media (max-width: vars.$break-point-md) {
        padding-bottom: 30px;
    }

    @media (max-width: vars.$break-point-sm) {
        padding-bottom: 48px;
    }
}

.header {
    margin-bottom: 24px;

    @media (max-width: vars.$break-point-md) {
        margin: 66px 0px 20px;
    }

    @media (max-width: vars.$break-point-sm) {
        margin: 46px 0px 20px;
    }
}

.questions_wrapper {
    display: flex;
    width: 100%;

    @media (max-width: vars.$break-point-md) {
        display: block;
    }
}

.questions {
    width: 100%;
    max-width: 952px;
    margin-right: 32px;
    z-index: vars.$default_index;

    @media (max-width: vars.$break-point-lg) {
        max-width: 629px;
    }

    @media (max-width: vars.$break-point-md) {
        max-width: 100%;
        margin-right: 0px;
    }
}

.question {
    &_navigation {
        width: 296px;
        height: fit-content;
        position: sticky;
        right: 0px;
        top: 89px;

        @media (max-width: vars.$break-point-md) {
            z-index: vars.$hight_index;
        }

        @media (max-width: vars.$break-point-sm) {
            z-index: vars.$hight_index;
        }

        &_items {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            row-gap: 10px;
            column-gap: 8px;

            padding: 16px 12px;
            margin-bottom: 24px;
            border-radius: 12px;
            background-color: vars.$grey_hover;

            @media (max-width: vars.$break-point-md) {
                position: fixed;
                border-radius: 0px;
                top: 0;
                left: 0;
                width: 100%;
                padding: 16px 24px;

                flex-wrap: nowrap;
                overflow-x: auto;
            }

            @media (max-width: vars.$break-point-sm) {
                padding: 10px;
            }
        }

        &_item {
            display: flex;
            align-items: center;
            justify-content: center;

            min-width: 32px;
            min-height: 32px;
            background-color: vars.$white_main;

            border-radius: 6px;
            border: 1px solid vars.$outline_borders;
            box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.08);

            &_active {
                position: relative;

                &::after {
                    position: absolute;
                    content: "";
                    width: 5px;
                    height: 5px;
                    top: 3px;
                    right: 3px;
                    border-radius: 50%;
                    background-color: vars.$red_attention;
                }
            }
        }

        &_btn {
            margin-bottom: 16px;

            @media (max-width: vars.$break-point-md) {
                max-width: 200px;
                margin-bottom: 0px;
                margin-left: 12px;
            }

            @media (max-width: vars.$break-point-sm) {
                max-width: 275px;
            }
        }

        &_deadline_wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &_dangerous {
            color: vars.$red_attention;
            path {
                stroke: vars.$red_attention;
            }
        }

        p {
            @include vars.font_text15;
            margin: 0px 0px 0px 8px;
        }
    }

    &_icon {
        position: absolute;
        top: 24px;
        right: 24px;
    }

    .question_icon {
        cursor: pointer;
    }

    &_active {
        .question_icon {
            path {
                stroke: vars.$red_attention;
            }
        }
    }
}

.timer_wrapper {
    @media (max-width: vars.$break-point-md) {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        width: 100%;
        padding: 12px 16px;
        background-color: vars.$white_main;

        position: fixed;
        bottom: 0;
        left: 0;
    }

    @media (max-width: vars.$break-point-sm) {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    &:before {
        @media (max-width: vars.$break-point-md) {
            content: "";
            width: 100vw;
            height: 8px;
            background: linear-gradient(0deg, rgba(30, 30, 30, 0.08) 0%, rgba(224, 224, 224, 0) 100%);
            position: absolute;
            top: -8px;
            left: 0px;
        }
    }
}
