.select {
    margin-bottom: 32px;
}

.buttons_wrapper {
    width: 100%;
    display: flex;
    column-gap: 16px;
    justify-content: flex-end;
}

.label {
    margin-bottom: 4px;
}


