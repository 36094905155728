@use '@styles/variables.scss' as vars;


.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    margin-top: 32px;

    @media (max-width: vars.$break-point-lg) {
        margin-top: 28px;
    }
}

.tabs {
    margin-top: 20px;
    display: flex;
}

.title {
    display: flex;
}

.tabContainer {
    display: flex;
}

.tabs {
    display: flex;
    column-gap: 12px;
}

.tab {
    display: flex;
}

.tabBtn {
    display: flex;
    background-color: vars.$grey_hover;
    color: vars.$black_main;
}

.panels {
    display: flex;
    margin-top: 30px;
}

.panel {
    display: flex;
    width: 100%;
}

.actions {
    display: flex
}

.tab[aria-selected="true"] {
    color: vars.$white_main;
    border-bottom: none;

    button {
        background-color: vars.$black_main;
        color: vars.$white_main;
    }
}
