@use '@styles/_variables.scss' as vars;

.grid {
    grid-template-columns: repeat(7, minmax(0, 1fr));
    gap: 1px;
    height: auto;
    background-color: vars.$grey_elements;
    border: 1px solid vars.$grey_elements;
    border-top: none;
}

.column {
    background-color: vars.$grey_pale;
    position: relative;
}

.show_day {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 33px;
    padding-bottom: 4px;
}

.events-container {
    padding-top: 24px;
    row-gap: 12px;
    display: flex;
    flex-direction: column;

    &>ul {
        row-gap: 8px;
    }

    & li {
        padding: 6px 8px;
    }
}

.events_caption {
    @include vars.font_text13;
    line-height: 1.6rem;
}

.loader {
    margin-top: 120px;
}

.error-data {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}