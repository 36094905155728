@use '@styles/variables.scss' as vars;


.container {
    display: flex;
    flex-direction: column;

    row-gap: 12px;
    width: 205px;

    margin-top: 64px;
    margin-bottom: 80px;
}

.picture,
.title,
.description {
    display: flex;
    align-items: center;
    justify-content: center;

    text-align: center;
}

.title {
    @include vars.font_text18()
}