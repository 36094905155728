@use '@styles/variables.scss' as vars;

.title {
    margin-bottom: 24px;
}

.table_wrapper {
    width: 664px;
    margin-top: 24px;
}

.input_wrapper {
    margin-bottom: 16px;
}

.button_wrapper {
    width: 100%;
    display: flex;
    column-gap: 16px;
    justify-content: flex-end;
}

.select {
    margin-bottom: 32px;
}

.group_collapse {
    [class~=rc-collapse-item] {
        [class~=rc-collapse-header] {
            border-bottom: 1px solid vars.$grey_lines;
            padding: 12px;

            &:hover {
                background-color: vars.$grey_hover;

                [class~=table-menu] {
                    opacity: 1;
                }
            }

            [class~=rc-collapse-header-text] {
                @include vars.font_text14;
                font-weight: 500;

                width: 100%;
                padding: 0px;
            }
        }

        [class~=rc-collapse-expand-icon] {
            margin-right: 12px;
            cursor: pointer;
        }
    }

    [class~=rc-collapse-content-box] {
        margin: 0px;

        [class~=rc-collapse-item-active] {
            [class~=rc-collapse-header] {
                border-bottom: 1px solid vars.$grey_lines;
            }
        }
    }

    [class~=rc-collapse-item-disabled] {
        [class~=rc-collapse-header] {
            color: vars.$black_main;
            cursor: default;

            [class~=rc-collapse-expand-icon] {
                visibility: hidden;
                margin-right: 0px;
            }
        }
    }
}

.table_header {
    @include vars.font_text13;
    color: vars.$grey_text;
    border-bottom: 1px solid vars.$grey_lines;

    display: flex;
    padding: 0px 56px 12px 12px;

    & > * {
        flex-grow: 1;

        &:first-child {
            flex-basis: 412px;
        }

        &:nth-child(2) {
            flex-basis: 184px;
        }
    }
}

