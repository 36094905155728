.title {
    margin-bottom: 16px;
}

.controls_container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    width: 100%;
    margin-bottom: 24px;
}

.material_filter {
    width: 320px;
}

.treeSelect {
    min-width: 260px;
}

.form {
    width: fit-content;
    display: flex;
    column-gap: 16px;
}

.box {
    display: flex;
    align-items: center;
    justify-content: center;
}

.selectGroup {
    min-width: 240px;
}

.datePicker {
    min-width: 240px;
}

.table {
    width: 100%
}

.actions {
    display: flex;
    column-gap: 10px;
}

.settings {
    padding: 8px;
    width: 34px;
    min-width: 34px;
}