@forward "@styles/mixins/typo";
@forward "@styles/mixins/center";
@forward "@styles/mixins/helpers";
@forward "@styles/functions/helpers";

$base-color: #28395c;

// Fonts
$baseFont: "Mulish", Arial, Helvetica, sans-serif;
$baseFontSize: 10px;

// Main colors
$white_main: #ffffff;
$green_main: #06725e;
$yellow: #f8e955;
$black_main: #222626;
$red_attention: #fb3c3c;
$grey_text: #a0a0a0;
$grey: #eceded;
$tab_hover: #e7ebeb;
$outline_borders: #e0e0e0;
$dark_grey: #999999;

// Additional color
$chatHover: #e9f1fa;
$yellow_grade: #eba800;
$grey_elements: #e0e0e0;
$grey_pale: #fcfcfc;
$supa_green: #06725e;
$green_dark: #144b3f;
$red_background: #feeeee;
$red_attention_hover: #ec3131;
$black_grey: #555555;
$green_success: #259b5b;
$green_success_05: rgba(37, 155, 91, 0.5);
$light_green: #e4f1ef;
$profile_grey_dark: #c4c4c4;
$grey_empty: rgba(136, 136, 136, 0.8);
$grey_lines: #eaeeee;
$green_05: rgba(20, 75, 63, 0.5);
$black: #000;
$green_pale: #e0f0ed;
$red_pale: #feeeee;
$yellow_pale: #fcf4ed;
$orange: #f47805;
$grey_e7: #e7ebeb;
$grey_88: #888888;

// hover
$green_main_hover: #0d5f4f;
$grey_lines_hover: #e3e6e6;
$grey_hover: #f4f5f5;
$dark_hover: #444444;
$outline_hover: #a0a0a0;

//z-index
$negative_index: -1;
$default_index: 0;
$low_index: 10;
$hight_index: 100;
$hightest_index: 1000;
