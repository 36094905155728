@use "@styles/_variables.scss" as vars;


.container {
    display: flex;
    white-space: nowrap;

    &:hover {
        background-color: vars.$grey_hover;
        cursor: pointer;
    }
}

.inner {
    padding: 6px 20px 8px;
    width: 100%;
    transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1);
}

.danger {
    color: vars.$red_attention_hover;
}