@use '@styles/_variables.scss' as vars;

.card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 30px;
    padding: 19.5px 12px 19.5px 4px;

    position: relative;

    @media (max-width: vars.$break-point-lg) {
        background-color: vars.$white_main;
        border-radius: 10px;
        border: 1px solid vars.$grey_lines;
        box-shadow: 0px 4px 24px rgba(53, 53, 53, 0.06);
        cursor: pointer;
        padding: 16px;
    }

    @media (max-width: vars.$break-point-sm) {
        padding: 17.5px 20px 17.5px 12px;
    }
}

.title_wrapper {
    display: flex;
    align-items: center;
    column-gap: 10px;
}

.link {
    font-weight: 700;
    font-size: 1.8rem;
    line-height: 2.4rem;
    max-width: 80%;
    word-break: break-word;
}

.maximum_score {
    margin-left: -2px;
    padding: 2px 8px 3px;
    background-color: vars.$grey_hover;
    border-radius: 25px;
    font-weight: 700;
    font-size: 0.9rem;
    line-height: 1.5rem;
    letter-spacing: 0.05em;
    color: vars.$black_grey;
    text-transform: uppercase;
}

.wrapper {
    display: flex;
    align-items: center;
    gap: 8px;

    & h3 {
        max-width: 70%;
        word-break: break-word;

        @media (max-width: vars.$break-point-lg) {
            font-size: 1.5rem;
            line-height: 2.1rem;
            font-weight: 700;
        }
    }

    @media (max-width: vars.$break-point-sm) {
        flex-direction: column;
        align-items: flex-start;

        & h3 {
            padding-right: 32px;
            max-width: unset;
        }
    }
}
