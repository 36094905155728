@use "@styles/_variables.scss" as vars;

.textArea {
    display: flex;
    width: 100%;
    resize: none;
    background-color: transparent;
    border: none;
    outline: none;
    padding: 0;
    align-items: center;
    height: 2rem;

    @include vars.font_text14();
    font-family: vars.$baseFont;
    font-weight: 500;

    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none; // Safari and Chrome
        background-color: transparent;
    }

    @media (max-width: vars.$break-point-sm) {
        font-size: 16px;
    }

    &:-moz-placeholder, /* Firefox 18- */
    &::-moz-placeholder, /* Firefox 19+ */
    &:-ms-input-placeholder, /* IE 10+ */
    &::-webkit-input-placeholder, /* Webkit based */
    &::placeholder {
        color: vars.$grey_text;
    }
}

.menu {
    position: fixed;
    background-color: vars.$white_main;
    border-radius: 10px;
    overflow: hidden;
    padding: 8px 0px;
    box-shadow: 0px 10px 30px rgb(0 0 0 / 10%);
    transform: translateY(-100%);

    & ul {
        padding: 0;

        & li {
            padding: 6px 20px 8px;
            cursor: pointer;
            transition: background-color 0.3s;

            &:hover {
                background-color: vars.$grey_hover;
            }
        }
    }
}
