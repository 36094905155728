@use '@styles/_variables.scss' as vars;

.status {
    display: inline-block;
    padding: 4px 12px;
    margin-bottom: 8px;
    background: vars.$grey_hover;
    color: vars.$grey_text;
    font-weight: 700;
    border-radius: 50px;

    &_success {
        background: vars.$green_success;
        color: vars.$white_main;
    }
}

.title {
    margin-bottom: 16px;
}

.description {
    display: flex;
    flex-direction: column;
    row-gap: 16px;

    &:not(:last-child) {
        margin-bottom: 24px;
    }

    &__item {
        display: flex;
        align-items: center;
        column-gap: 10px;
        word-break: break-word;

        p {
            margin-bottom: 0px;
        }
    }
}

.button {
    &:not(:last-child) {
        margin-bottom: 16px;
    }
}
