@use '@styles/variables.scss' as vars;

.actions {
    display: flex;
    padding: 17px 0px 0px 0px;
    align-items: center;
    justify-content: space-between;
    position: relative;

    &:before {
        position: absolute;
        content: "";
        width: 700px;
        height: 8px;
        top: -8px;
        left: -24px;

        border-bottom: 1px solid vars.$grey_lines;
    }

    &--shadow {
        &:before {
            background-image: linear-gradient(0deg, #f0f0f0 0%, rgba(255, 255, 255, 0) 100%);
        }
    }

    &--no_steps {
        justify-content: flex-end;
    }
}

.steps {
    @include vars.font_text15();
    color: vars.$dark_grey;
}

.btns {
    display: flex;
    column-gap: 16px;
}
