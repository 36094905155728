@use "@styles/variables.scss" as vars;
@use "@styles/mixins/typo" as mixins;

.root {
    &__caption {
        font-weight: 400;
        @include vars.font_text13;

        color: vars.$grey_text;

        span {
            @include vars.font_text13;
        }
    }

    &--block {
        margin-top: 8px;
    }

    &--inline {
        white-space: nowrap;
    }
}
