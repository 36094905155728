.tabs_wrapper {
    width: 100%;
    position: relative;

    &__btn {
        position: absolute;
        top: -13px;
        right: 0px;
        border-radius: 8px;
    }
}

.tabs {
    margin-bottom: 24px;
}
