@use "@styles/_variables.scss" as vars;

.back_link {
    display: flex;
    column-gap: 12px;
    align-items: center;
    margin-bottom: 16px;
}

.title {
    margin-bottom: 12px;
}

.text {
    margin-bottom: 20px;
}

.input_layout {
    display: flex;
    flex-wrap: wrap;
    row-gap: 20px;
    margin-bottom: 32px;

    @media (max-width: vars.$break-point-lg) {
        margin-bottom: 24px;
    }
}

.button_submit {
    margin-bottom: 20px;
}

.notification_success {
    width: 100%;
    padding: 24px;
    padding-left: 68px;
    background: vars.$white_main;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.06);
    border: 1px solid #f0f0f0;
    border-radius: 16px;
    background: url("/assets/icons/notfication-recover-success.svg") no-repeat;
    background-position: 24px 50%;
}
