@use '@styles/_variables.scss' as vars;

.root {
    padding: 24px 24px 32px 24px;
    background: vars.$white_main;
    border: 1px solid #f0f0f0;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.06);
    border-radius: 16px;
    position: relative;

    &--full_width {
        width: 100%;
    }
}