@use '@styles/_variables.scss' as vars;

.list_item {
    position: relative;
    display: flex;
    align-items: center;
    column-gap: 16px;
    padding: 12px 0;
    border-bottom: 1px solid vars.$grey_lines;

    &:hover &__button {
        opacity: 1;
    }

    &__title {
        display: flex;
        align-items: center;
        column-gap: 10px;
    }

    &__icon {
        width: 32px;
        height: 32px;
    }

    &__button_wrapper {
        position: absolute;
        top: 22px;
        right: 1px;
        display: flex;
        align-items: center;
        column-gap: 14.7px;
    }

    &__button {
        opacity: 0;
        transition: opacity 300ms ease;
    }
}
