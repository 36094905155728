@use '@styles/_variables.scss' as vars;

.container {
    display: inline-flex;
    position: relative;
    width: 100%;
}

.input {
    min-width: 1px;
    padding: 2px 6px;
    margin-left: -6px;
    color: vars.$black_main;
    border: 1px solid vars.$grey_text;
    border-radius: 6px;
    outline: none;
    box-sizing: content-box;

    font-family: vars.$baseFont;
    @include vars.font_text14();
}

.virtual {
    position: absolute;
    top: 0px;
    left: 0px;
    visibility: hidden;
    height: 0px;
    overflow: scroll;
    white-space: pre;

    font-family: vars.$baseFont;
    @include vars.font_text14();
}