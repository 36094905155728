@use '@styles/_variables.scss' as vars;

.item {
    width: 100%;
    border-radius: 8px;
    background-color: vars.$grey_hover;
    padding: 12px 12px 14px;

    .row {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .title {
        margin: 0;
        @include vars.font_text14_bold;
        max-width: 70%;
    }

    .grade {
        margin: 0;
        @include vars.font_text14_bold;
    }

    .text {
        @include vars.font_text13;
    }

    .text-overflow {
        text-overflow: ellipsis;
        max-height: 36px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
}

.text-offset {
    margin-top: 16px;
}

.more {
    background-color: transparent;
    border: none;
    padding: 0;
    @include vars.font_text13;
    color: vars.$green_main;
    cursor: pointer;
    margin-top: 8px;
}