@use '@styles/_variables.scss' as vars;

.root {
    width: 100%;
    position: relative;
    padding-bottom: 56%;
}

.wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    font-size: 0;
    line-height: 0;
}

.image {
    width: 100%;
    height: 100%;
    border-radius: 12px;
    object-fit: cover;
}

.button {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
}

.iframe {
    border-radius: 12px;
}

.play_icon {
    width: 75px;
    height: 75px;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    transition: background-color 0.2s ease-out;

    &:hover {
        background-color: vars.$black_main;
    }

    & > svg {
        path {
            fill: vars.$white_main;
        }
    }

    @media (max-width: vars.$break-point-lg) {
        width: 48px;
        height: 48px;
    }
}
