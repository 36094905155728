@use '@styles/_variables.scss' as vars;

.title_wrapper {
    display: flex;
    align-items: center;
    column-gap: 12px;
    margin-bottom: 26px;
}

.paper {
    width: 1034px;
}
