@use '@styles/variables.scss' as vars;

.cell {
    @include vars.font_text14();
    padding: 12px 0px 12px 0px;
    flex-shrink: 0;

    min-width: 200px;
    &:last-child {
        min-width: 150px;
    }
}

.headerCell {
    color: vars.$grey_text;
}

.last {
    display: flex;
    margin-left: auto;
}

.noPaddings {
    display: flex;
    padding: 0px;
}