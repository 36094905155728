@use "@styles/_variables.scss" as vars;

.title_wrapper {
    display: flex;
    align-items: center;
    column-gap: 12px;
    margin-bottom: 26px;

    h1:not(:last-child) {
        margin-bottom: 4px;
    }
}

.profile {
    width: 848px;
    padding: 24px 24px 32px;
    border-radius: 16px;

    @media (max-width: vars.$break-point-lg) {
        width: 629px;
    }

    @media (max-width: vars.$break-point-md) {
        width: 100%;
    }

    @media (max-width: vars.$break-point-sm) {
        padding: 16px 16px 24px;
        border-radius: 12px;
    }
}

.subtitle {
    margin-bottom: 12px;

    @media (max-width: vars.$break-point-lg) {
        @include vars.font_h3;
    }

    p {
        margin-bottom: 0px;
    }
}

.form {
    display: flex;
    flex-direction: column;
    margin-top: 32px;

    @media (max-width: vars.$break-point-lg) {
        margin-top: 20px;
    }

    @media (max-width: vars.$break-point-sm) {
        margin-top: 24px;
    }
}

.field {
    display: flex;
    margin-bottom: 16px;

    &:last-child {
        margin-bottom: 0px;
    }

    @media (max-width: vars.$break-point-sm) {
        flex-direction: column;
        row-gap: 4px;
        margin-bottom: 16px;
        width: 100%;
    }
}

.label {
    display: flex;
    flex-direction: column;
    width: 194px;
    margin: 11px 16px 0px 0px;

    @media (max-width: vars.$break-point-lg) {
        width: 159px;
    }

    @media (max-width: vars.$break-point-sm) {
        margin: 0 16px 0 0;
    }
}

.content {
    display: flex;
    flex-direction: column;
}

.input {
    width: 320px;

    @media (max-width: vars.$break-point-sm) {
        width: 100%;
    }
}

.inputs_wrapper {
    &:not(:last-child) {
        margin-bottom: 32px;

        @media (max-width: vars.$break-point-lg) {
            margin-bottom: 24px;
        }
    }
}

.btns_wrapper {
    display: flex;
    column-gap: 16px;
    width: fit-content;
    margin-left: 210px;

    @media (max-width: vars.$break-point-lg) {
        margin-left: 175px;
    }

    @media (max-width: vars.$break-point-sm) {
        flex-direction: column;
        row-gap: 16px;
        width: 100%;
        margin-left: 0px;
    }
}
