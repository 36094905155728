@use "@styles/_variables.scss" as vars;

.title {
    margin-bottom: 16px;
}

.tabs_container {
    margin-top: 20px;
    position: relative;

    & > ul.tabs {
        margin-bottom: 30px;
    }

    & > ul {
        display: flex;

        padding-bottom: 2px;
        padding-left: var(--page-padding);
        padding-right: var(--page-padding);
        @include vars.horizontal_scroll;
        overflow-x: auto;
        border-bottom: 1px solid vars.$grey_lines;

        @media (max-width: vars.$break-point-lg) {
            margin-left: calc(var(--page-padding) * -1);
            margin-right: calc(var(--page-padding) * -1);
            width: calc(100% + var(--page-padding) * 2);
            max-width: calc(100% + var(--page-padding) * 2);
            --page-padding: 0;
        }

        @media (max-width: vars.$break-point-sm) {
            --page-padding: 16px;
        }

        & li > div {
            white-space: nowrap;
        }
    }

    &--student {
        & > ul {
            @media (min-width: vars.$break-point-lg) {
                border-bottom: none;
            }
        }
    }

    &__btn {
        position: absolute;
        top: -13px;
        right: 24px;
        border-radius: 8px;

        &__mobile {
            display: none;
            width: 100%;
            margin-bottom: 24px;

            @media (max-width: vars.$break-point-sm) {
                display: block;
            }
        }
    }
}


.tabs {
    display: flex;
}
