@use '@styles/_variables.scss' as vars;

.card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 30px;
    padding: 16px 12px 16px 4px;

    position: relative;

    @media (max-width: vars.$break-point-lg) {
        background-color: vars.$white_main;
        border-radius: 10px;
        border: 1px solid vars.$grey_lines;
        box-shadow: 0px 4px 24px rgba(53, 53, 53, 0.06);
        cursor: pointer;
        padding: 17.5px 20px 17.5px 16px
    }

    @media (max-width: vars.$break-point-md) {
        padding-right: 16px;
    }

    @media (max-width: vars.$break-point-sm) {
        column-gap: 18px;
        padding: 16px 20px 16px 12px;
    }
}

.name {
    transition: color 0.2s ease;

    &:hover {
        color: vars.$grey_text;
    }
}

.title_wrapper {
    display: flex;
    align-items: center;
    column-gap: 10px;
}

.progress_bar_wrapper {
    display: flex;
    align-items: center;
    column-gap: 24px;

    @media (max-width: vars.$break-point-sm) {
        flex-grow: 1;
        justify-content: space-between;
    }
}

.progress_bar_container {
    @media (max-width: vars.$break-point-sm) {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
    }
}
