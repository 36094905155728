@use "@styles/_variables.scss" as vars;

.root {
    width: 100%;
    max-width: 250px;
    min-width: 100%;
}

.button_drop {
    @include vars.font_text13_bold;
    overflow: hidden;
    text-align: start;
    align-items: center;
}

.drop_menu {
    width: 100%;
}

.drop_menu_item__link {
    display: block;
    align-items: center;
    width: 100%;
    height: 100%;
    color: vars.$black_main;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &:hover {
        color: vars.$black_main;
    }
}

.button {
    max-width: 200px;
}

.button_content {
    display: flex;
}

.title {
    display: block;
    max-width: 100%;
    max-height: 16px;
    line-height: 1.6rem;
    overflow: hidden;
    word-break: break-all;
    color: vars.$black_main;
}

.extension {
    display: flex;
    max-width: 100%;
    max-height: 16px;
    line-height: 1.6rem;

    position: relative;
    left: 0px;
}