@use '@styles/_variables.scss' as vars;

.layout_container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.main_layout_wrapper {
    @include vars.centering;
    display: flex;
    width: 100%;
    flex-grow: 1;
    padding-bottom: 72px;

    @media (max-width: vars.$break-point-sm) {
        padding-bottom: 64px;
    }
}

.header {
    @include vars.centering;
}

.footer {
    column-gap: 166px;
}

.block_wrapper {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    align-items: flex-start;
    column-gap: 32px;
}

.contacts_wrapper {
    display: flex;
    column-gap: 32px;
    width: 63%;
    justify-content: space-between;
    align-items: flex-start;
}
