@use '@styles/_variables.scss' as vars;

.link {
    align-items: flex-start;
    height: 100%;
}

.wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    transition: color 300ms ease;

    &:hover {
        color: vars.$grey_text;
    }

    @media (max-width: vars.$break-point-md) {
        min-width: 200px;
        max-width: 200px;
    }
}

.date {
    font-size: 1.2rem;
    color: vars.$grey_text;
    margin-top: 8px;
}

.name {
    word-break: break-word;

    &:not(:last-child) {
        margin-bottom: 8px;
    }
}

.video {
    margin-bottom: 10px;
}
