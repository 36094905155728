@use '@styles/_variables.scss' as vars;

.title {
    margin-bottom: 12px;
}

.text {
    margin-bottom: 20px;
}

.input_layout {
    display: flex;
    flex-wrap: wrap;
    row-gap: 20px;
    margin-bottom: 32px;
}

.button_submit {
    margin-bottom: 20px;
}
