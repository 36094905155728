@use "@styles/_variables.scss" as vars;

.root {
    position: relative;
    cursor: pointer;
    user-select: none;
    width: 100%;
    display: flex;
    padding: 0;

    &__input {
        opacity: 0;
        position: absolute;
        height: 18px;
        width: 18px;
        cursor: pointer;
        margin: 0px;
        background-color: white;

        &:checked + .root__label::before {
            background-color: vars.$yellow;
            background-repeat: no-repeat;
            background-position: center center;
            background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTEiIHZpZXdCb3g9IjAgMCAxNCAxMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxwYXRoIGQ9Ik0xMiAxLjk5OTgyTDUuMzMzNDkgOC45OTk4MkwyIDUuNDk5NzQiIHN0cm9rZT0iIzIyMjYyNiIgc3Ryb2tlLXdpZHRoPSIyLjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPg0KPC9zdmc+DQo=");
            box-shadow: none;
        }
    }

    &__label {
        display: flex;
        align-items: center;
        color: vars.$white_main;
        transition: background-color 0.2s ease-in;
        cursor: pointer;

        &:before {
            content: "";
            border-radius: 4px;
            box-shadow: 0 0 0 1px vars.$grey_elements;
            display: flex;
            flex-shrink: 0;
            z-index: vars.$low_index;
            transition: background-color 0.2s ease-in;
            height: 18px;
            width: 18px;
            background-color: white;
        }

        & > span {
            color: vars.$black_main;
            margin-left: 12px;
        }
    }

    // &--label {
    //     &::before {
    //     }
    // }

    &--theme-dark {
        span {
            color: vars.$white_main;
        }
    }

    &--disabled {
        pointer-events: none;
        .root__input {
            &:checked + .root__label::before {
                background-color: vars.$grey_lines;
                border: 1px solid vars.$grey_lines;
                background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTEiIHZpZXdCb3g9IjAgMCAxNCAxMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxwYXRoIGQ9Ik0xMiAxLjk5OTc2TDUuMzMzNDkgOC45OTk3NkwyIDUuNDk5NjgiIHN0cm9rZT0iI0EwQTBBMCIgc3Ryb2tlLXdpZHRoPSIyLjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPg0KPC9zdmc+DQo=");
            }
        }

        label.root__label {
            &::before {
                background-color: vars.$grey_hover;
            }
        }
    }
}
