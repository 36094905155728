@use "@styles/_variables.scss" as vars;

.modal {
    width: 500px;
}

.content_wrapper {
    padding-bottom: 30px;
}

.input {
    margin-bottom: 14px;
}

.preview_video_wrapper {
    margin-bottom: 20px;
}

.preview_video {
    margin-bottom: 12px;
    height: 252px;
}

.preview_button_wrapper {
    display: flex;
    align-items: center;
    column-gap: 12px;
}

.preview_button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    background: vars.$grey_hover;
    border-radius: 6px;
}

.file_upload_btn {
    & > div {
        flex-direction: column;
        align-items: flex-start;
        row-gap: 6px;
    }

    & div {
        margin-left: 0;
    }
}

.button_wrapper {
    width: 100%;
    display: flex;
    column-gap: 16px;
    justify-content: flex-end;
}
