@use "@styles/variables.scss" as vars;

.container {
    padding: 24px 24px 28px 24px;

    @media (max-width: vars.$break-point-md) {
        padding: 20px 20px 24px 20px;
    }

    @media (max-width: vars.$break-point-sm) {
        padding: 16px 16px 24px 16px;
    }
}

.photo {
    width: 400px;
    //height: 403px;
    border-radius: 10px;
    margin-bottom: 24px;

    @media (max-width: vars.$break-point-sm) {
        width: 100%;
        height: auto;
    }

    img {
        object-fit: cover;
        object-position: center;

        border-radius: 12px;
        height: 100%;
        width: 100%;
        max-width: 100%;
        max-height: 100%;
    }
}

.modal_content {
    max-width: 500px;
}

.button_wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    column-gap: 16px;
    width: 100%;
    margin-top: 32px;

    @media (max-width: vars.$break-point-sm) {
        flex-direction: column;
        row-gap: 12px;
        column-gap: 0px;
    }

    &_end {
        justify-content: flex-end;
    }
}
