.rc-collapse-motion {
	transition: height 0.3s, opacity 0.3s;
}

.rc-collapse-content-hidden {
	display: none;
}

.rc-collapse {
	user-select: none;

	>.rc-collapse-item {
		outline: none;

		&:first-child {
			border-top: none;
		}

		>.rc-collapse-header {
			display: flex;
			cursor: pointer;
			border-bottom: 1px solid #ECEDED;
			outline: none;


			&:last-child {
				margin-bottom: 0px;
			}


			.arrow {
				background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTMuNSAwLjc1TDguNSA1Ljc1TDMuNSAxMC43NSIgc3Ryb2tlPSIjQTBBMEEwIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=);
				width: 12px;
				height: 12px;
			}

			.rc-collapse-extra {
				//margin: 0 16px 0 auto;
			}
		}

		.rc-collapse-header-collapsible-only {
			cursor: default;

			.rc-collapse-header-text {
				cursor: pointer;
			}
		}
	}

	>.rc-collapse-item-disabled {
		>.rc-collapse-header {
			cursor: not-allowed;
			color: #999;
		}
	}

	>.rc-collapse-item-active {
		>.rc-collapse-header {
			.arrow {
				transform: rotate(90deg);
			}

			border: none;
		}
	}
}

.rc-collapse-expand-icon {
	display: flex;
	align-items: center;
	margin-right: 10px;
}

.rc-collapse-content {

	>.rc-collapse-content-box {
		margin-top: 16px;
		margin-bottom: 16px;
	}
}

.rc-collapse-item {
	&:last-child {}
}

.rc-collapse-header-text {
	font-family: "Mulish";
	font-size: 16px;
	font-weight: 800;
	line-height: 22px;
	letter-spacing: 0em;

	padding: 11px 0px;
}

.rc-collapse-content-box {
	.rc-collapse {
		margin-left: 32px;

		.rc-collapse-header-text {
			font-family: Mulish;
			font-size: 15px;
			font-weight: 500;
			line-height: 21px;
			letter-spacing: 0em;
			text-align: left;
		}

		.rc-collapse-item-active {

			.rc-collapse-header {
				border: none;
			}
		}
	}
}