@use '@styles/_variables.scss' as vars;

.container {
    display: flex;
}

.cursor {
    cursor: pointer;
}

.popup {
    opacity: 1;
    transition: opacity 0.15s ease-in;
    z-index: vars.$hightest_index;
}

.isShow {
    opacity: 0;
    pointer-events: none;
}
