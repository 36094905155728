@use "@styles/_variables.scss" as vars;

.container {
    display: flex;
    margin-top: 32px;
    flex-direction: column;
    width: 100%;
    padding-bottom: 22px;

    @media (max-width: vars.$break-point-lg) {
        margin-top: 28px;
    }

    @media (max-width: vars.$break-point-sm) {
        margin-top: 14px;
    }
}

.heading {
    margin-bottom: 20px;
}

.tabList {
    display: flex;
    margin-bottom: 32px;
    column-gap: 16px;

    @media (max-width: vars.$break-point-lg) {
        margin-bottom: 24px;
    }

    @media (max-width: vars.$break-point-md) {
        column-gap: 8px;
    }

    @media (max-width: vars.$break-point-sm) {
        margin: 0 -16px 26px;
        padding: 0 16px;
        width: calc(100% + 16px * 2);
        max-width: calc(100% + 16px * 2);
        overflow-x: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none; // Safari and Chrome
            background-color: transparent;
        }
    }
}

.content {
    h2 {
        @media (max-width: vars.$break-point-lg) {
            font-size: 1.8rem;
            line-height: 2.4rem;
            font-weight: 700;
        }
    }
}

.date {
    margin-top: 12px;
    display: flex;
}

.icon {
    margin-right: 8px;
}

.caption {
    display: flex;
}

.tab {
    display: flex;
    color: white;
    white-space: nowrap;
    padding: 9.5px 16px 11.5px;

    @include vars.font_text14();
    color: vars.$white_main;

    background-color: vars.$grey_hover;
    color: vars.$black_main;
    border-radius: 8px;
    cursor: pointer;

    border: none !important; // Vlad privet, because lib.
    outline: none;
    transition: background-color 0.2s ease-in;

    @media (max-width: vars.$break-point-lg) {
        padding: 6px 16px 8px;
    }

    &[class*="react-tabs__tab--selected"] {
        background-color: vars.$black_main;
        color: vars.$white_main;

        border: none;

        &:hover {
            background-color: vars.$black_main;
        }
    }

    &:hover {
        background-color: vars.$tab_hover;
    }
}

.panelContent {
    display: flex;
    margin-top: 24px;
    flex-wrap: wrap;
    column-gap: 32px;
    row-gap: 36px;

    @media (max-width: vars.$break-point-md) {
        column-gap: 16px;
        row-gap: 32px;
    }

    @media (max-width: vars.$break-point-sm) {
        margin-top: 20px;
        row-gap: 28px;
    }
}
