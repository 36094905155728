@import "@styles/_variables.scss";

.row {
    grid-gap: 3%;
    padding: 12px;
    border-top: 1px solid $grey_lines;
    min-height: 48px;
    height: fit-content;
    max-height: 64px;
    cursor: pointer;
    position: relative;

    &:hover {
        background-color: $grey_hover;
    }
}

.column {
    width:100%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.menu {
    position: absolute;
    right: 12px;
    top: 9px;
}
