@use "@styles/_variables.scss" as vars;

.backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: vars.$hight_index;
    
    background-color: rgba(51, 51, 51, 0.3);
    
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    
    padding: 24px;
}

.content {
    margin: auto;
    position: relative;
    min-width: 500px;
    padding: 24px;
    background: vars.$white_main;
    box-shadow: 0 12px 32px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    z-index: vars.$hightest_index;


    @media (max-width: vars.$break-point-sm) {
        min-width: initial;
        width: 90%;
    }

    &__title {
        margin-bottom: 16px;
        padding-right: 30px;
        word-break: break-word;
    }

    &__wrapper {
        width: 100%;
    }
}

.close_button {
    position: absolute;
    top: 16px;
    right: 16px;
    transition: stroke 0.2s ease-in;

    &:hover {
        svg {
            path {
                stroke: vars.$black_main;
            }
        }
    }
}

//backdrop
.modal_enter .backdrop {
    background-color: rgba(51, 51, 51, 0.3);
}

.modal_enter_active .backdrop {
    background-color: rgba(51, 51, 51, 0);
    transition: all 0.2s;
}

.modal_exit .backdrop {
    background-color: rgba(51, 51, 51, 0);
}

.modal_exit_active .backdrop {
    background-color: rgba(51, 51, 51, 0.3);
    transition: all 0.2s;
}

// modal_enter
.modal_enter .content {
    opacity: 0;
    transform: translateY(100px);
}

.modal_enter_active .content {
    opacity: 1;
    transform: translateY(0px);
    transition: all 0.2s;
}

.modal_exit .content {
    opacity: 1;
    transform: translateY(0px);
}

.modal_exit_active .content {
    opacity: 0;
    transform: translateY(-100px);
    transition: all 0.2s;
}
