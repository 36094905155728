@use "@styles/_variables.scss" as vars;

.container {
    display: flex;
    column-gap: 12px;

    border-radius: 24px;
    background-color: vars.$grey_hover;
    align-items: flex-end;

    margin: 0px 16px 16px 16px;
    padding: 6px 8px 6px 16px;
    box-sizing: border-box;
}

.textArea-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    overflow: hidden;
    padding: 4px 0;
}
