.container {
    display: flex;
    position: relative;
    width: 100%;
    padding: 6px 0px
}

.value {
    display: flex;
    width: 100%;
    column-gap: 12px;
}

.control {
    width: 50px;
    height: 30px;
}

.content {
    display: flex;
    align-items: center;
}

.input {
    display: flex;
    align-items: center;
    column-gap: 12px;
}

.editable {
    padding: 6px 0px;
}

.btn {
    position: relative;
}

.btn_controls,
.btn_scores {
    display: flex;
}

.actions {
    display: flex;
    column-gap: 12px;
    align-items: center;
}

.icon {
    cursor: pointer;
}

.btn_edit {
    margin-right: 8px;
}