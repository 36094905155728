@use '@styles/_variables.scss' as vars;

.root {
    display: flex;
    align-items: center;
    padding: 8px 12px;
    color: vars.$white_main;
    background: rgba(34, 38, 38, 0.85);
    border-radius: 8px;
    z-index: vars.$hightest_index;
}
