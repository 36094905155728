@use '_variables.scss' as vars;

* {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html {
    /* 10px */
    font-size: 10px;
    line-height: 1;
}

body {
    font-family: vars.$baseFont;
    @include vars.font_text14;
    color: vars.$black_main;
}

button {
    font-family: vars.$baseFont;
}

h1 {
    @include vars.font_h1;
}

h2 {
    @include vars.font_h2;
}

h3 {
    @include vars.font_h3;
}

p,
span,
a {
    @include vars.font_text14;
}

a {
    color: vars.$black_main;
}

a.link, span.link {
    color: vars.$supa_green;
    cursor: pointer;

    &:hover {
        color: vars.$green_main_hover;
    }
}

// hover tableRow
.row--selectable {
    &:hover {
        background-color: vars.$grey_hover;

        .table-menu {
            opacity: 1;
        }
    }
}

.table-menu {
    opacity: 0;
    background-color: transparent;

    &:hover {
        .tbmenu {
            box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
        }
    }
}

button.table-menu {
    padding: 8px 14px 10px;
    border-radius: 5px;
}

.portal-container {
    position: relative;
    z-index: 1200;
}
