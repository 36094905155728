@use '@styles/variables.scss' as vars;

.title {
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 2.1rem;
    margin-bottom: 6px;
}

.wrapper_block {
    display: flex;
    flex-direction: column;
}

.tel_wrapper {
    margin-bottom: 14px;
}

.tel {
    font-weight: 700;
    font-size: 1.8rem;
    line-height: 2.4rem;
}

.extension {
    font-weight: 700;
    font-size: 1.4rem;
}

.work_schedule {
    display: block;
    font-weight: 500;
    font-size: 1.3rem;
    line-height: 1.8rem;
    color: vars.$grey_text;

    @media (max-width: vars.$break-point-sm) {
        margin-top: 2px;
    }
}

.mail {
    color: vars.$green_main;
}