@use "@styles/_variables.scss" as vars;

.main {
    padding-top: 32px;
    padding-left: 0;
    padding-right: 0;

    @media (max-width: vars.$break-point-lg) {
        padding-top: 28px;
    }

    @media (max-width: vars.$break-point-sm) {
        padding-top: 24px;
    }
}

.back_link {
    margin-bottom: 12px;
}

.grid_container {
    margin-top: 12px;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 32px;

    &__column_left {
        grid-column-end: span 3;
    }

    &__column_right {
        grid-column-end: span 1;
    }

    @media (max-width: vars.$break-point-lg) {
        grid-template-columns: repeat(3, 1fr);
        margin-top: 16px;

        &__column_left {
            grid-column-end: span 2;
        }
    }

    @media (max-width: vars.$break-point-md) {
        grid-template-columns: repeat(4, 1fr);

        &__column_left {
            grid-column-end: span 4;
        }

        &__column_right {
            grid-column-end: span 4;
        }
    }

    @media (max-width: vars.$break-point-sm) {
        margin-top: 4px;
        row-gap: 20px;
    }
}

.lecture_title {
    @media (max-width: vars.$break-point-md) {
        font-weight: 800;
        font-size: 20px;
        line-height: 26px;
    }

    @media (max-width: vars.$break-point-sm) {
        margin-bottom: 24px;
    }
}

.video_info {
    display: flex;
    flex-direction: column;
    align-items: start;
    row-gap: 20px;
    max-width: 296px;
    hyphens: auto;

    @media (max-width: vars.$break-point-md) {
        max-width: 100%;
    }

    @media (max-width: vars.$break-point-sm) {
        row-gap: 24px;
    }
}

.video_info_list {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    max-width: 100%;

    &__item {
        display: flex;
        align-items: flex-start;
        column-gap: 10px;

        &--center {
            align-items: center;
        }
    }

    &__teachers_span {
        display: block;
        margin-bottom: 5px;
    }
}

.video_info_list_item {
    &__text {
        @include vars.font_text13;
    }
}

.file_list {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    max-width: 100%;
}

.button {
    @include vars.font_text15;
}
