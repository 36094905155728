@use '@styles/_variables.scss' as vars;

.container {
    width: 100%;
    max-width: 1034px;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    background-color: vars.$white_main;
    padding: 24px;
}

.row-name {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 2px;
}

.checkbox {
    width: unset;
    margin-right: 10px;
}

.avatar {
    margin-right: 10px;
}

.name {
    @include vars.font_text15_semi_bold;
}

.content {
    .row {
        display: grid;
        padding-top: 10px;
        align-items: center;
        grid-template-columns: auto 70px 176px 52px;
        column-gap: 20px;

        &:not(.last) {
            border-bottom: 1px solid vars.$grey;
            padding-bottom: 10px;
        }
    }

    .title {
        @include vars.font_text14;
        font-weight: 500;
    }

    .info {
        @include vars.font_text14;
        font-weight: 500;
        color: vars.$black_grey;
    }

    .controls {
        display: flex;

        .edit {
            margin-right: 12px;
        }
    }
}
