@use '@styles/_variables.scss' as vars;

.data_list {
    &__header {
        padding: 12px;
        padding-top: 0;
    }
}

.list_item {
    display: flex;
    align-items: center;
    column-gap: 4px;
    padding: 12px;
    border-top: 1px solid vars.$grey_lines;

    &:first-child {
        border-top: none;
    }

    &:last-child {
        border-bottom: none;
    }

    @media (max-width: vars.$break-point-lg) {
        padding: 12px 0px;
    }

    @media (max-width: vars.$break-point-sm) {
        &:last-child {
            border-bottom: none;
        }
    }

    &__grid {
        grid-template-columns: 89% 9%;

        @media (max-width: vars.$break-point-lg) {
            grid-template-columns: 83% 15%;
        }

        @media (max-width: vars.$break-point-md) {
            grid-template-columns: 72% 26%;
            row-gap: 2px;
        }

        @media (max-width: vars.$break-point-sm) {
            display: block;
        }
    }

    &__header {
        padding: 8px 12px 9px;
        background-color: vars.$light_green;
    }

    &__title_task {
        @include vars.font_text10;
        color: vars.$grey_text;
        text-transform: uppercase;
    }

    &__work_count {
        color: vars.$grey_text;
    }

    [class~="column"] {
        display: flex;

        &.flex_end {
            @media (max-width: vars.$break-point-sm) {
                display: block;
            }
        }
    }
}

.deadline_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 10px;
}

.empty_message {
    margin: 120px auto 0px auto;
    text-align: center;

    & > * {
        margin: 0px auto 12px auto;
    }

    & > *:last-child {
        margin-bottom: 0px;
    }

    &_header {
        @include vars.font_text18;
    }

    &_text {
        @include vars.font_text14;
    }
}

.list_wrapper {
    width: 100%;
}

.flex_end {
    display: flex;
    justify-content: flex-end;

    @media (max-width: vars.$break-point-md) {
        justify-content: flex-end;
    }
}

.link {
    &:hover {
        text-decoration: underline;
    }

    @media (max-width: vars.$break-point-sm) {
        margin-bottom: 4px;
        display: block;
    }
}

.column_label {
    color: vars.$grey_text;
}
