.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    width: 100%;
}

.loader {
    width: 50px;
    height: 50px;
}

.loader > svg {
    -webkit-animation: rotate-animation 1s linear infinite;
    -moz-animation: rotate-animation 1s linear infinite;
        -o-animation: rotate-animation 1s linear infinite;
            animation: rotate-animation 1s linear infinite;
}

/*
* Animation
*/
@-webkit-keyframes rotate-animation {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    50% {
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
@-moz-keyframes rotate-animation {
    0% {
        -moz-transform: rotate(0deg);
            transform: rotate(0deg);
    }
    50% {
        -moz-transform: rotate(180deg);
            transform: rotate(180deg);
    }
    100% {
        -moz-transform: rotate(360deg);
            transform: rotate(360deg);
    }
}
@-o-keyframes rotate-animation {
    0% {
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    50% {
        -o-transform: rotate(180deg);
        transform: rotate(180deg);
    }
    100% {
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes rotate-animation {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
            -o-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    50% {
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
            -o-transform: rotate(180deg);
                transform: rotate(180deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
            -o-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}