@use "@styles/_variables.scss" as vars;

.video_card {
    position: relative;
    grid-column: span 1;
    display: flex;
    align-self: start;
    flex-direction: column;

    &:hover .video_card__wrapper_btn {
        display: flex;
    }

    & > p {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        word-break: break-word;
        margin-top: 8px;
    }

    &__files {
        margin-top: 8px;
    }

    &__wrapper_btn {
        position: absolute;
        bottom: 0;
        right: 0;
        display: none;
        align-items: center;
        column-gap: 12px;
    }

    &__date {
        font-size: 1.2rem;
        color: vars.$grey_text;
        margin-top: 6px;
    }
}
