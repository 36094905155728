@use '@styles/_variables.scss' as vars;

.container {
    width: 1034px;
}

.actions {
    display: flex;
    margin-top: 32px;
    column-gap: 16px;
}