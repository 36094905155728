@use '@styles/_variables.scss' as vars;

.title {
    margin-bottom: 16px;
}

.create_section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 16px;

    &__title {
        margin-bottom: 12px;
    }

    &__text {
        width: 195px;
        text-align: center;
        margin-bottom: 24px;
    }
}

.background_img {
    width: 264px;
    height: 231px;
    background: url("/assets/image/create-section-svg.svg") no-repeat center;
}

.table_container {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.row {
    display: flex;
}

.table_control {
    margin-right: 12px;

    & > div {
        height: 34px;
    }

    &:last-child {
        margin-right: 0px;
    }
}

.delete_bucket {
    display: flex;
    position: relative;
    top: 2px;
    cursor: pointer;
}

.steps {
    max-width: 452px;
}

.step_container {
    display: -webkit-inline-flex;

    &:not(:last-child) {
        margin-bottom: 28px;
    }
}

.step_number {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    border-radius: 50%;
    width: 36px;
    height: 36px;

    background-color: vars.$grey_hover;

    &:not(last-child) {
        margin-right: 16px;
    }
}

.step_content {
    p {
        &:not(:last-child) {
            margin-bottom: 8px;
        }
    }

    a {
        color: vars.$green_main;
    }
}

.input_wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    margin-bottom: 32px;
}

.button_wrapper {
    width: 100%;
    display: flex;
    column-gap: 16px;
    justify-content: flex-end;
}

.csv-preview {
    display: flex;
    align-items: center;
    height: 100%;
    white-space: pre;

    & p {
        display: inline;
    }
}
