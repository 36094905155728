.container {
    display: flex;
    align-items: center;
    flex-grow: 0;
    flex-shrink: 0;
}

.control {
    margin-right: 12px;
    display: flex;
    cursor: pointer;

    &:last-child {
        margin-right: 0px;
    }
}
