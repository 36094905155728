@use "@styles/_variables.scss" as vars;

.tabs__container {
    margin-top: 20px;

    & > ul.tabs {
        margin-bottom: 30px;
    }

    & > ul {
        display: flex;

        padding-bottom: 2px;
        padding-left: var(--page-padding);
        padding-right: var(--page-padding);
        @include vars.horizontal_scroll;
        overflow-x: auto;
        border-bottom: 1px solid vars.$grey_lines;

        @media (max-width: vars.$break-point-lg) {
            margin-left: calc(var(--page-padding) * -1);
            margin-right: calc(var(--page-padding) * -1);
            width: calc(100% + var(--page-padding) * 2);
            max-width: calc(100% + var(--page-padding) * 2);
            --page-padding: 0;
        }

        @media (max-width: vars.$break-point-sm) {
            --page-padding: 16px;
        }

        & li > div {
            white-space: nowrap;
        }
    }

    &--student {
        & > ul {
            @media (min-width: vars.$break-point-lg) {
                border-bottom: none;
            }
        }
    }
}

.container {
    position: relative;
    width: 100%;
}

.form-control {
    position: absolute;
    top: 0;
    right: 0;
    color: vars.$green_main;
    cursor: pointer;
    height: 30px;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
}
