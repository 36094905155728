@use "@styles/variables.scss" as vars;
@use "@styles/mixins/typo" as mixins;

.root {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    box-sizing: border-box;
    max-width: 100%;

    &__disabled {
        cursor: default !important;
    }

    &__inner {
        height: 42px;
        padding: 0px 16px;
        display: flex;
        align-items: center;
        max-width: 100%;
        overflow: hidden;

        @media (max-width: vars.$break-point-sm) {
            max-width: unset;
        }
    }

    &__container {
        display: flex;
        align-items: center;
        max-width: 100%;
    }

    &__wrapper {
        display: flex;
        border: 1px solid vars.$grey_elements;
        border-radius: 8px;
        cursor: pointer;
        max-width: 100%;
        overflow: hidden;

        &:not(.root__disabled):hover {
            border: 1px solid vars.$grey_text;

            .root__delete {
                border-left: 1px solid vars.$grey_text;
            }
        }

        &:not(:last-child) {
            margin-right: 16px;
        }
    }

    &__default-icon {
        display: block;
        margin-right: 8px;
    }

    &__label {
        color: vars.$green_main;
    }

    &__hide-input {
        display: none;
    }

    &__delete {
        width: 42px;
        height: 42px;
        min-width: 42px;
        display: none;
        border-left: 1px solid vars.$grey_elements;

        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        align-items: center;
        justify-content: center;
    }

    &__caption {
        margin-left: 16px;
        font-weight: 400;
        @include vars.font_text13;
        margin-right: 10px;
        color: vars.$grey_text;

        span {
            @include vars.font_text13;
        }
    }

    &--select {
        border: 1px solid vars.$grey_text;
    }

    &--file {
        .root__inner {
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
            border-right: none;
        }

        .root__delete {
            display: flex;
        }
    }
}
