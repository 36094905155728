@use '@styles/_variables.scss' as vars;

.root {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 60px;
    padding: 16px 10px;
    background-color: vars.$green_main;
}

.nav__list {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 16px;
}

.nav__item {
    position: relative;

    &:hover .nav__tooltip {
        opacity: 1;
        visibility: visible;
    }
}

.nav__link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 8px;
    color: vars.$white_main;

    &:hover {
        background-color: rgba(vars.$green_dark, 0.5);
    }

    &--active {
        background-color: vars.$green_dark;
        color: vars.$yellow;

        &:hover {
            background-color: vars.$green_dark;
            cursor: default;
        }
    }
}

.nav__tooltip {
    position: absolute;
    left: 59px;
    top: 0;
    white-space: nowrap;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s ease-out;
    overflow: hidden;
}
