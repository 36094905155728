@use "@styles/_variables.scss" as vars;

.question {
    padding: 24px;
    border: 1px solid vars.$grey_lines;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.06);
    border-radius: 16px;
    overflow-wrap: break-word;
    position: relative;

    scroll-margin-top: 250px;

    @media (max-width: vars.$break-point-md) {
        padding: 20px;
    }

    @media (max-width: vars.$break-point-md) {
        padding: 16px;
    }

    &:not(:last-child) {
        margin-bottom: 24px;

        @media (max-width: vars.$break-point-lg) {
            margin-bottom: 16px;
        }
    }

    &_header {
        @include vars.font_text15_bold;
        margin-bottom: 16px;

        @media (max-width: vars.$break-point-lg) {
            margin-bottom: 8px;
        }
    }

    &_description {
        margin-bottom: 24px;

        @media (max-width: vars.$break-point-lg) {
            margin-bottom: 20px;
        }

        @media (max-width: vars.$break-point-sm) {
            margin-bottom: 16px;
        }

        p:last-child {
            margin-bottom: 0px;
        }
    }

    &_icon {
        position: absolute;
        top: 25px;
        right: 24px;

        @media (max-width: vars.$break-point-md) {
            top: 17px;
            right: 16px;
        }

        @media (max-width: vars.$break-point-sm) {
            top: 13px;
            right: 12px;
        }
    }

    .question_icon {
        cursor: pointer;
    }

    &_active {
        .question_icon {
            path {
                stroke: vars.$red_attention;
            }
        }
    }
}

.answers {
    &_header {
        color: vars.$outline_hover;
        margin-bottom: 16px;
    }

    &_wrapper {
        width: fit-content;

        @media (max-width: vars.$break-point-sm) {
            width: 100%;
        }
    }
}

.button_wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    column-gap: 16px;
    width: 100%;
    margin-top: 32px;
}

.csv-preview {
    display: flex;
    align-items: center;
    height: 100%;
    white-space: pre-wrap;

    & p {
        display: inline;
    }
}

.answers {
    &_input {
        justify-content: space-between;
        &:not(:last-child) {
            margin-bottom: 16px;
        }
    }

    &_select_wrapper {
        display: flex;
        align-items: center;

        @media (max-width: vars.$break-point-sm) {
            flex-direction: column;
            align-items: flex-start;
        }

        p {
            margin: 0px 16px 0px 0px;

            @media (max-width: vars.$break-point-sm) {
                margin: 0px 0px 4px 0px;
            }
        }
    }

    &_select {
        width: 280px;

        @media (max-width: vars.$break-point-sm) {
            width: 100%;
        }
    }
}
