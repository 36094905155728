@use "@styles/_variables.scss" as vars;

.main {
    padding: 26px 0 72px 24px;

    @media (max-width: vars.$break-point-lg) {
        padding: 28px 0 68px;
    }
}

.progress_bar_container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    height: 60px;

    @media (max-width: vars.$break-point-lg) {
        margin-bottom: 12px;
        height: fit-content;
    }

    a {
        margin-bottom: 0;
    }
}
