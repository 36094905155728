@use '@styles/_variables.scss' as vars;

.root {
    padding: 32px 0;

    &:last-child {
        padding-bottom: 0;
    }

    &:first-child {
        padding-top: 0;
    }

    @media (max-width: vars.$break-point-lg) {
        padding: 0;
    }

    @media (max-width: vars.$break-point-md) {
        margin: 0 -40px;
        width: calc(100% + 40px * 2);
        max-width: calc(100% + 40px * 2);
        overflow-x: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none; // Safari and Chrome
            background-color: transparent;
        }
    }

    @media (max-width: vars.$break-point-sm) {
        margin: 0 -16px;
        width: calc(100% + 16px * 2);
        max-width: calc(100% + 16px * 2);
    }
}

.container {
    display: flex;
    width: fit-content;
    column-gap: 16px;
    padding: 0 40px;

    @media (max-width: vars.$break-point-sm) {
        padding: 0 16px;
    }
}
