@use "@styles/variables.scss" as vars;

.switcher {
    display: flex;
    align-items: center;
    @include vars.font_text14;

    &:hover {
        color: vars.$green_main;
    }
}

.arrow {
    margin-left: 8px;
    transition: transform 0.3s ease;

    path {
        color: vars.$black_main;
    }

    &--active {
        transform: rotate(-180deg);

        path {
            color: vars.$grey_text;
        }
    }
}
