@use '@styles/_variables.scss' as vars;

.modal {
    max-width: 700px;
    width: 100%;
}

.description {
    margin-top: 8px;
    margin-bottom: 20px;
    @include vars.font_text15_semi_bold;
}

.control {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
