@use "@styles/_variables.scss" as vars;

.root {
    width: 100%;
    display: flex;
    flex-direction: column;
}

[class~="react-tabs__tab"] {
    @include vars.font_text15;
    display: flex;
    justify-content: flex-start;
    cursor: pointer;
    position: relative;

    outline: none;
    box-sizing: border-box;
    margin-right: 30px;
    padding-bottom: 16px;
    flex-shrink: 0;
    top: 1px;

    &:last-child {
        margin-right: 0;
    }

    [class~="tab_items_count"] {
        border: 1px solid vars.$outline_borders;
        color: vars.$grey_text;
        align-items: center;

        height: 22px;
    }

    @media (max-width: vars.$break-point-lg) {
        @include vars.font_text14;
        margin-right: 20px;
    }
}

[class~="react-tabs__tab--selected"] {
    color: vars.$green_main;
    border-bottom: 2px solid vars.$green_main;

    [class~="tab_items_count"] {
        border: 1px solid vars.$green_05;
        color: vars.$green_05;

        align-items: center;
        height: 22px;
    }
}

.react-tabs--grey {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    [class~="react-tabs__tab-list"] {
        column-gap: 16px;
        border-bottom: none;
        margin-bottom: 32px;

        @media (max-width: vars.$break-point-lg) {
            margin-bottom: 24px;
        }

        @media (max-width: vars.$break-point-md) {
            column-gap: 8px;
        }

        @media (max-width: vars.$break-point-sm) {
            margin-bottom: 20px;
        }

        [class~="react-tabs__tab"] {
            margin-right: 0;
            padding: 7px 16px 9px;
            background: vars.$grey_hover;
            border-radius: 8px;
            font-size: 1.5rem;
            line-height: 2.2rem;
            color: vars.$black_main;
            width: fit-content;

            @media (max-width: vars.$break-point-lg) {
                padding: 6px 16px 8px;
                @include vars.font_text14;
            }

            [class~="react-tabs__tab-panel"] {
                height: 100%;
            }
        }

        [class~="react-tabs__tab--selected"] {
            background: vars.$black_main;
            color: vars.$white_main;
            border-bottom: none;
        }
    }
}

.tabs_content {
    display: flex;

    @media (max-width: vars.$break-point-lg) {
        height: 20px;
    }
}