@use "@styles/variables.scss" as vars;

.container {
    padding: 24px 24px 28px 24px;

    @media (max-width: vars.$break-point-md) {
        padding: 20px 20px 24px 20px;
    }

    @media (max-width: vars.$break-point-sm) {
        padding: 16px 16px 24px 16px;
    }
}

.title {
    @include vars.font_text19();
    margin-bottom: 20px;
}

.example {
    display: flex;
    margin-bottom: 24px;
    img {
        height: 145px;
        border-radius: 10px;
    }
}

.description {
    display: flex;
    margin-bottom: 16px;
}

.button_wrapper {
    width: 240px;

    @media (max-width: vars.$break-point-sm) {
        width: 100%;
    }
}

//Camera

.isCamera {
    border: none;
    background-color: vars.$grey_hover;
    position: fixed;
    z-index: vars.$hightest_index;
    top: 0;
    right: 0;
    overflow: hidden;
    margin: 0px;
    width: 100vw;
    height: 100%;

    .innerCamera {
        width: 100%;
        height: 100%;
        position: relative;
        left: 0;
    }
}

.loader {
    position: absolute;
    z-index: vars.$default_index;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.cameraUI {
    display: flex;
    position: absolute;
    bottom: 48px;
    left: 50%;
    transform: translateX(-50%);

    @media (max-width: vars.$break-point-sm) {
        position: fixed;
        width: 100%;
        padding: 0px 16px;
        bottom: 32px;
    }
}

.camera {
    &_btns_wrapper {
        display: flex;
        column-gap: 20px;

        @media (max-width: vars.$break-point-sm) {
            row-gap: 20px;
            flex-direction: column;
            width: 100%;
        }
    }

    &_btn {
        width: 320px;

        @media (max-width: vars.$break-point-sm) {
            width: 100%;
        }
    }
}

.screenShoot {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
    }
}

video {
    object-fit: contain;
    object-position: center;
    background-color: vars.$black;
}
