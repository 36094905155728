@use "@styles/_variables.scss" as vars;


.container {
    display: flex;
    box-shadow: none;
    flex-direction: column;
    border-radius: 10px;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
    background-color: vars.$white_main;

    z-index: vars.$hight_index;

    padding: 8px 0px;
}