@use '@styles/_variables.scss' as vars;

.content_wrapper {
    width: 100%;
    display: flex;
    margin-top: 16px;
    border-top: 1px solid vars.$grey_lines;
}

.title_wrapper {
    margin-bottom: 12px;
}

.title {
    margin-bottom: 14px;
}

.lecture_title_wrapper {
    display: flex;
    align-items: center;
    column-gap: 12px;
    margin-bottom: 26px;

    word-break: break-all;
}

.button_wrapper {
    display: flex;
    align-items: center;
    column-gap: 16px;
    margin-bottom: 22px;

    justify-content: space-between;
}

.sidebar {
    &>div {
        height: calc(100vh - 136px);
    }
}

.inner {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: stretch;
    padding-bottom: 24px;
}

.scrolled {
    overflow: scroll;

    padding-right: 10px;

    &::-webkit-scrollbar {
        width: 4px;
        height: 100%;
        background-color: white;
        border: solid 3px transparent;
    }

    &::-webkit-scrollbar-track {
        border: solid 3px transparent;
    }

    &::-webkit-scrollbar-thumb {
        width: 3px;
        border-radius: 5px;
        background-color: vars.$grey_elements;
    }
}

.main {
    padding-bottom: 64px;

    .grid {
        grid-auto-rows: unset;
    }

    .form {
        height: calc(100% - 42px - 24px - 22px - 26px);
    }
}
