@use '@styles/_variables.scss' as vars;

.wrapper {
    width: calc(100% - 68px);
    box-sizing: border-box;
}

.input_wrapper {
    position: relative;
    max-width: fit-content;

    &:hover .edit_button {
        opacity: 1;
    }
}

.title_wrapper {
    width: calc(100% - 70px);
    display: flex;
    column-gap: 10px;
    align-items: center;
    box-sizing: border-box;
}

.title_wrapper,
.input_wrapper {
    position: relative;

    h2 {
        word-break: break-all;
    }


    &:hover .edit_button {
        opacity: 1;
    }
}

.edit_button {
    width: 20px;
    height: 20px;
    opacity: 0;
    transition: opacity 300ms ease;
}