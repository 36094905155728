@use '@styles/_variables.scss' as vars;

.list {
    display: flex;
    flex-direction: column;
    row-gap: 24px;

    &:not(:last-child) {
        margin-top: 32px;
    }

    @media (max-width: vars.$break-point-lg) {
        row-gap: 32px;
    }
}
