@use "@styles/_variables.scss" as vars;

.main {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
}

.session {
    &:not(:last-child) {
        margin-bottom: 40px;

        @media (max-width: vars.$break-point-sm) {
            margin-bottom: 24px;
        }
    }

    &_name {
        margin-bottom: 20px;

        @media (max-width: vars.$break-point-lg) {
            @include vars.font_h3;
        }

        @media (max-width: vars.$break-point-sm) {
            margin-bottom: 16px;
        }
    }
}

.column {
    min-width: 0px;
}
