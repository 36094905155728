@use "@styles/_variables.scss" as vars;

.content {
    width: 100%;
}

.title_wrapper {
    display: flex;
    align-items: center;
    column-gap: 12px;
    margin-bottom: 24px;
}

.tabs_root {
    width: 100%;
}

.paper {
    width: 100%;
    margin-bottom: 32px;
}

.react-tabs--grey {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    [class~="react-tabs__tab-list"] {
        column-gap: 12px;
        border-bottom: none;
        display: flex;

        [class~="react-tabs__tab"] {
            margin-right: 0;
            padding: 7px 16px 9px;
            background: vars.$grey_hover;
            border-radius: 8px;
            font-size: 1.5rem;
            line-height: 2.2rem;
            color: vars.$black_main;
            width: fit-content;

            [class~="react-tabs__tab-panel"] {
                height: 100%;
            }
        }

        [class~="react-tabs__tab--selected"] {
            background: vars.$black_main;
            color: vars.$white_main;
            border-bottom: none;
        }
    }
}

.attempt {
    &_info {
        display: flex;
        justify-content: space-between;
    }

    &_grade_wrapper {
        display: flex;
    }

    &_best {
        padding: 2px 8px 3px;
        margin-right: 10px;
        height: fit-content;

        border-radius: 25px;
        border: 1px solid vars.$green_success_05;

        text-transform: uppercase;
        color: vars.$green_success;
        @include vars.font_text9;

        @media (max-width: vars.$break-point-sm) {
            margin-right: 0;
        }
    }

    &_grade {
        @include vars.font_text18();
    }
}

.attempt-wrapper {
    display: flex;
}

.sticky_menu {
    height: fit-content;
    position: sticky;
    right: 0px;
    top: 89px;
}
