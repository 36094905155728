@use '@styles/_variables.scss' as vars;

.content {
    display: flex;
    flex-direction: column;
    row-gap: 26px;
    padding: 24px 24px 28px;
    background: vars.$grey_hover;
    border-radius: 16px;

    @media (max-width: vars.$break-point-md) {
        padding: 20px 20px 24px;
    }

    @media (max-width: vars.$break-point-sm) {
        padding: 16px 16px 20px;
    }

    & a {
        padding: 0;
        border-radius: 0;
        border: none;
        background-color: transparent;
        width: 100%;

        & > p {
            word-break: unset;
        }
    }
}

.info {
    display: flex;
    align-items: center;
    column-gap: 12px;
    padding: 14px 20px 16px;
    background: vars.$white_main;
    border-radius: 10px;

    @media (max-width: vars.$break-point-md) {
        padding: 14px 16px 16px;

        & p {
            font-size: 1.2rem;
            line-height: 1.6rem;
        }
    }

    @media (max-width: vars.$break-point-sm) {
        column-gap: 10px;
        align-items: flex-start;
    }

    &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 36px;
        height: 36px;
        background-color: vars.$green_main;
        border-radius: 50%;

        & > svg {
            width: 20px;
            height: 20px;
            margin-top: -2px;
        }

        @media (max-width: vars.$break-point-md) {
            width: auto;
            height: auto;
            background-color: transparent;
            border-radius: 0;

            & > svg {

                & path {
                    stroke: vars.$green_main
                }

                & rect {
                    fill: vars.$green_main
                }
            }
        }

        @media (max-width: vars.$break-point-sm) {
            & > svg {
                margin-top: 2px;
            }
        }
    }
}
