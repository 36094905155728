@use "@styles/_variables.scss" as vars;

.emptyContainer {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    white-space: pre-wrap;
    margin-top: 88px;

    @media (max-width: vars.$break-point-sm) {
        margin-top: 48px;
    }
}

.picture {
    margin-bottom: 12px;
}

.header {
    width: 266px;
    margin-bottom: 12px;
    text-align: center;
    font-size: 1.8rem;
    font-weight: 700;
}

.text {
    width: 245px;
    text-align: center;
    font-size: 1.4rem;
}
