@use "@styles/variables.scss" as vars;
@use "@styles/mixins/typo" as mixins;

.root {
    display: flex;
    max-width: 100%;

    &__default {
        display: flex;
        align-items: center;
        justify-content: center;

        &-icon {
            display: inline-flex;
            margin-right: 10px;
        }

        &-label {
            transition: color 0.1s ease-in;
            color: vars.$green_main;
            //TODO: Replace this shit
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: normal;
        }
    }

    &__uploading {
        display: flex;

        &-icon {
            margin-right: 10px;

            &-btn {
                margin-right: 8px;
                height: 20px;
            }
        }

        &-caption {
            @include mixins.font_text14();
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        &-size {
            color: vars.$grey_text;
            @include mixins.font_text14();
        }

        &-content {
            display: block;
            margin-right: 6px;

            max-width: 100%;
            overflow: hidden;
        }

        &-actions {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            height: 20px;
        }
    }
}