.button {
    position: absolute;
    top: 20px;
    right: 0;
    width: 140px;
    height: 38px;

    & svg {
        width: 20px;
        height: 20px;
    }

    & div {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
