
.inherit-button {
    margin: 24px 0 32px;
    width: 452px;
    padding: 9px 16px 10px;
    height: unset;

    .loader {
        height: 40px;
        width: 40px;
    }
}

.inherit-success {
    display: flex;
    justify-content: center;
    align-items: center;

    span {
        margin-left: 8px;
    }
}

.info {
    font-size: 1.5rem;
    max-width: 452px;
}

.bold {
    font-size: 1.5rem;
    font-weight: bold;
}