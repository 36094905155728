@use "@styles/_variables.scss" as vars;

.root {
    display: flex;
    align-items: center;
    column-gap: 20px;

    @media (max-width: vars.$break-point-lg) {
        column-gap: 14px;
    }
}

.credit_book_link {
    //padding: 7px 16px 9px;
    //background: vars.$grey_hover;
    //border-radius: 8px;
}

.user_avatar_wrapper {
    position: relative;
    display: flex;
    align-items: center;
    column-gap: 12px;
}

.user_info {
    padding-bottom: 10px;
    margin-bottom: 6px;
    border-bottom: 1px solid #ececec;

    &__role {
        color: vars.$grey_text;
        margin-bottom: 2px;
    }

    &__lastname {
        font-size: 1.5rem;
        font-weight: 700;
    }
}

.menu_item {
    padding-top: 6px;
    padding-bottom: 7px;

    &__btn {
        font-size: 1.4rem;
        color: vars.$black_main;
        min-width: auto;

        &:hover {
            color: vars.$grey_text;
        }
    }
}

.btn--active {
    background-color: vars.$light_green;
    color: vars.$green_main;
}
