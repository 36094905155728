@use "@styles/_variables.scss" as vars;

.time {
    @include vars.font_text11();
    color: vars.$grey_text;
    text-align: right;
}

.default {
    color: vars.$grey_text;
}

.own {
    color: rgba(20, 75, 63, 0.5);
}

.photoOwn {
    color: white;
}