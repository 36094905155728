@use '@styles/variables.scss' as vars;

.container {
    display: flex;
    border-radius: 16px;
}

.calendar {
    display: flex;

    .table {
        margin-right: 32px;

        &:last-of-type {
            margin-right: 0px;
        }
    }

    & > *:first-child {
        padding-right: 16px;
        border-right: 1px solid vars.$grey;
    }

    & > *:last-child {
        padding-left: 16px;
        border-right: none;
    }
}

.rangeContainer {
    display: flex;
}

.table {
    display: block;
}

.timePicker {
    display: flex;
}

.setupBtn {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-top: 20px;
    width: 100%;

    a {
        color: vars.$green_main;
    }
}

.paper {
    padding-bottom: 24px;
}
