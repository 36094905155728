@use "@styles/_variables.scss" as vars;

.steps {
    max-width: 452px;
}

.step_container {
    display: -webkit-inline-flex;

    &:not(:last-child) {
        margin-bottom: 28px;
    }
}

.step_number {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    border-radius: 50%;
    width: 36px;
    height: 36px;

    background-color: vars.$grey_hover;

    &:not(last-child) {
        margin-right: 16px;
    }
}

.step_content {
    p {
        &:not(:last-child) {
            margin-bottom: 8px;
        }
    }

    a {
        color: vars.$green_main;
    }
}

.input_wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
}

.button_wrapper {
    width: 100%;
    display: flex;
    column-gap: 16px;
    justify-content: flex-end;
    margin-top: 32px;
}

.modal__text {
    @include vars.font_text14;
    margin-bottom: 32px;
    max-width: 452px;
}

.modal__message {
    display: flex;
    align-items: center;

    &_error {
        margin-bottom: 16px;

        .message__icon {
            background-color: vars.$red_attention;
        }
    }
    &_success {
        .message__icon {
            background-color: vars.$green_success;
            padding: 6px;
        }
    }
}

.message__icon {
    display: flex;

    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-right: 12px;

    padding: 2px 4px;
}

.file_wrapper {
    max-width: 400px;
}
