@use "@styles/_variables.scss" as vars;

.list-container {
    width: 100%;

    @media (min-width: vars.$break-point-lg) {
        margin-top: 0px;
    }

    @media (max-width: vars.$break-point-lg) {
        margin-top: 12px;
    }

    .header {
        grid-gap: 3%;
        padding: 0 12px 12px 12px;

        @media (max-width: vars.$break-point-lg) {
            display: none;
        }

        .cell {
            padding: 0;
            color: vars.$grey_text;
        }
    }

    .row {
        grid-gap: 3%;
        padding: 16px 8px;
        border-bottom: 1px solid vars.$grey_lines;
        min-height: 48px;
        cursor: default;

        &:last-child {
            border-bottom: none;

            @media (min-width: vars.$break-point-lg) {
                padding-bottom: 0px;
                min-height: fit-content;
            }
        }

        @media (max-width: vars.$break-point-lg) {
            padding: 16px 0;
        }

        @media (max-width: vars.$break-point-sm) {
            padding: 12px 0;
        }

        .cell {
            padding: 0;
            display: flex;
            align-items: center;
            height: 100%;

            @media (max-width: vars.$break-point-md) {
                font-size: 1.3rem;
            }

            @media (max-width: vars.$break-point-sm) {
                flex-direction: column;
                align-items: flex-start;
                row-gap: 12px;
            }

            .row-footer {
                display: flex;
                justify-content: space-between;
                width: 100%;
            }

            .bold {
                font-weight: 700;
            }
        }
    }
}

.column {
    &:last-child {
        .cell {
            justify-content: flex-end;
        }
    }
}
