@use '@styles/_variables.scss' as vars;

.title {
    margin-bottom: 16px;
}

.button_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 24px;
}

.create_section {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-top: 16px;

    &__title {
        margin-bottom: 12px;
    }

    &__text {
        width: 195px;
        text-align: center;
        margin-bottom: 24px;
    }
}

.background_img {
    width: 264px;
    height: 231px;
}
