@use '@styles/_variables.scss' as vars;


.container {
    display: flex;
    align-items: center;
}

.content,
.icon {
    display: flex;
}

.content {
    margin-right: 6px;
    color: vars.$grey_text;
}

.icon {
    svg {
        path {
            stroke: vars.$grey_text;
        }
    }
}

.loading_icon {
    svg {
        path {
            stroke: inherit;
        }
    }

    transform-origin: center;
    animation:spin 1s linear infinite;
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}