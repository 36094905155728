@use '@styles/_variables.scss' as vars;

.link_prev {
    margin-bottom: 10px;

    @media (max-width: vars.$break-point-lg) {
        margin-bottom: 12px;
    }

    svg {
        color: vars.$grey_text;
        transition: color 300ms;
    }

    // &:hover svg {
    //     color: vars.$black_main;
    // }
}
