@use '@styles/variables.scss' as vars;

.container {
    width: 700px;
    transition: all 0.2s;

    &--tight {
        width: 500px;
    }
}

.btn {
    display: flex;
}

.chose_mode_container {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
}

.hide {
    opacity: 0;
}
