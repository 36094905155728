@use "@styles/_variables.scss" as vars;

.main {
    padding-top: 32px;
    width: 100%;

    @media (max-width: vars.$break-point-md) {
        padding-top: 28px;
    }
}
