.wrapper {
    display: flex;
    width: 100%;
}

.title_wrapper {
    display: flex;
    align-items: center;
    column-gap: 12px;
    margin-bottom: 26px;
}

.tabs_wrapper {
    width: 100%;
    position: relative;

    &__btn {
        position: absolute;
        top: -13px;
        right: 24px;
        border-radius: 8px;
    }
}
