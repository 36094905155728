@use "@styles/_variables.scss" as vars;

.root {
    position: relative;
    cursor: pointer;
    user-select: none;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0;

    &__input {
        opacity: 0;
        position: absolute;
        display: none;
        box-sizing: border-box;

        &:checked + .root__label::after {
            border: 1px solid vars.$grey_elements;
        }

        &:checked + .root__label::before {
            background-color: vars.$yellow;
            border: none;
        }

        &:checked + .root__label::after {
            background-color: vars.$black_main;
            opacity: 1;
            border: none;
        }
    }

    &__label {
        display: flex;
        z-index: vars.$hight_index;
        transition: background-color 0.2s ease-in;
        cursor: pointer;
        color: vars.$black_main;
        box-sizing: border-box;
        @include vars.font_text14;
        min-height: 20px;

        &:active {
            &::before {
                border: 1px solid vars.$grey_elements;
            }
        }

        &:hover {
            &::before {
                background-color: vars.$yellow;
                border: none;
            }

            &::after {
                background-color: vars.$black_main;
                opacity: 1;
            }
        }

        &:before {
            display: block;
            position: absolute;
            content: "";
            border-radius: 50%;
            height: 20px;
            width: 20px;
            top: 0px;
            left: 0px;

            border: 1px solid vars.$grey_text;
            transition: border 0.2s ease-in;
            box-sizing: border-box;
        }

        &:after {
            display: block;
            position: absolute;
            height: 10px;
            width: 10px;
            border-radius: 50%;
            content: "";
            top: 5px;
            left: 5px;
            background-color: vars.$yellow;

            transition: opacity 0.2s ease-in;
            opacity: 0;
            box-sizing: border-box;
        }
    }

    &--centered {
        &::after,
        &::before {
            top: 50%;
            transform: translateY(-50%);
        }
    }

    &--labeled {
        margin-left: 30px;
    }

    &--disabled {
        pointer-events: none;
        .root__input {
            &:checked + .root__label::after {
                background-color: vars.$grey_text;
            }

            &:checked + .root__label::before {
                background-color: vars.$grey_elements;
                border: 1px solid vars.$grey_elements;
            }
        }

        label.root__label {
            &::before {
                background-color: vars.$grey_hover;
                border: 1px solid vars.$grey_elements;
            }
        }
    }
}
