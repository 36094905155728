@use '@styles/_variables.scss' as vars;

.main_title {
    font-size: 1.8rem;
    line-height: 2.6rem;
    margin-bottom: 10px;
}

.main_content {
    a {
        color: vars.$green_main;

        &:hover {
            text-decoration: underline;
        }
    }

    strong {
        font-weight: bold;
    }

    em {
        font-style: italic;
    }

    ol {
        display: block !important;
        padding-inline-start: 40px !important;

    }
}
