@use "@styles/_variables.scss" as vars;

.title {
    margin-bottom: 16px;
}

.list {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
}

.item {
    display: flex;
    justify-content: space-between;
    row-gap: 12px;
    flex-direction: column;

    &:first-child {
        margin-top: 32px;

        @media (max-width: vars.$break-point-lg) {
            margin-top: 0;
        }
    }

    &:last-child {
        margin-bottom: 32px;
    }
}

.count {
    color: vars.$grey_text;
}

.content_list {
    display: flex;
    flex-direction: column;

    padding-bottom: 10px;
}

.content_item {
    display: flex;
    justify-content: space-between;
    row-gap: 19px;
    border-bottom: 1px solid vars.$grey_lines;
}

.link {
    &_header {
        @include vars.font_text15;
        word-break: break-word;
    }
}

.topic {
    &__title {
        font-weight: 800;
        font-size: 1.6rem;
        line-height: 2.1rem;
        word-break: break-word;
    }

    &__title_wrapper {
        padding: 14px;
        background: vars.$grey_lines;
        border-radius: 12px;
        word-break: break-word;

        &--disabled {
            color: vars.$grey_text;
            background: vars.$grey_hover;
        }
    }
}

.lecture {
    &__list {
        display: flex;
        flex-direction: column;
    }

    &__title {
        padding-bottom: 16px;

        h5 {
            cursor: pointer;
            transition: color 0.2s ease;

            &:hover {
                color: vars.$outline_hover;
            }
        }
    }

    &__title_wrapper {
        font-weight: 800;
        font-size: 1.4rem;
        line-height: 2rem;
        word-break: break-word;

        padding: 0px 16px;

        &:not(:first-child) {
            .lecture__title {
                border-top: 1px solid vars.$grey_lines;
                padding-top: 16px;
            }
        }

        &--disabled {
            color: vars.$outline_hover;
            pointer-events: none;
        }
    }

    &__item {
        &:first-child {
            padding-top: 0;
        }

        &:last-child {
            padding-bottom: 0;
            border-bottom: none;
        }
    }
}

.collapse_panel {
    display: flex;
    flex-direction: column;

    &:first-child {
        padding-top: 0;
    }

    &:last-child {
        padding-bottom: 0;
        border-bottom: none;

        @media (max-width: vars.$break-point-md) {
            padding-bottom: 14px;
        }
    }
}

.sidebar {
    position: relative;

    & > div {
        @media (max-width: vars.$break-point-lg) {
            margin-bottom: -50px;
        }

        &::after {
            position: absolute;
            content: "";
            z-index: vars.$hight_index;

            bottom: 0px;
            left: 0px;
            width: calc(100% - 4px);
            height: 48px;

            background-image: -webkit-linear-gradient(180deg, rgba(vars.$white_main, 0), vars.$white_main);
            background-image: linear-gradient(180deg, rgba(vars.$white_main, 0), vars.$white_main);
        }

        &::before {
            position: absolute;
            content: "";
            z-index: vars.$hight_index;

            top: 0px;
            left: 0px;
            width: calc(100% - 4px);
            height: 48px;

            background-image: -webkit-linear-gradient(0deg, rgba(vars.$white_main, 0), vars.$white_main);
            background-image: linear-gradient(0deg, rgba(vars.$white_main, 0), vars.$white_main);

            @media (max-width: vars.$break-point-lg) {
                height: 24px;
            }
        }
    }

    @media (max-width: vars.$break-point-lg) {
        padding-right: 5px;
        border-right: none;
        height: 100%;

        & > div {
            padding-top: 0;
        }
    }

    & > div {
        @media (max-width: vars.$break-point-lg) {
            top: 24px;
            height: calc(100% - 58px);
            min-height: calc(100% - 58px);
        }
    }
}

.drawer {
    padding: 0px 11px 0px 0px;
    position: relative;

    overflow-y: auto;
    -ms-overflow-style: initial;
    scrollbar-width: initial;

    &::-webkit-scrollbar {
        width: 4px;
        height: 100%;
        border-radius: 5px;
        background-color: inherit;
    }

    &::-webkit-scrollbar-thumb {
        width: 4px;
        border-radius: 5px;
        background-color: inherit;
    }

    &:hover,
    &:focus {
        &::-webkit-scrollbar-thumb {
            background-color: vars.$grey_elements;
        }
    }

    & > div {
        overflow-y: initial;

        &:before {
            display: none;
        }
    }

    & > * {
        visibility: visible;
    }

    @media (max-width: vars.$break-point-lg) {
        width: 456px;
        padding-top: 16px;
        padding-left: 16px;
    }

    @media (max-width: vars.$break-point-sm) {
        width: 100%;
        padding-left: 10px;
        padding-right: 5px;
    }
}
