@import "@styles/_break_points";

// Fonts
@mixin font_h1 {
    font-weight: 800;
    font-size: 2.8rem;
    line-height: 3.6rem;
    letter-spacing: -0.2px;

    @media (max-width: $break-point-lg) {
        font-size: 2.4rem;
        line-height: 3rem;
        letter-spacing: normal;
    }
}

@mixin font_h2 {
    font-weight: 800;
    font-size: 2.2rem;
    line-height: 3rem;
    letter-spacing: normal;
}

@mixin font_h3 {
    font-weight: 700;
    font-size: 1.8rem;
    line-height: 2.4rem;
    letter-spacing: normal;
}

@mixin font_text9 {
    font-size: 0.9rem;
    line-height: 1.5rem;
    letter-spacing: 0.05em;
}

@mixin font_text10 {
    font-weight: 700;
    font-size: 1.1rem;
    line-height: 1.5rem;
    letter-spacing: 0.05em;
}

@mixin font_text11 {
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: 0.05em;
}

@mixin font_text11 {
    font-weight: 500;
    font-size: 1.1rem;
    line-height: 1.4rem;
}

@mixin font_text12 {
    font-size: 1.2rem;
    line-height: 1.6rem;
    letter-spacing: normal;
}

@mixin font_text13 {
    font-size: 1.3rem;
    line-height: 1.8rem;
    letter-spacing: normal;
}

@mixin font_text13_bold {
    font-size: 1.3rem;
    line-height: 1.8rem;
    letter-spacing: normal;
    font-weight: 700;
}

@mixin font_text14 {
    font-size: 1.4rem;
    line-height: 2rem;
    letter-spacing: normal;
}

@mixin font_text14_bold {
    font-size: 1.4rem;
    line-height: 1.76rem;
    letter-spacing: normal;
    font-weight: 700;
}

@mixin font_text15 {
    font-size: 1.5rem;
    line-height: 2.1rem;
    letter-spacing: normal;
}

@mixin font_text15_semi_bold {
    font-size: 1.5rem;
    line-height: 2.1rem;
    letter-spacing: normal;
    font-weight: 700;
}

@mixin font_text15_bold {
    font-size: 1.5rem;
    line-height: 2.1rem;
    letter-spacing: normal;
    font-weight: 800;
}

@mixin font_text16 {
    font-style: normal;
    line-height: 18px;
    font-size: 1.3rem;
    font-weight: 500;
}

@mixin font_text17 {
    font-style: normal;
    line-height: 24px;
    font-size: 1.4rem;
    font-weight: 500;
}

@mixin font_text18 {
    font-style: normal;
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 2.2rem;
}

@mixin font_text19 {
    font-style: normal;
    font-weight: 800;
    font-size: 2rem;
    line-height: 2.6rem;
}

@mixin font_text22_extra_bold {
    font-style: normal;
    font-weight: 800;
    font-size: 2.2rem;
    line-height: 3rem;
}

@mixin font_text22_semi_bold {
    font-style: normal;
    font-weight: 600;
    font-size: 2.2rem;
    line-height: 3rem;
}

@mixin font_text32 {
    font-weight: 700;
    font-size: 2.8rem;
    line-height: 3.6rem;
}

@mixin font_title {
    font-weight: 800;
    font-size: 3.6rem;
    line-height: 4.4rem;
    letter-spacing: -0.2px;
}
