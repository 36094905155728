@use '@styles/variables.scss' as vars;


.title {
    display: flex;

    h1 {
        margin-left: 12px;
    }
}

.card {
    padding: 24px;
    background-color: white;
}

.container {
    margin-top: 24px;
    width: 100%;
}

.author_container {
    width: 100%;
}

.author_inner {
    display: flex;
    justify-content: space-between;
}

.author_breadcrumb {
    display: flex;
    align-items: center;
}

.breadcrumb_item {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;

    color: #A0A0A0;

    &::after {
        width: 12px;
        height: 12px;
        content: '';
        position: relative;
        top: 2px;

        display: inline-flex;

        margin-left: 4px;
        margin-right: 4px;
        background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4 2L8 6L4 10' stroke='%23A0A0A0' stroke-width='1.3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    }

    &:last-child {
        &::after {
            display: none;
        }
    }
}

.author {
    display: flex;
}

.author_name {
    margin-left: 12px;
}

.question_container {
    margin-top: 24px;
}

.question_header {
    font-weight: 800;
    font-size: 15px;
    line-height: 21px;
    letter-spacing: normal;

    margin-bottom: 8px;
}

.question_card {
    margin-bottom: 24px;
}

.question_sub_header {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: normal;

    margin-bottom: 24px;
}

.quiz_caption {
    color: #A0A0A0;
    margin-bottom: 20px;
}

.quiz_list {
    margin: 0px 0px 24px 0px;
    display: block;
}

.quiz_control {
    margin-bottom: 16px;
    display: flex;
    justify-content: flex-start;

    &:last-child {
        margin-bottom: 0px;
    }
}

.quiz_correct_answer {
    background: #F4F5F5;
    border-radius: 8px;

    font-family: 'Mulish';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    padding: 12px;
}

.quiz_checkbox {
    margin-right: 8px;
}

.quiz_label {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

.estimation {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
}

.estimation_label {
    outline: none;
    margin-right: 10px
}

.rightcolumn,
.leftcolumn {
    display: flex;
}

.quiz_samples,
.leftcolumn,
.quiz_container,
.quiz_samples_heading,
.quiz_samples_rows,
.quiz_samples_caption {
    display: block;
}

.samples {
    margin-top: 26px;

    ul {
        display: flex;
    }
}

.tabs_btn {
    margin-right: 10px;
    padding: 6px 16px 8px 16px;
}

.quiz_popup {
    position: relative;
}

.quiz_samples {
    background-color: vars.$grey_hover;
    border-radius: 12px;
    padding: 16px 12px;
    position: sticky;
}

.quiz_samples_heading {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
}

.quiz_samples_caption {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    color: vars.$grey_text;
    margin-top: 4px;
}

.quiz_row {
    display: flex;
}

.quiz_samples_rows {
    margin-top: 16px;
}

.quiz_row_item {
    width: 32px;
    height: 32px;

    background-color: vars.$green_success;
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;

    font-family: 'Mulish';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    border-radius: 6px;

    color: white;

    &:last-child {
        margin-right: 0px;
    }

    margin-bottom: 8px;
}