@import "@styles/_variables.scss";

.page {
    padding: 32px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    .header {
        display: flex;
        align-items: center;
        gap: 16px;
        height: fit-content;
        span {
            color: $black_main;
            font-size: 28px;
            font-weight: 800;
            line-height: 36px;
            letter-spacing: -0.2px;
        }
    }
    .content {
        max-width: 986px;

        padding: 24px;

        border-radius: 16px;
        border: 1px solid #f0f0f0;

        background: $white_main;

        box-shadow: 0px 4px 24px 0px rgba(53, 53, 53, 0.06);

        .inputs {
            display: grid;
            grid-template-columns: 194px 1fr;
            grid-template-rows: min-content;
            grid-gap: 16px;

            @media (max-width: $break-point-sm) {
                grid-template-columns: none;
            }

            p {
                color: $black_main;
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
            }
            .description {
                display: flex;
                flex-direction: column;
                gap: 10px;
                .grayText {
                    color: $grey_text;
                    font-size: 13px;
                    font-weight: 400;
                    line-height: 13px;
                }
            }
        }
        .buttons {
            margin-top: 40px;
            margin-left: 210px;
            display: flex;
            gap: 16px;

            @media (max-width: $break-point-sm) {
                margin: 40px 0 0;
            }
        }
    }
}
