@use '@styles/_variables.scss' as vars;

.layout_container {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

.main_layout_wrapper {
    display: flex;
    flex-grow: 1;
    width: 100%;
}

.sidebar {
    z-index: vars.$hight_index;
}
