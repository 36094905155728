.title_wrapper {
    display: flex;
    flex-direction: column;

    margin-bottom: 24px;
}

.main_title {
    margin-bottom: 6px;
}

.content {
    width: 100%;
    max-width: 824px;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
}
