@use "@styles/_variables.scss" as vars;

.wrapper {
    position: absolute;
    top: 1px;
    left: 1px;
    width: 360px;
    padding: 24px;
    background-color: vars.$white_main;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    z-index: vars.$hightest_index;
}

.btn_wrapper {
    position: absolute;
    top: 15px;
    right: 15px;
    display: flex;
    align-items: center;
    column-gap: 12px;
}

.button_menu {
    padding: 0;
    border-radius: 5px;

    &__item {
        &--delete {
            color: vars.$red_attention;
        }
    }
}

.close_button {
    &:hover {
        path {
            stroke: vars.$black_main;
        }
    }
}

.arrow,
.arrow::before {
    position: absolute;
    width: 12px;
    height: 12px;
    background: vars.$white_main;
}

.arrow {
    visibility: hidden;
}

.arrow::before {
    visibility: visible;
    content: "";
    transform: rotate(45deg);
}

.btn_delete {
    margin-top: 16px;
}

div[data-popper-place3ent^="top"] > .arrow {
    bottom: -3px;
}

div[data-popper-placement^="bottom"] > .arrow {
    top: -3px;
}

div[data-popper-placement^="left"] > .arrow {
    right: -3px;
}

div[data-popper-placement^="right"] > .arrow {
    left: -3px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}
