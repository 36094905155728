@use '@styles/_variables.scss' as vars;

.footer_сontainer {
    width: 100%;
    display: flex;
    margin-top: 24px;
    justify-content: space-between;
}

.btn_container {
    column-gap: 16px;
    justify-content: flex-end;
    display: flex;
}

.table_container {
    width: 900px;
    max-height: 500px;
    overflow: hidden;
}

.table_select_count {
    display: flex;
    justify-content: flex-start;
}

.csv-preview {
    display: flex;
    align-items: center;
    height: 100%;
    white-space: pre;

    & p {
        display: inline;
    }
}