@use '@styles/_variables.scss' as vars;

.root {
    position: relative;
    display: flex;
    column-gap: 16px;
    align-items: center;
    justify-content: space-between;
    padding: 12px 0;
    padding-left: 32px;
    border-bottom: 1px solid vars.$grey_lines;

    &__name_wrapper {
        display: flex;
        align-items: center;
        column-gap: 10px;
    }

    &__list_btn {
        position: absolute;
        top: 22px;
        right: 1px;

        display: flex;
        align-items: center;
        column-gap: 8px;

        opacity: 0;
        transition: opacity 300ms ease;
    }

    &:hover &__list_btn {
        opacity: 1;
    }
}
