@use '@styles/_variables.scss' as vars;

.item {
    position: relative;
    display: flex;
    align-items: center;
    padding: 12px 0;
    padding-left: 32px;
    border-bottom: 1px solid vars.$grey_lines;

    &:hover .delete_button {
        opacity: 1;
    }

    &--disabled {
        padding-left: 0;
    }
}

.span {
    padding: 0 8px;
    font-size: 1.4rem;
    cursor: pointer;

    &.active {
        font-weight: 800;
        color: vars.$green_main;
    }
}

.delete_button {
    position: absolute;
    top: calc(50% - 6px);
    right: 0;
    width: 20px;
    height: 20px;
    margin: auto;
    opacity: 0;
    transition: opacity 300ms ease;
}
