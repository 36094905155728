@use '@styles/variables.scss' as vars;

.root {
    position: sticky;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 40px;
    width: 100%;
    min-height: 72px;
    padding-top: 14px;
    padding-left: 24px;
    padding-bottom: 18px;
    padding-right: 24px;
    background-color: vars.$white_main;
    border-bottom: 1px solid vars.$grey_lines;
    z-index: vars.$hight_index;

    //@media (max-width: vars.$break-point-lg) {
    //    column-gap: unset;
    //    padding: 18px 32px;
    //}
    //
    //@media (max-width: vars.$break-point-md) {
    //    padding: 24px 40px;
    //}
    //
    //@media (max-width: vars.$break-point-sm) {
    //    //min-height: 56px;
    //    padding-top: 0;
    //    padding-bottom: 0;
    //}
}
