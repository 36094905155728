@use '@styles/_variables.scss' as vars;


.container {
    display: flex;
    column-gap: 16px;

    width: calc((100% - 32px * 2) / 3);

    @media (max-width: vars.$break-point-md) {
        width: calc((100% - 16px) / 2);
    }

    @media (max-width: vars.$break-point-sm) {
        width: 100%;
    }
}

.avatar {
    display: flex;
    border-radius: 50%;
    overflow: hidden;
    width: 44px;
    height: 44px;
    min-width: 44px;

    background-color: vars.$green_main;
    color: vars.$white_main;
    align-items: center;
    justify-content: center;
    font-weight: bold;

    img {
        width: 44px;
        height: 44px;
        min-width: 44px;
    }

    @media (max-width: vars.$break-point-lg) {
        width: 48px;
        min-width: 48px;
        height: 48px;

        img {
            width: 48px;
            min-width: 48px;
            height: 48px;
        }
    }
}

.content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.title {
    margin-bottom: 6px;
    @include vars.font_text15();
    font-weight: bold;

    @media (max-width: vars.$break-point-lg) {
        margin-bottom: 2px;
    }
}

.description {
    margin-bottom: 16px;
    color: vars.$grey_text;
    @include vars.font_text13();

    @media (max-width: vars.$break-point-lg) {
        margin-bottom: 8px;
    }
}

.description,
.title {
    width: 100%;
}

.numberCaption {
    margin-bottom: 8px;
    @include vars.font_text14();
    display: flex;
    flex-direction: column;
    row-gap: 4px;
}

.phone {
    display: flex;
}

.action {
    display: flex;
    margin-top: 16px;

    @media (max-width: vars.$break-point-lg) {
        margin-top: 8px;
    }

    .write-btn {
        @media (max-width: vars.$break-point-lg) {
            font-size: 14px;
            font-weight: 700;
        }
    }
}

.emails {
    display: flex;
    flex-direction: column;

    a {
        color: vars.$green_main;
    }
}
