.title_wrapper {
    display: flex;
    column-gap: 16px;
    margin-bottom: 26px;
}

.actions {
    display: flex;
    column-gap: 16px;
    margin-top: 32px
}

.loader {
    position: fixed;
    height: 100%;
    width: 100%;

    background: white;
    display: flex;
    align-items: center;
    justify-content: center;

    z-index: 999;
}

.header {
    display: flex;
    width: 100%;
    align-items: stretch;
    flex-direction: row;
    column-gap: 16px;

    h1 {
        flex-grow: 1;
        flex-shrink: 0;
    }

    .select {
        flex-shrink: 0;
    }
}

.select {
    display: block;
}