@use '@styles/_variables.scss' as vars;

.title {
    margin-bottom: 16px;
}

.create_section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 16px;

    &__title {
        margin-bottom: 12px;
    }

    &__text {
        width: 195px;
        text-align: center;
        margin-bottom: 24px;
    }
}

.blocked_test {
    display: flex;
    color: vars.$red_attention;
    background-color: vars.$red_background;
    padding: 11px 16px;
    border-radius: 8px;
    width: fit-content;
    margin-bottom: 32px;
}

.background_img {
    width: 264px;
    height: 231px;
    background: url("/assets/image/test-page-svg.svg") no-repeat center;
}

.delete_bucket {
    display: flex;
    position: relative;
    top: 2px;
    cursor: pointer;
}

.content_container,
.addbtn,
.content_table {
    display: flex;
    flex-direction: column;
}

.csv-preview {
    display: flex;
    align-items: center;
    height: 100%;
    white-space: pre;

    & p {
        display: inline;
    }
}
