@use "@styles/_variables.scss" as vars;

.dropdown_control {
    display: flex;
    justify-content: flex-end;
    position: relative;
    padding: 10px 12px 4px;
    border-top: 1px solid vars.$grey_lines;

    &__clear {
        position: absolute;
        left: 12px;
        height: 34px;
    }
}
