@use '@styles/_variables.scss' as vars;

.root {
    display: block;

    &__input {
        height: 0;
        width: 0;
        visibility: hidden;
        display: none;
        user-select: none;

        &:checked + .root__label {
            background: vars.$green_main;

            &::after {
                left: calc(100% - 2px);
                transform: translateX(-100%);
            }
        }
    }

    &__label {
        cursor: pointer;
        text-indent: -9999px;
        width: 44px;
        height: 24px;
        background: vars.$grey_lines_hover;
        display: block;
        border-radius: 100px;
        position: relative;

        &::after {
            content: '';
            position: absolute;
            top: 2px;
            left: 2px;
            width: 20px;
            height: 20px;
            background: #fff;
            border-radius: 65px;
            transition: 0.2s;
        }
    }
}

.disabled {
    opacity: 0.7;

    & .root__label {
        cursor: default;
    }

}
