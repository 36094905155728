@use '@styles/_variables.scss' as vars;

.container {
    & .Toastify__toast {
        display: flex;
        align-items: center;
        min-height: 48px;
        color: vars.$white_main;
        border-radius: 8px;
    }

    & .Toastify__toast--default {
        &.Toastify__toast {
            color: vars.$black_main;
        }
    }

    & .Toastify__toast--success {
        background-color: vars.$green_success;
    }

    & .Toastify__toast--error {
        background-color: vars.$red_attention;
    }

    & .Toastify__toast-body {
        font-family: "Mulish", Arial, Helvetica, sans-serif;
        font-size: 1.4rem;
    }

    .Toastify__toast-icon {
        width: 24px;
        height: 24px;
    }

    & .close-button {
        color: vars.$white_main;
        display: flex;
    }
}