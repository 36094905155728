@use "@styles/_variables.scss" as vars;

.wrapper {
    height: 100%;
    max-height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-bottom: 10px;
}

.contactList {
    overflow: auto;

    &::-webkit-scrollbar {
        width: 4px;
        height: 100%;
        background-color: white;
        border: solid 3px transparent;
    }

    &::-webkit-scrollbar-track {
        border: solid 3px transparent;
    }

    &::-webkit-scrollbar-thumb {
        width: 3px;
        height: 39px;
        border-radius: 5px;
        background-color: vars.$grey_elements;
    }

    @media (max-width: vars.$break-point-sm) {
        width: 100%;
    }
}

.item {
    padding: 14px 14px 14px 20px;
    cursor: pointer;
    width: 100%;

    &:not(.selected):hover {
        background-color: vars.$grey_hover;
    }

    @media (max-width: vars.$break-point-lg) {
        padding: 17px 24px 11px 24px;
    }

    @media (max-width: vars.$break-point-md) {
        padding: 16px 24px;
    }

    @media (max-width: vars.$break-point-sm) {
        width: 100%;
        padding: 12px 16px 14px;
    }
}

.selected {
    background-color: vars.$chatHover;
}

.no-data {
    height: 100%;
}

.container {
    display: flex;
    flex-direction: column;
}
