@use "@styles/_variables.scss" as vars;

.pagination {
    display: flex;
    align-items: center;
    margin-top: 24px;
    justify-content: space-between;
    width: 100%;

    &--end {
        justify-content: flex-end;
    }

    @media (max-width: vars.$break-point-md) {
        flex-direction: column-reverse;
    }
}

.list {
    margin: 0px;
    display: flex;
    list-style-type: none;

    .ul {
        display: flex;
        column-gap: 8px;

        li {
            &::marker {
                display: nones;
            }
        }
    }
}

.switcher {
    display: flex;

    @media (max-width: vars.$break-point-md) {
        margin-bottom: 12px;
    }
}

.btn {
    &:hover {
        background-color: vars.$grey_lines;
        color: vars.$black_main;
    }

    @media (max-width: vars.$break-point-lg) {
        min-width: fit-content;
    }

    &_hide {
        display: none;
    }
}

.btn_hide {
    display: none;
}
