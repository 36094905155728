@use "@styles/_variables.scss" as vars;

.tooltip-container {
    position: relative;
    display: inline-block;
}

.tooltip-box {
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: max-content;
    padding: 14px;
    background-color: vars.$white_main;
    color: vars.$black_main;
    text-align: center;
    border-radius: 4px;
    z-index: 1000;
    max-width: 360px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
}
