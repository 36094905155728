@use "@styles/_variables.scss" as vars;

.root {
    @media (max-width: vars.$break-point-md) {
        padding: 20px 20px 24px;
    }

    @media (max-width: vars.$break-point-sm) {
        padding: 16px 16px 24px;
    }
}

.textarea {
    width: 100%;
    padding: 8px 10px 9px;
    border: 1px solid vars.$grey_elements;
    border-radius: 8px;
    outline-color: vars.$grey_text;
    outline-width: 1px;
    font-family: vars.$baseFont;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 2rem;
    color: vars.$black_main;
    resize: none;
}

.info {
    margin-bottom: 14px;

    & p {
        margin-bottom: 0;
    }
}

.content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 22px;

    .upload {
        @media (max-width: vars.$break-point-sm) {
            & > div {
                width: inherit;
            }

            & > div > div {
                width: inherit;
            }
        }
    }

    .submit {
        @media (max-width: vars.$break-point-sm) {
            width: 100%;
        }
    }
}

.collapse {
    width: 100%;
}

.collapse_panel {
    color: red;

    [class~="rc-collapse-content-box"] {
        margin-top: 12px;
        margin-bottom: 0;
    }

    &__header {
        max-width: 190px;
        border-bottom: none !important;

        [class~="rc-collapse-header-text"] {
            padding: 0;
            font-weight: 700;
            font-size: 1.4rem;
            line-height: 2rem;
            color: vars.$black_main;
        }

        [class~="rc-collapse-expand-icon"] {
            order: 1;
            margin-right: 0;
            margin-left: 8px;
        }
    }

    &__icon {
        color: vars.$grey_text;
        transition: transform 0.3s ease-in-out;

        &--active {
            transform: rotate(180deg);
        }
    }
}
