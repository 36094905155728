@use "@styles/_variables.scss" as vars;

.wrapper {
    display: flex;
    width: 100%;
}

.title_wrapper {
    margin-bottom: 24px;

    @media (max-width: vars.$break-point-md) {
        margin-bottom: 20px;
    }
}

.tabs_wrapper {
    width: 100%;
    position: relative;

    &__btn {
        position: absolute;
        top: -13px;
        right: 24px;
        border-radius: 8px;

        @media (max-width: vars.$break-point-sm) {
            position: initial;
            width: 100%;
        }
    }
}

.tabs {
    margin-bottom: 24px;
    column-gap: 30px;

    @media (max-width: vars.$break-point-lg) {
        column-gap: 24px;
    }

    @media (max-width: vars.$break-point-md) {
        column-gap: 20px;
    }

    & a {
        margin-right: 0;
        padding-bottom: 14px;

        @media (max-width: vars.$break-point-lg) {
            padding-bottom: 12px;
        }
    }
}
