@use '@styles/_variables.scss' as vars;

.paper {
    margin-bottom: 24px;
}

.item {
    position: relative;
    display: flex;
    align-items: center;
    column-gap: 12px;
    padding-bottom: 14px;
    border-bottom: 1px solid vars.$grey_lines;

    &:hover .delete_button {
        opacity: 1;
    }

    &__edit_input_title {
        font-weight: 800;
        font-size: 2.2rem;
        line-height: 2.4rem;
    }
}

.delete_button {
    position: absolute;
    //-12px
    top: calc(50% - 18px);
    right: 0;
    width: 20px;
    height: 20px;
    margin: auto;
    opacity: 0;
    transition: opacity 300ms ease;
}

.lectures_list {
    padding-left: 10px;
    margin-bottom: 20px;
}

.lecture_item {
    position: relative;
    display: flex;
    align-items: center;
    column-gap: 12px;
    padding: 10px 0;
    border-bottom: 1px solid vars.$grey_lines;

    width: 100%;

    &:hover .delete_button {
        opacity: 1;
    }
}

.card_header {
    display: flex;
    align-items: stretch;
    width: 100%;
}

.drag_handler {
    display: flex;
    margin-right: 12px;
}