@use '@styles/_variables.scss' as vars;

.layout_container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.main_layout_wrapper {
    @include vars.centering;
    display: flex;
    width: 100%;
    flex-grow: 1;
    padding-bottom: 50px;
}

.header {
    @include vars.centering;
}

.footer {
    column-gap: 166px;

    @media (max-width: vars.$break-point-lg) {
        column-gap: unset;
    }

    @media (max-width: vars.$break-point-md) {
        flex-wrap: wrap;
    }
}

.block_wrapper {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    align-items: flex-start;
    column-gap: 32px;

    @media (max-width: vars.$break-point-md) {
        width: 100%;
    }
}

.contacts_wrapper {
    display: flex;
    column-gap: 32px;
    width: 63%;
    justify-content: space-between;
    align-items: flex-start;

    @media (max-width: vars.$break-point-md) {
        width: 100%;

        & > div {
            width: 50%;
        }
    }

    @media (max-width: vars.$break-point-sm) {
        flex-direction: column;

        & > div {
            margin-top: 24px;
            width: 100%;
        }

        & > ul {
            width: 100%;
        }
    }
}

.label {
    @media (max-width: vars.$break-point-lg) {
        display: none;
    }
}

.social-wrapper {
    height: 103px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .label-right {
        @media (min-width: vars.$break-point-lg) {
            display: none;
        }
    }

    @media (max-width: vars.$break-point-md) {
        width: 100%;
        flex-direction: row-reverse;
        height: auto;
        margin-top: 24px;
        align-items: center;

        & > ul {
            width: 50%;
        }
    }

    @media (max-width: vars.$break-point-sm) {
        width: 100%;
        flex-direction: column;
        height: 80px;

        & > ul {
            width: 100%;
        }
    }
}
