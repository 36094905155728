@use '@styles/variables.scss' as vars;

.body {
    display: flex;
    flex-direction: column;

    &::-webkit-scrollbar {
        width: 4px;
        height: 100%;
        background-color: white;
        padding-top: 4px;
    }

    &::-webkit-scrollbar-thumb {
        width: 4px;
        height: 39px;
        border-radius: 5px;
        background-color: vars.$grey_elements;
    }
}

.scrolled {
    height: 500px;
    overflow-y: auto;
}