@use "@styles/variables.scss" as vars;


.events-container {
    margin-top: 24px;
}

.events_caption {
    display: flex;
    margin-bottom: 24px;
}

.events_heading {
    margin-bottom: 12px;
}