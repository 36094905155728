@use "@styles/_variables.scss" as vars;

.container {
    display: flex;
    flex-direction: column;
    gap: 32px;

    .list {
        display: flex;
        flex-direction: column;
        gap: 16px;
        .input {
            display: flex;
            flex-direction: column;
            gap: 4px;

            & > label {
                font-size: 14px;
                line-height: 20px;
            }
        }
    }
    .button_wrapper {
        width: 100%;
        display: flex;
        column-gap: 16px;
        justify-content: flex-end;
    }
}
