@use "@styles/variables.scss" as vars;
@use "@styles/mixins/typo" as mixins;

.root {
    display: flex;
    flex-direction: column;

    &__inner {
        border: 1px dashed vars.$outline_borders;
        box-sizing: content-box;
        border-radius: 10px;
        display: flex;
        height: 180px;
        overflow: hidden;
        width: 300px;

        transition: border-color 0.1s ease-in;

        cursor: pointer;

        &:hover {
            border: 1px dashed vars.$green_main;
        }
    }

    &__placeholder {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__photo {
        display: flex;
        align-items: center;
        flex-direction: column;

        &-icon {
            margin-bottom: 12px;
            width: 36px;
            height: 36px;
        }

        &-label {
            color: vars.$green_main;
            @include mixins.font_text14();
        }
    }

    &__caption {
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;
        margin-right: 10px;

        margin-top: 8px;
        color: vars.$grey_text;
    }

    &__messages {
        margin-top: 8px;

        &--error {
            color: vars.$red_attention;
            //TODO: Replace this shit
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: normal;
        }
    }

    &__hide-input {
        display: none;
    }

    &--select {
        border: 1px dashed vars.$green_main;
    }

    &--droped {
        &:hover {
            border: 1px dashed vars.$outline_borders;
        }
    }

    &--error {
        border: 1px dashed vars.$red_attention;

        &:hover {
            border: 1px dashed vars.$red_attention;
        }
    }

    &--disabled {
        cursor: default;
    }
}
