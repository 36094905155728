.button_wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    column-gap: 16px;
    width: 100%;
    margin-top: 32px;

    & > p {
        margin-bottom: 0px;
    }

    &_end {
        justify-content: flex-end;
    }
}

.drawer {
    &_title {
        margin-bottom: 12px;
    }

    .button_wrapper {
        margin-top: 24px;
    }
}
