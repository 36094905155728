@use '@styles/_variables.scss' as vars;

.user_avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    min-width: 36px;
    font-weight: bold;
    color: vars.$white_main;
    background-color: vars.$green_main;
    border-radius: 50%;
    cursor: pointer;
    overflow: hidden;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}