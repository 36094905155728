@use '@styles/_variables.scss' as vars;

.main {
    padding-top: 28px;
    padding-left: 0;
    padding-right: 0;

    @media (max-width: vars.$break-point-sm) {
        padding-top: 24px;
    }
}

.main_title {
    margin-bottom: 20px;

    @media (max-width: vars.$break-point-sm) {
        margin-bottom: 24px;
        font-size: 2.0rem;
        line-height: 2.6rem;
    }
}

