@use "@styles/_variables.scss" as vars;

.table {
    .event_name {
        @include vars.font_text14_bold;
        word-wrap: break-word;
        margin-bottom: 4px;

        @media (max-width: vars.$break-point-sm) {
            font-size: 1.5rem;
            line-height: 2.21rem;
        }
    }

    .discipline_name {
        @include vars.font_text10;
        color: vars.$black_grey;
        word-wrap: break-word;
        text-transform: uppercase;

        @media (max-width: vars.$break-point-sm) {
            margin-bottom: 4px;
        }
    }
}

.no_results {
    margin: 120px auto 0px auto;
    color: vars.$grey_empty;
    width: fit-content;
}

.student_table {
    padding-top: 8px;

    @media (max-width: vars.$break-point-lg) {
        padding-top: 0;
    }

    @media (max-width: vars.$break-point-sm) {
        padding-top: 0;
    }

    [class~="row--selectable"] {
        cursor: pointer;
        height: 65px;

        &:hover {
            background-color: inherit;
        }

        div {
            div {
                align-items: center;
            }
        }
    }

    .list-container {
        .cell {
            &:last-child {
                padding-right: 0px;
            }
        }

        .header--last {
            @media (min-width: vars.$break-point-lg) {
                padding-right: 0px;
            }
        }
    }
}

.list-container {
    width: 100%;

    .header {
        grid-gap: 3%;
        color: vars.$grey_text;
        border-bottom: 1px solid vars.$grey_lines;
        padding: 0px 12px;

        &--last {
            display: flex;

            @media (min-width: vars.$break-point-lg) {
                justify-content: flex-end;
                padding-right: 56px;
            }
        }
    }

    .row {
        grid-gap: 3%;
        border-bottom: 1px solid vars.$grey_lines;
        padding: 12px 12px 16px;
        height: auto;
        cursor: pointer;

        &:hover {
            .event_name {
                color: vars.$green_main;
            }
        }
    }

    .first-row {
        @media (max-width: vars.$break-point-lg) {
            padding-top: 0;
        }
    }

    .last-row {
        border-bottom: none;
    }

    .cell {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        position: relative;
        word-break: break-word;

        &:last-child {
            @media (min-width: vars.$break-point-lg) {
                align-items: flex-end;
                padding-right: 56px;
            }
        }
    }
}

.menu {
    position: absolute;
    right: 0;
    font-size: 1.4rem;

    &__item {
        @include vars.font_text14;
    }
}

.gray-text {
    @media (max-width: vars.$break-point-lg) {
        @include vars.font_text12;
        color: vars.$grey_text;
        text-align: end;
    }

    @media (max-width: vars.$break-point-sm) {
        text-align: start;
    }

    &:not(:last-of-type) {
        margin-bottom: 2px;
    }
}

.filters {
    width: 100%;
    justify-content: flex-start;
    margin-bottom: 20px;
}
