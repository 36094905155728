.title {
    margin-bottom: 12px;
}

.material {
    padding: 8px 0;

    a {
        font-weight: 500;
    }
}
