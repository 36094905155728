@use '@styles/_variables.scss' as vars;

.root {
    width: 100%;
    word-break: break-word;
    color: inherit;
    font-family: vars.$baseFont;

    p, span, a {
        font-family: inherit !important;
    }

    a {
        color: vars.$green_main;
    }

    img {
        margin: 18px 7px;
        max-width: 100%;
        height: auto;

        @media (max-width: vars.$break-point-md) {
            margin: 14px 0;
        }

        @media (max-width: vars.$break-point-sm) {
            margin: 6px 0;
        }
    }

    p {
        margin-bottom: 18px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    span {
        line-height: inherit;
    }

    strong {
        font-weight: bold;
    }

    em {
        font-style: italic;
    }

    table {
        margin-bottom: 18px;
    }

    td {
        border: 1px solid #ccc;
        padding: 0.4rem;
    }

    sup {
        vertical-align: super;
        font-size: smaller;
    }

    sub {
        vertical-align: sub;
        font-size: smaller;
    }

    ul {
        list-style-type: disc;
    }

    ol {
        list-style-type: decimal;
    }

    ol, ul {
        display: block;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0;
        margin-inline-end: 0;
        padding-inline-start: 40px;
    }

    pre {
        white-space: pre-wrap;
        white-space: -moz-pre-wrap;
        white-space: pre-wrap;
        white-space: -o-pre-wrap;
        word-wrap: break-word;
    }

    iframe {
        width: 100% !important;
        height: 80vh !important;
        object-fit: cover !important;
    }
}
