@use "@styles/_variables.scss" as vars;

.option_list_wrapper {
    overflow-x: auto;
    max-height: 300px;

    &::-webkit-scrollbar {
        scroll-padding-top: 10px;
        width: 4px;
        padding-top: 12px;
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        padding-top: 10px;
        width: 4px;
        border-radius: 5px;
        background-color: vars.$grey_elements;
    }

    ::-webkit-scrollbar-track-piece:end {
        background: transparent;
        margin-bottom: 10px;
    }

    ::-webkit-scrollbar-track-piece:start {
        background: transparent;
        margin-top: 10px;
    }
}

.empty {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: vars.$grey_text;
    cursor: default;
    height: 36px;
}
