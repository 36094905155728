@import "@styles/_variables.scss";

.row {
    grid-gap: 3%;
    padding: 12px;
    border-top: 1px solid $grey_lines;
    height: 48px;
    cursor: pointer;
    position: relative;

    &:hover {
        background-color: $grey_hover;
    }
}

.menu {
    position: absolute;
    right: 12px;
    top: 9px;
}
