@use '@styles/_variables.scss' as vars;

.sidebar_wrapper {
    display: flex;
    flex-direction: column;
    //position: relative;;

    width: 332px;
    flex-shrink: 0;
    //height: fit-content;
    overflow-y: auto;
    //min-height: 100%;

    position: sticky;
    top: 0;
    height: 100%;
    min-height: 100%;
    max-height: 100%;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        width: 20px;
        height: calc(100%);
        box-shadow: inset -21px 0px 20px -28px vars.$grey_text;
    }
}

.search_wrapper {
    padding: 24px 24px 12px 24px;

    & > *:not(:last-child) {
        margin-bottom: 24px;
    }

    label {
        color: vars.$grey_text;

        span {
            color: vars.$grey_text;
        }
    }
}

.sidebar_students {
    flex-grow: 1;
    height: calc(100vh - 122px);
    max-height: calc(100vh - 122px);
    overflow-y: auto;

    &::-webkit-scrollbar-track {
    }

    &::-webkit-scrollbar {
        width: 3px;
        height: 100%;
        background-color: vars.$white_main;
    }

    &::-webkit-scrollbar-thumb {
        width: 3px;
        height: 39px;
        border-radius: 5px;
        background-color: vars.$grey_elements;
    }
}

.sidebar_student {
    display: flex;
    align-items: center;

    padding: 10px 10px 10px 24px;

    border-top: 1px solid vars.$grey_hover;
    border-bottom: 1px solid vars.$grey_hover;

    & > *:not(:last-child) {
        margin-right: 10px;
    }

    &:hover {
        background-color: vars.$grey_hover;

        .student_photo {
            background-color: vars.$white_main;
        }

        [class~="table-menu"] {
            opacity: 1;
        }
    }
}

.student_selected {
    background-color: vars.$grey_hover;

    .student_photo {
        background-color: vars.$white_main;
    }

    [class~="table-menu"] {
        opacity: 1;
    }
}

.student_blocked {
    color: vars.$grey_text;

    .student_photo {
        opacity: 0.5;
    }
}

.sidebar_checkbox {
    display: flex;
    align-items: center;
}

.student_photo {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 36px;
    min-width: 36px;
    height: 36px;
    border-radius: 50%;
    overflow: hidden;

    border: 1px solid vars.$grey_lines;
    background-color: vars.$grey_hover;

    img {
        max-width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .student_photo_name {
        @include vars.font_text14_bold;
    }
}

.student_name_wrapper {
    display: flex;
    flex-direction: column;
}

.student_name {
    @include vars.font_text14;
    flex-grow: 1;
}

.student_badge_wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 3px;

    .badge {
        border-radius: 3px;
        padding: 0 3px;
        text-transform: uppercase;
        width: fit-content;
        margin-right: 4px;
        @include vars.font_text9;

        &.period {
            background-color: vars.$light_green;
            color: vars.$green_main;
        }

        &.additional {
            background-color: #F4DEF2;
            color: vars.$black_grey;
        }
    }
}

.select_container {
    display: flex;
    align-items: center;
    flex-grow: 1;

    .student_photo {
        margin-right: 10px;
    }
    &:hover {
        cursor: pointer;
    }
}

.massContainer {
    display: flex;
    justify-content: space-between;
}

.massControls {
    display: flex;
}

.actionBtn {
    display: flex;
}

.no_students {
    color: vars.$grey_text;
    @include vars.font_text14;
    margin: 72px auto 0px;
    width: fit-content;
}
