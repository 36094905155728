@use "@styles/_variables.scss" as vars;

.discipline {
    display: flex;
    justify-content: space-between;
    gap: 10px;

    .closeButton {
        cursor: pointer;
    }
}

.lastElement {
    color: vars.$black_main;
}

.element {
    max-width: 238px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.option {
    white-space: normal;
    color: vars.$black_main;
    max-width: none;
    width: fit-content;
}
