@use "@styles/variables.scss" as vars;

.container {
    display: flex;
    width: 100%;
    padding: 24px 40px;
    margin-bottom: 24px;
    min-width: 375px;
}

.inner {
    width: 100%;
    display: block;
}

.header,
.heading,
.labels,
.headerControls,
.editControl,
.deleteControl,
.list,
.listItem,
.listItemIcon,
.listTitle,
.listControls {
    display: flex;
}

// HEADER
.header {
    width: 100%;
    position: relative;
    justify-content: space-between;
}

.headerContent {
    display: flex;
}

.labels {
    margin-left: 8px;
}

.headerControls {
    justify-self: right;
    align-items: center;

    .iconControl {
        margin-right: 12px;
        cursor: pointer;

        &:last-child {
            margin-right: 0px;
        }
    }
}

.subHeader {
    margin-top: 16px;
}

// LIST
.list {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid vars.$grey_lines;

    &--inspector {
        border-bottom: none;
    }
}

.listItem {
    border-top: 1px solid vars.$grey_lines;
}

.action {
    margin-top: 24px;
}