@use '@styles/variables.scss' as vars;

.root {
    @include vars.centering;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    column-gap: 32px;
    width: 100%;
    padding-top: 34px;
    padding-bottom: 34px;
    background-color: vars.$grey_hover;
    border-bottom: 1px solid vars.$grey_lines;

    @media (max-width: vars.$break-point-sm) {
        padding-top: 24px;
        padding-bottom: 40px;
    }
}