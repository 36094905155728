@use '@styles/_variables.scss' as vars;

.wrapper {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    justify-content: center;

    @media (max-width: vars.$break-point-lg) {
        padding-bottom: 16px;
    }

    &__methodist {
        justify-content: space-between;
    }
}

.month_control {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__date_wrapper {
        display: flex;
        align-items: center;
        column-gap: 5px;

        span {
            @include vars.font_text18;
            font-weight: 500;

            @media (max-width: vars.$break-point-lg) {
                @include vars.font_text15;
            }
        }
    }

    &__btn {
        padding: 12.5px 9px 10.5px;
        border-radius: 8px;

        &:hover {
            background-color: vars.$grey_hover;
            transition: background-color 0.2s ease;
        }

        svg {
            height: 9px;
            width: 14px;
            color: vars.$grey_text;
        }
    }

    & > *:not(:last-child) {
        margin-right: 16px;

        @media (max-width: vars.$break-point-lg) {
            margin-right: 8px;
        }
    }
}
