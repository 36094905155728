@use '@styles/_variables.scss' as vars;

.title_wrapper {
    display: flex;
    align-items: center;
    column-gap: 12px;
    margin-bottom: 24px;
}

.notify {
    display: flex;
    padding: 3px 7px;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 50%;
    background: vars.$red_attention;
    @include vars.font_text13;
    font-weight: 700;
    color: vars.$white_main;
}
