@use "@styles/_variables.scss" as vars;

.wrapper {
    width: 270.56px;
    height: 40px;
    display: flex;
    align-items: center;

    &:hover {
        cursor: pointer;
    }

    @media (max-width: vars.$break-point-lg) {
        width: auto;
    }
}
