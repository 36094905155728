@use '@styles/_variables.scss' as vars;

.container {
    width: 375px;
    height: 100%;
    background-color: vars.$green_main;
    padding: 28px 28px 40px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (max-width: vars.$break-point-sm) {
        width: 100vw;
    }

    .content {
        display: flex;
        width: 100%;
        overflow: hidden;
    }

    .more-opened {
        .main {
            transform: translateX(-100%);
        }

        .more-container {
            transform: translateX(-100%);
        }
    }

    .main {
        width: 100%;
        min-width: 100%;
        transition: transform 0.3s ease-in-out;
    }

    .logo {
        margin-bottom: 32px;

        & path {
            fill: vars.$white_main;
        }
    }

    .close {
        position: absolute;
        top: 16px;
        right: 16px;

        & svg {
            width: 24px;
            height: 24px;

            & path {
                stroke: vars.$white_main;
            }
        }

        &:hover {
            svg path {
                stroke: vars.$grey_hover;
            }
        }
    }

    .button {
        margin-bottom: 24px;
        font-size: 1.6rem;
        line-height: 2.1rem;
        font-weight: 700;
    }

    .list {
        gap: 18px;
        display: flex;
        flex-direction: column;
    }

    .link {
        color: vars.$white_main;
        @include vars.font_text19;
    }

    .contacts {
        color: vars.$white_main;

        & a {
            color: vars.$white_main;
        }

        & span:last-child {
            margin-top: 2px;
            color: rgba(255, 255, 255, 0.5);
        }
    }

    .more {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;

        cursor: pointer;

        .arrow {
            path {
                stroke: vars.$white_main
            }
        }
    }

    .more-container {
        width: 100%;
        min-width: 100%;

        transition: transform 0.3s ease-in-out;
        transform: translateX(calc(100%));

        display: flex;
        flex-direction: column;
        row-gap: 20px;

        .more-back {
            width: 16px;
            height: 16px;
            cursor: pointer;

            path {
                stroke: vars.$white_main
            }
        }

        h3 {
            @include vars.font_h3;

            color: vars.$white_main;
        }

        span {
            @include vars.font_text15;

            color: vars.$white_main;
        }
    }


}
