@use "@styles/_variables.scss" as vars;

.drawer {
    position: relative;

    &_content {
        padding: 32px 16px;
    }

    &_handle {
        width: 48px;
        height: 3px;
        content: "";
        background-color: vars.$outline_borders;
        border-radius: 3px;
        position: absolute;
        top: 6px;
        left: 50%;
        transform: translateX(-50%);
    }
}
