@use "@styles/_variables.scss" as vars;

.link {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 6px 16px;

    &:hover {
        a {
            transition: color 0.2s ease;
            color: vars.$outline_hover;
        }
    }

    &--active {
        background: vars.$green_pale;
        border-radius: 6px;

        &:hover {
            a {
                color: initial;
            }
        }
    }
}

.disabled_link {
    pointer-events: none;
    color: vars.$grey_text;

    &--active {
        pointer-events: none;
        color: vars.$black_main;
    }
}

li:first-child > div.link {
    padding-top: 0px;
}

li:first-child div.link--active {
    padding-top: 6px;
}
