@use "@styles/_variables.scss" as vars;

.title {
    margin-bottom: 16px;
}

.create_section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 16px;

    &__title {
        margin-bottom: 12px;
    }

    &__text {
        width: 195px;
        text-align: center;
        margin-bottom: 24px;
    }
}

.background_img {
    width: 264px;
    height: 231px;
    background: url("/assets/image/create-section-svg.svg") no-repeat center;
}

.table_container {
    display: flex;
}

.table_control {
    margin-right: 12px;

    &:last-child {
        margin-right: 0px;
    }
}

.table_control_search {
    width: 280px;
    & > div {
        height: 34px;
    }
}

.modal__text {
    @include vars.font_text14;
    margin-bottom: 32px;
    max-width: 452px;
}

.modal__message {
    display: flex;
    align-items: center;

    &_error {
        margin-bottom: 16px;

        .message__icon {
            background-color: vars.$red_attention;
        }
    }
    &_success {
        .message__icon {
            background-color: vars.$green_success;
            padding: 6px;
        }
    }
}

.message__icon {
    display: flex;

    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-right: 12px;

    padding: 2px 4px;
}

.list-container {
    width: 100%;
    margin-top: 24px;

    .header {
        grid-gap: 3%;
        padding: 0 12px 12px;
    }

    .row {
        grid-gap: 3%;
        padding: 12px 12px 0 12px;
        border-bottom: 1px solid vars.$grey_lines;
        height: 48px;
        cursor: pointer;

        &:hover {
            background-color: vars.$grey_hover;
        }
    }

    .relative {
        position: relative;
        display: flex;
        align-items: center;
    }

    .menu {
        position: absolute;
        right: 0;
    }
}

.pagination {
    width: 100%;
}
