@use '@styles/_variables.scss' as vars;

.wrapper {
    margin-bottom: 4px;
    border: 1px dashed #e0e0e0;
    border-radius: 10px;
    padding: 0 32px;
    padding-top: 42.5px;
    padding-bottom: 43.5px;
    transition: border-color 0.1s ease-in;
}

.form_content {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.placeholder {
    color: vars.$green_main;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    margin-bottom: 12px;
    background-color: vars.$green_main;
    border-radius: 50%;
}

.text {
    text-align: center;
    margin-bottom: 24px;
}

.input_wrapper {
    display: flex;
    column-gap: 12px;
}

.input {
    width: auto;
    height: 42px;
    flex-grow: 1;
}

.button {
    align-self: flex-start;
}
