@use "@styles/_variables.scss" as vars;

.wrapper {
    width: 100%;
}

.data_list {
    &__header {
        padding: 0px 12px;
        border-bottom: 1px solid vars.$grey_lines;
    }
}

.list_item {
    position: relative;
    align-items: center;
    column-gap: 16px;
    padding: 12px 12px;
    border-bottom: 1px solid vars.$grey_lines;
    transition: background-color 300ms ease;

    .statusLabel {
        margin-left: 10px;
    }

    &__download_btn_wrapper {
        position: absolute;
        top: 50%;
        right: 12px;

        transform: translateY(-50%);
        opacity: 0;
        transition: opacity 300ms ease;
    }

    &__btn {
        display: flex;
        align-items: center;
        column-gap: 12px;
        height: 100%;
        cursor: pointer;

        svg {
            color: vars.$grey_text;
            transition: color 0.2s ease;

            &:hover {
                color: vars.$black_main;
            }
        }
    }

    &:hover {
        background-color: vars.$grey_hover;
    }

    &:hover &__download_btn_wrapper {
        opacity: 1;
    }

    &__btn:hover .btn_icon {
        svg {
            color: vars.$black_main;
        }
    }
}

.column_label {
    color: vars.$grey_text;
    padding-top: 0px;
    min-width: 140px;
}

.hidden {
    display: none;
}
