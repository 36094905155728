.group_row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    gap: 16px;
    width: 100%;
}

.group_column {
    flex-grow: 1;
}
