@use '@styles/_variables.scss' as vars;

.container {
    display: flex;
    padding: 16px 24px 20px 24px;

    justify-content: flex-start;
    align-items: center;

    border-bottom: 1px solid vars.$grey_lines;

    @media (max-width: vars.$break-point-md) {
        padding: 16px 24px 20px 24px;
    }

    @media (max-width: vars.$break-point-sm) {
        padding: 16px 20px 20px;
    }
}

.icon {
    display: flex;
    flex: 0 0 36px;
    margin-right: 16px;
}

.content {
    display: flex;
    flex-direction: column;
}

.title {
    display: flex;
    @include vars.font_text14();
    margin-bottom: 4px;
    cursor: default;
}

.date {
    display: flex;
    color: vars.$grey_text;
    @include vars.font_text13();
}

.isNew {
    display: flex;
    position: relative;
    padding-right: 47px;

    background-color: vars.$grey_hover;

    &:after {
        content: "";
        width: 9px;
        height: 9px;

        background-color: vars.$green_success;
        position: absolute;

        top: 50%;
        bottom: 50%;
        transform: translate(-50%, -50%);

        right: 24px;
        border-radius: 50%;
    }

    svg {
        circle {
            fill: vars.$white_main;
        }
    }
}

.link {
    cursor: pointer;
}
