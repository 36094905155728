@use '@styles/_variables.scss' as vars;

.notify {
    height: 100%;
    width: 500px;
    background-color: vars.$white_main;

    @media (max-width: vars.$break-point-md) {
        width: 456px;
    }

    @media (max-width: vars.$break-point-sm) {
        width: 100vw;
    }
}

.header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: fit-content;
    align-items: center;
    position: relative;

    &:after {
        content: '';
        position: absolute;

        width: 100%;
        height: 8px;
        bottom: -8px;

        background: linear-gradient(0deg, rgba(30, 30, 30, 0.08) 0%, rgba(224, 224, 224, 0) 100%);
        transform: matrix(1, 0, 0, -1, 0, 0);
    }
}

.caption {
    display: flex;
    @include vars.font_h2();
    padding: 21px 24px;

    @media (max-width: vars.$break-point-md) {
        padding: 16px 24px;
    }

    @media (max-width: vars.$break-point-sm) {
        padding: 16px 20px;
    }
}

.close {
    display: block;
    margin-right: 24px;
    cursor: pointer;
    padding: 2px;

    @media (max-width: vars.$break-point-md) {
        margin-right: 16px;
    }
}

.content {
    display: flex;
    flex-direction: column;
    height: calc(100% - 72px);

    & > div {
        height: 100%;
    }

    @media (max-width: vars.$break-point-md) {
        height: calc(100% - 62px);
    }
}

.scroll {
    max-height: 100%;

    &::-webkit-scrollbar {
        width: 4px;
        height: 100%;
        background-color: white;
        border: solid 3px transparent;
    }

    &::-webkit-scrollbar-track {
        border: solid 3px transparent;
    }

    &::-webkit-scrollbar-thumb {
        width: 3px;
        height: 39px;
        border-radius: 5px;
        background-color: vars.$grey_elements;
    }
}

.empty {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: vars.$grey_text;

    & svg path {
        stroke: vars.$grey_text;
    }

    & svg path:last-child {
        fill: vars.$grey_text;
    }
}

.icon-container {
    width: 64px;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: vars.$grey_hover;
    margin-bottom: 12px;
}
