@use "@styles/_variables.scss" as vars;

.section {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    align-items: center;
    width: 100%;
    padding-top: 104px;

    &__background_img {
        width: 100px;
        height: 100px;
        background: url("/assets/image/empty-lecture.svg") no-repeat center;
    }

    &__text {
        width: 190px;
        text-align: center;
    }
}
