@use "@styles/_variables.scss" as vars;


.wrapper {
    padding: 14px 16px;
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    background: vars.$grey_hover;
    gap: 12px;
    border-radius: 10px;

    .divider {
        width: 100%;
        height: 1px;
        background: vars.$outline_borders;
    }
}
