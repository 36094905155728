@use "@styles/variables.scss" as vars;

.container {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: 0.05em;
    word-break: normal;

    border-radius: 16px;
    padding: 2px 8px 3px 8px;

    margin-bottom: 2px;
}

.danger {
    color: vars.$red_attention_hover;
    border: 1px solid rgba(251, 60, 60, 0.5);
}

.outlined {
    color: vars.$grey_text;
    border: 1px solid vars.$grey_elements;
}

.filled {
    color: vars.$black_grey;
    border: none;
    background-color: vars.$grey_hover;
}
