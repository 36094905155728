@use '@styles/_variables.scss' as vars;

.title_navigate {
    display: flex;
    align-items: center;

    h1 {
        margin-left: 16px;
    }
}

.container {
    padding-top: 24px;
    width: 100%;
}

.filters {
    display: flex;
    flex-direction: row;
    margin-bottom: 24px;

    .select {
        flex: 1;
        max-width: 320px;
    }

    .select_input {
        height: 34px;
        padding-top: 7px;
        padding-bottom: 7px;
    }

    .select_icon {
        top: 10px;
    }

    .select_margin {
        margin-right: 12px;
    }
}

.header {
    width: 100%;
    max-width: 1034px;
    padding: 0 24px;
    display: grid;
    align-items: center;
    grid-template-columns: auto 70px 176px 52px;
    column-gap: 20px;
    margin-bottom: 12px;
    color: vars.$grey_text;

    .checkbox_wrapper {
        display: flex;
    }

    .checkbox {
        width: unset;
        margin-right: 12px;
    }
}

.card {
    margin-bottom: 12px;
}

.mass_container {
    display: flex;
    justify-content: space-between;
    padding: 6px 0px;

    & > *:not(:last-child) {
        margin-right: 8px;
    }
}

.action_btn {
    display: flex;
    height: 35px;

    circle {
        fill: vars.$white_main;
    }

    & > div {
        margin: 0px 0px 0px 10px;
    }
}

.empty {
    padding-top: 96px;
    display: flex;
    justify-content: center;
    color: vars.$grey_88;
}
