@use "@styles/_variables.scss" as vars;

.header {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    height: 64px;
    border-bottom: 1px solid vars.$grey_lines;
    z-index: vars.$hightest_index;

    @media (max-width: vars.$break-point-md) {
        height: 58px;
        border-bottom: none;

        &::after {
            content: "";
            position: absolute;
            bottom: -8px;
            height: 8px;
            width: 100%;
            left: 0;
            background: linear-gradient(0deg, rgba(30, 30, 30, 0.08) 0%, rgba(224, 224, 224, 0) 100%);
            transform: matrix(1, 0, 0, -1, 0, 0);
        }
    }

    @media (max-width: vars.$break-point-sm) {
        height: 54px;
    }
}

.caption {
    display: flex;
    padding: 12px 16px;
    align-items: center;
}

.contacts {
    width: 328px;
    display: flex;
    flex-direction: column;
}

.close {
    padding-right: 24px;
    display: flex;
    align-items: flex-start;

    @media (max-width: vars.$break-point-md) {
        padding-right: 16px;
    }
}

.icon {
    cursor: pointer;

    transition: stroke 0.2s ease-in;

    & svg {
        width: 24px;
        height: 24px;
    }

    &:hover {
        svg {
            path {
                stroke: vars.$black_main;
            }
        }
    }
}

.prev {
    margin-right: 16px;

    @media (max-width: vars.$break-point-sm) {
        margin-right: 14px;
    }
}
