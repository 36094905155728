@use '@styles/_variables.scss' as vars;

.title_wrapper {
    display: flex;
    align-items: center;
    column-gap: 12px;
    width: 100%;
}

.profile {
    padding: 24px;
    border-radius: 16px;
    width: 100%;
    margin-bottom: 24px;
}

.profile_header_container {
    display: flex;
}

.profile_header {
    display: flex;
    width: 100%;
    align-items: center;
}

.profile_photo_container {
    width: 96px;
    height: 96px;
    margin-right: 20px;
    border-radius: 50%;
    overflow: hidden;
    background-color: vars.$grey_hover;

    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    position: relative;

    @media (max-width: vars.$break-point-lg) {
        width: 72px;
        height: 72px;
        margin-right: 16px;
    }

    img {
        max-width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.header_info {
    word-wrap: break-word;
    flex-grow: 1;
    .full_name {
        @media (max-width: vars.$break-point-sm) {
            font-size: 15px;
        }
    }
}

.status {
    display: inline-block;
    padding: 4px 12px;
    margin-bottom: 4px;
    background: vars.$grey_hover;
    color: vars.$grey_text;
    font-weight: 700;
    border-radius: 50px;
    @include vars.font_text13;

    @media (max-width: vars.$break-point-lg) {
        @include vars.font_text12;
    }

    &_success {
        background: vars.$green_success;
        color: vars.$white_main;
    }
}

.profile_breadcrumps_wrapper {
    @media (max-width: vars.$break-point-sm) {
        width: vars.responsive_property_sm(223, 552, 223px);
        @include vars.fade-in-ellipsis(30px, vars.$white_main);
    }

    @media (max-width: vars.$break-point-xs) {
        width: vars.responsive_property_sm(180, 223, 180px);
    }
}

.profile_photo_name {
    color: vars.$grey_text;
}

.profile_photo_icon {
    background-color: vars.$profile_grey_dark;
    width: 100%;
    height: 28px;

    position: absolute;
    bottom: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: vars.$break-point-lg) {
        height: 24px;
    }
}

.subtitle {
    margin-bottom: 16px;

    @media (max-width: vars.$break-point-lg) {
        @include vars.font_h3;
    }
}

.main_info_block {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
    @media (max-width: vars.$break-point-sm) {
        width: 100%;
        grid-template-columns: 1fr;
    }
}

.section_net {
    display: flex;
    gap: 8px 8px;
    flex-wrap: wrap;
    .section_item {
        display: flex;
        flex-direction: column;
        gap: 4px 0;
        border-radius: 12px;
        background-color: #f4f5f5;
        padding: 10px 12px;
        font-size: 14px;
        .section_key {
            color: #a0a0a0;
        }
        .section_value {
            color: #222626;
        }
    }
}

.section {
    @include vars.font_text14;
}

.section_caption {
    color: vars.$grey_text;
}

.socialNetworks {
    display: flex;
    flex-direction: column;
}

.field {
    display: flex;
    flex-direction: column;
    gap: 4px 0;
}

.label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 194px;
    margin-right: 16px;

    @media (max-width: vars.$break-point-lg) {
        width: 71px;
    }
}

.role {
    margin-top: 8px;
}

.content {
    display: flex;
    flex-direction: column;
}

.save_btn {
    width: fit-content;
    margin-top: 16px;
}

.inputs_wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 24px;
    @media (max-width: vars.$break-point-md) {
        grid-template-columns: 1fr;
        gap: 15px 0;
    }
}

.input {
    width: 100%;
}

.notice {
    display: flex;
    align-items: center;

    & > p {
        margin-right: 16px;
    }
}
