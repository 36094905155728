@use "@styles/_variables.scss" as vars;

.container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    flex-direction: column;
    row-gap: 16px;

    & p {
        max-width: 381px;
        text-align: center;
    }

    @media (max-width: vars.$break-point-md) {
        & p {
            max-width: 320px;
        }

        & h3 {
            text-align: center;
        }
    }

    .closeIcon {
        position: absolute;
        cursor: pointer;
        top: 20px;
        right: 24px;

        & svg {
            width: 24px;
            height: 24px;
        }

        &:hover {
            path {
                stroke: vars.$black_main;
            }
        }
    }
}
