@use "@styles/_variables.scss" as vars;

.score {
    &__status {
        width: 9px;
        height: 9px;
        border-radius: 50%;

        @media (max-width: vars.$break-point-lg) {
            width: 7px;
            height: 7px;
        }

        &--excellent {
            background-color: vars.$green_success;
        }

        &--good {
            background-color: vars.$green_main;
        }

        &--satisfactory {
            background-color: #eba800;
        }

        &--unsatisfactory {
            background-color: vars.$red_attention_hover;
        }

        &--hide {
            display: none;
        }
    }
}
