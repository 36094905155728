@use '@styles/variables.scss' as vars;

.container {
    display: flex;
    background-color: white;
    max-width: 324px;
    padding: 20px 24px 12px;
    height: fit-content;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    position: absolute;
    z-index: vars.$hightest_index;

    box-shadow: 0px 4px 24px rgba(53, 53, 53, 0.06);
    border-radius: 16px;

    &--range {
        max-width: 290px;
        padding: 20px 16px 12px;
    }

    &--last {
        left: auto;
    }
}

.itemsContainer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
}

.items {
    display: flex;
    width: 86px;
    column-gap: 8px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 4px 0px;
    border-radius: 6px;
    margin-bottom: 8px;

    font-family: Mulish;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;

    &:hover {
        background-color: #f4f5f5;
    }
}
