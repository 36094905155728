@use '@styles/_variables.scss' as vars;

.container {
    display: flex;
    width: 100%;
    padding: 8px 12px;
    border-bottom: 1px solid vars.$grey_lines;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    &:hover {
        background-color: vars.$grey_hover;
    }

    .name {
        @include vars.font_text14;
        transition: color 0.1s ease-in-out;

        &:hover {
            cursor: pointer;
            color: vars.$green_main_hover;
        }
    }
}