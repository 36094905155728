@use '@styles/_variables.scss' as vars;

.container {
    margin-bottom: 24px;
}

.row {
    margin-bottom: 0;
}

.error {
    margin-top: 8px;
    color: vars.$red_attention;
}
