@use '@styles/_variables.scss' as vars;

.root {
    position: relative;
}

.handle {
    display: flex;
    z-index: vars.$low_index;
    position: absolute;
    left: 4px;
    top: 50%;
    transform: translate(0, -50%); 
    width: fit-content;
    height: fit-content;
}
