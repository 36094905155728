@use "@styles/_variables.scss" as vars;

.antiplagiarism {
    .header {
        display: flex;
        align-items: center;
        gap: 0 8px;
        margin-bottom: 12px;
        .name {
            @include vars.font_text18;
            font-weight: 600;
        }

        .tooltip {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .board {
        display: grid;
        gap: 10px;
        .boardItem {
            .boardItemName {
                font-weight: 700;
                margin-bottom: 4px;
            }
            .result {
                display: flex;
                align-items: center;
                gap: 0 8px;
                max-width: 120px;
            }
            .progressBar {
                width: 100%;
                height: 4px;
                background-color: #e3e7ea;
                border-radius: 4px;
                overflow: hidden;
                .progress {
                    height: 100%;
                    transition: width 0.3s ease;
                }
            }
        }
    }
    .sources {
        margin: 12px 0;
        font-weight: 700;
    }
}
