@use '@styles/_variables.scss' as vars;

.item {
    display: flex;
    align-items: center;
    column-gap: 10px;
    padding: 10px 0;
    font-weight: 500;

    @media (max-width: vars.$break-point-lg) {
        padding: 0 0 16px;
    }

    & span {
        font-weight: 500;
        white-space: unset;
        font-size: 1.4rem;
    }
}

.link {
    font-weight: 700;
    font-size: 1.4rem;
    color: vars.$black_main;
}
