@use '@styles/_variables.scss' as vars;
@use '@styles/mixins/typo' as mixins;

.container {
    position: relative;
    width: 100%;

    .header {
        display: flex;
        border-bottom: 1px solid vars.$grey_lines;
        padding: 0 12px 12px;
        color: vars.$grey_text;
        @include mixins.font_text14;
    }
}
